@mixin heading() {
  line-height: 1.2em;
  margin-bottom: $spacer-m;
  display: block;
}
// h1, %h1,
// h2, %h2,
// h3, %h3,
// h4, %h4,
// h5, %h5,
// h6, %h6,
// p,
// strong,
// span {
//   overflow-wrap: break-word;
//   word-break: break-word;
// }
h1, %h1 {
  @include heading;
  font-size: 3rem;
  font-weight: bold;
  @include media-breakpoint-down(md) {
    font-size: 2.5rem;
  }
  @include media-breakpoint-down(sm) {
    font-size: 2rem;
  }
}
h2,%h2 {
  @include heading;
  font-size: 2.5rem;
  font-weight: $msv-font-weight-medium;
  @include media-breakpoint-down(sm) {
    font-size: 2rem;
  }
}
h3,%h3 {
  line-height: 1.5em;
  margin-bottom:1rem;
  text-transform: none;
  font-size: 2rem;
  font-weight: $msv-font-weight-medium;
  @include media-breakpoint-down(sm) {
    font-size: 1.5rem;
  }
}
h4, %h4 {
  @include heading;
  font-size: 1.5rem;
  font-weight: $msv-font-weight-light;
  @include media-breakpoint-down(sm) {
    font-size: 1.25rem;
  }
}
h5, %h5 {
  @include heading;
  font-size: 1rem;
  font-weight: $msv-font-weight-heavy;
  letter-spacing: 0.1em;
  line-height: 1.6em;
  margin-bottom: $spacer;
  @include media-breakpoint-down(md) {
    font-size:1rem;
  }
  @media screen and (max-width: $msv-breakpoint-s) {
    line-height: 1.4em;
  }
}
h6, %h6 {
  @include heading;
  font-size: 0.875rem;
  font-weight: $msv-font-weight-medium;
  line-height: 1.6em;
}
p {
  font-size:1rem;
  margin-bottom: 1rem;
  line-height: 1.4em;
  &.small {
    font-size:1rem!important;
  }
  &:last-child {
    margin-bottom:0;
  }
}
strong {
  font-weight: 600;
}
.uppercase {
  text-transform: uppercase;
}

.bigger {
  font-size: 1.5rem;
}

.text-center {
  text-align: center;
}

h1.container-heading {
  @extend %h2;
  text-align: center;
  width: 100%;
  padding: $spacer-l $layout-gutter $spacer $layout-gutter;
}