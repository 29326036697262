$msv-address-form-item-margin-bottom: 10px;
$msv-address-detail-item-margin-right: 5px;
$msv-address-select-item-margin-top: 10px;
$msv-address-select-item-margin-bottom: 10px;
$msv-alert-border-radius: 0;
$msv-alert-padding: 0.75rem 1.25rem;
$msv-alert-margin-bottom: 1rem;
$msv-alert-icon-margin: 8px;
$msv-address-input-min-height: 32px;
$msv-address-select-item-radio-height: 24px;
$msv-address-select-item-radio-width: 24px;
$msv-address-select-item-address-detail-padding-left: 48px;
$msv-address-button-save-margin-right: 20px;
$msv-account-management-address-margin-bottom: 20px;
$msv-account-management-address-list-button-add-empty-margin-bottom: 16px;
$msv-account-management-address-list-primary-margin-top: 20px;
$msv-account-management-address-list-primary-heading-margin-bottom: 12px;
$msv-account-management-address-list-primary-list-border-color: $msv-gray-300;
$msv-account-management-address-list-primary-list-address-detail-item-margin-right: 5px;
$msv-account-management-address-list-primary-list-address-detail-item-newline-margin-right: 0px;
$msv-account-management-address-list-primary-list-phone-icon-margin-right: 5px;
$msv-account-management-address-list-button-primary-margin-top: 15px;
$msv-account-management-address-textbox-height: 48px;

//style presets
:root {
    --msv-address-font-size: var(--msv-body-font-size-l);
    --msv-address-font-color: var(--msv-font-primary-color);
    --msv-address-border: #{$msv-gray-300};
    --msv-address-font-family: #{$msv-primary-font-family};
    --msv-checkout-shipping-address-font-color: var(--msv-font-primary-color);

    //heading
    --msv-address-heading-font-size: var(--msv-body-font-size-xl);
    --msv-address-heading-font-color: var(--msv-font-primary-color);

    --msv-address-primary-heading-font-size: var(--msv-body-font-size-m);
    --msv-address-primary-heading-font-color: var(--msv-font-primary-color);

    --msv-address-primary-text-font-size: var(--msv-body-font-size-s);
    --msv-address-primary-text-font-color: var(--msv-font-primary-color);

    // error
    --msv-address-error-bg: var(--msv-error-color);
    --msv-address-error-border: var(--msv-error-color);
    --msv-address-error-font-color: var(--msv-font-secondary-color);
    --msv-address-error-font-size: var(--msv-body-font-size-m);

    // link
    --msv-address-link: var(--msv-font-primary-color);
    --msv-address-link-size: var(--msv-body-font-size-m);

    // primary button
    --msv-address-primary-btn-bg: var(--msv-accent-brand-color);
    --msv-address-primary-btn-font-color: var(--msv-font-secondary-color);
    --msv-address-primary-btn-border: var(--msv-accent-brand-color);

    // secondary button
    --msv-address-secondary-btn-bg: var(--msv-secondary-button-background-color);
    --msv-address-secondary-btn-font-color: var(--msv-font-primary-color);
    --msv-address-secondary-btn-border: var(--msv-accent-brand-color);
}

.msc-address-form {
    &__intro {
        margin-bottom: $spacer-m;

        @media screen and (max-width: $msv-breakpoint-m) {
            h5 {
                font-size: $msv-font-size-s;
                font-weight: $msv-font-weight-heavy;
                margin-bottom: .5rem;
            }
            p {
                font-size: 13px;
            }
        }
    }
    label {
        span {
            font-size: 12px;
        }
    }
    .personal-data--type {
        display: flex;
        margin-bottom: $spacer-l;

        label {
            margin-right: $spacer-m;
        }
        @media screen and (max-width: $msv-breakpoint-m) {
            flex-direction: column;

            label {
                margin-bottom: $spacer;
                text-transform: unset;
            }
            span {
                font-size: $msv-font-size-m;
            }
        }
    }
    &__exra-info {
        position: absolute;
        top: 0;
        right: 0;
        text-transform: uppercase;
        color: $msv-secondary;
        font-size: $msv-font-size-xs;
        font-weight: $msv-font-weight-medium;
        letter-spacing: 0.03em;

        @media screen and (max-width: $msv-breakpoint-m) {
            color: $msv-gray-500;
			font-weight: $msv-font-weight-light;
            margin-top: -0.25rem;
            margin-bottom: 0.5rem;
            position: relative;
        }
    }
    &__item {
		margin-bottom: $spacer-m;
		position: relative;

        @media screen and (max-width: $msv-breakpoint-m) {
            margin-bottom: $spacer;
        }

		&#shipping_addressaddresstypevalue_container {
			@include add-icon($msv-ChevronDown, after);

			&::after {
				position: absolute;
				right: 0;
				bottom: 11px;
			}
		}
        select {
            border: none;
            border-bottom: 1px solid $msv-gray-300;
            background: none;
			cursor: pointer;
            font-weight: $msv-font-weight-medium;
            line-height: 2.5em;
            width: 100%;
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			z-index: 1;
        }
        &-name {
            display: none;
        }
    }

    &__item-isprimary {
        display: flex;

        .msc-address-form__label {
            margin-top: auto;
            margin-bottom: auto;
            margin-right: 10px;

            &:hover {
                cursor: pointer;
            }
        }

        .msc-address-form__input-checkbox {
            width: 20px;

            &:hover {
                cursor: pointer;
            }
        }
    }

    .MicrosoftMap {
        position: relative;
        width: 100%;

        .as_container_search {
            width: 100%;
        }
    }

    &__alert {
        display: block;
    }

    &__button-save {
        @extend %primary-button;
    }

    &__button-cancel {
        @extend %secondary-button;
    }

    .address-form__item-invalid &__alert {
        color: $msv-error-color;
        font-size: 12px;
    }
}

.msc-address-detail {
    h6 {
        margin-top: $spacer;
        margin-bottom: $spacer-xs;
    }
    &__item {
        display: block;
        margin-bottom: $spacer-xs;
        font-size: $msv-font-size-xs;
        margin-right: $msv-address-detail-item-margin-right;
        &.Type_CAP, &.DeliveryMode_CAP {
            display: none;
        }
        &.FirstName_CAP,
        &.LastName_CAP {
            font-weight: $msv-font-weight-heavy;
        }
        &-name {
            display: none;
        }
    }

    &__item-newline {
        margin-right: 0px;
    }
    &__item-address-detail_Phone {
        display: block;
    }
    &__item-phone {
        &-label {
            @include visually-hidden();
        }

        &:before {
            margin-right: $msv-account-management-address-list-primary-list-phone-icon-margin-right;
        }

        &.msc-address-detail__item-empty {
            display: none;
        }
    }
}

.msc-address-select {
    &__input-radio {
        height: $msv-address-select-item-radio-height;
        width: $msv-address-select-item-radio-width;
        position: absolute;
        top: 0;
    }

    &__item {
        display: flex;
        align-items: center;
        margin-top: $msv-address-select-item-margin-top;
        margin-bottom: $msv-address-select-item-margin-bottom;
        position: relative;

        .msc-address-detail {
            padding-left: $msv-address-select-item-address-detail-padding-left;
        }
    }

    &__input {
        @include vfi();
        @include form-input-checkbox();
    }

    &__button-add {
        @include vfi();
        @include primary-button(
            var(--msv-address-primary-btn-bg),
            var(--msv-address-primary-btn-font-color),
            var(--msv-address-primary-btn-border)
        );
    }
}

// mixin
@mixin address-button {
    cursor: pointer;
}
.ms-account-management-address {
    margin-bottom: $msv-account-management-address-margin-bottom;
    .ms-address-list {
        margin-bottom: $msv-account-management-address-margin-bottom;
    }

    .msc-address-list__heading {
        @include font-content(var(--msv-font-weight-normal), var(--msv-address-heading-font-size), $msv-line-height-xl);
        color: var(--msv-address-heading-font-color);
        margin-bottom: 28px;
    }

    .msc-address-list__add-empty {
        margin-bottom: $msv-account-management-address-list-button-add-empty-margin-bottom;
    }

    .msc-address-list__button-add {
        @include primary-button(
            var(--msv-address-primary-btn-bg),
            var(--msv-address-primary-btn-font-color),
            var(--msv-address-primary-btn-border)
        );
    }

    .msc-address-list__primary {
        border-top: 1px solid var(--msv-address-border);
        margin-top: $msv-account-management-address-list-primary-margin-top;
        padding: 20px 0px;

        .msc-address-list__primary-heading {
            @include font-content(var(--msv-font-weight-bold), var(--msv-address-primary-heading-font-size), $msv-line-height-m);
            color: var(--msv-address-primary-heading-font-color);
            margin-bottom: $msv-account-management-address-list-primary-heading-margin-bottom;
        }
        .msc-address-list__primary-list {
            padding: 20px 0px;
            border-bottom: 1px dashed var(--msv-address-border);

            &::nth-child(2) {
                padding-top: 0px;
            }

            &::last-child {
                border-bottom: none;
                padding-bottom: 0;
            }
            .msc-address-detail {
                @include font-content(var(--msv-font-weight-normal), var(--msv-address-primary-text-font-size), $msv-line-height-s);
                color: var(--msv-address-primary-text-font-color);

                .msc-address-detail__item {
                    margin-right: $msv-account-management-address-list-primary-list-address-detail-item-margin-right;
                }
                .msc-address-detail__item-newline {
                    margin-right: $msv-account-management-address-list-primary-list-address-detail-item-newline-margin-right;

                    &::after {
                        content: '\A';
                        white-space: pre;
                    }
                }
            }
            .msc-address-list__button-primary {
                @include secondary-button(
                    var(--msv-address-secondary-btn-bg),
                    var(--msv-address-secondary-btn-font-color),
                    var(--msv-address-secondary-btn-border)
                );
                display: block;
                margin-top: $msv-account-management-address-list-button-primary-margin-top;
            }

            .msc-address-list__button-edit {
                @include button-link(var(--msv-address-link));
                @include font-content(var(--msv-font-weight-normal), var(--msv-address-link-size), $msv-line-height-m);
                text-transform: none;
                border-radius: 0px;
                padding: 6px 12px 6px 0px;
                text-decoration: underline;
            }

            .msc-address-list__button-remove {
                @include button-link(var(--msv-address-link));
                @include font-content(var(--msv-font-weight-normal), var(--msv-address-link-size), $msv-line-height-m);
                text-transform: none;
                border-radius: 0px;
                padding: 6px 12px 6px 0px;
                text-decoration: underline;
            }
        }
        .msc-address-list__primary-list:last-child {
            border-bottom: none;
            padding-bottom: 0;
        }
    }
}
