.ms-checkout-financing {
  position: relative;

  .financing-container {
    padding:0;
    border: none;

    &.toggled {
      padding:0;

      & + .ms-checkout__guided-card-btn-save.no-simulation {
        display: none;
      }
    }
    .financing-toggeable {
      border: 1px solid $msv-gray-100;
      flex-wrap: wrap;

      @media (max-width: $msv-breakpoint-m) {
        margin-right: -.5rem;
        margin-left: -.5rem;
      }
    }
    .financing-options {
      border: none;
      flex-basis: 100%;
      margin-right: 0;
      padding-bottom: 2rem;
    }

    &.updated {
      .financing-simulation_error {
        margin: -2rem 0 4rem 0;
      }
    }
  }
  
  .financing-simulation {
    display: none;
    flex-basis: 100%;
    background: rgba(227, 229, 230, 0.3);
    border: none;
    margin: -2rem 3.75rem 8rem 3.75rem;
    padding-right: 0;
    padding-left: 0;

    @media (max-width: $msv-breakpoint-m) {
      margin: 1rem 1rem 8rem 1rem;
      background-color: transparent;

      .financing-simulation__table {
        background-color: transparent;

        li {
          border-bottom: none;
          margin-top: $spacer;

          .simulation-label {
            color: $msv-black;
            font-weight: $msv-font-weight-light;
            text-transform: none;
          }
        }
      }
    }
  }
  .ms-checkout__guided-card-btn-redo,
  .ms-checkout__guided-card-btn-save.save-simulation {
    display: none;
    position: absolute;
    width: auto;
  }
  .ms-checkout__guided-card-btn-redo {
    bottom: 42px;
    left: 3.75rem;
    
    @media (max-width: $msv-breakpoint-m) {
      background: none;
      border: none;
      color: $msv-primary-hover;
      right: .5rem;
      bottom: 1rem;
      left: .5rem;
      text-decoration: underline;
      text-transform: none;
      width: calc(100% - 1rem);
    }
  }
  .ms-checkout__guided-card-btn-save.save-simulation {
    bottom: 44px;
    right: 3.75rem;

    @media (max-width: $msv-breakpoint-m) {
      right: .5rem;
      bottom: 5rem;
      left: .5rem;
      width: calc(100% - 1rem);
    }
  }
  .financing-container.updated {
    .financing-cta {
      display:none;
    }

    .financing-options .financing-option {
      padding-bottom: 6rem;
    }
    .financing-simulation,
    .ms-checkout__guided-card-btn-redo,
    & + button + .ms-checkout__guided-card-btn-save.save-simulation {
      display: block;
    }
  }
}
