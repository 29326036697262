.hero.ms-content-block {
    .ms-content-block__details {
        bottom: 0;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: flex-start;
        top: 0; 
        right: 0;        
        left: 0;
        padding: $spacer-l;
        .msc-carousel & {
            padding: $spacer-l 8rem;
        }

        .ms-content-block__text {
            color: $msv-white;
            font-size: $msv-font-size-m;
        }
    }
    &.texttheme__light .ms-content-block__title,
    &.texttheme__light .ms-content-block__text {
        color: $msv-black;
        text-shadow: $hero-content-shadow;
    }
    &.texttheme__dark .ms-content-block__title,
    &.texttheme__dark .ms-content-block__text  {
        color: $msv-white;
    }
}
@media screen and (max-width: $msv-breakpoint-m) {
    .hero.ms-content-block  .ms-content-block__details {
        background-color: $msv-white;
        color: $msv-black;
        position: static;
        text-decoration: none;
        .msc-carousel & {
            padding: $spacer-l;
        }
        .ms-content-block__title {
            color: $msv-black;
            font-size: 28px;
            max-width: initial;
        }
        .ms-content-block__text {
            color: $msv-black;
        }
        .ms-content-block__cta {
            width: 100%;

            .msc-cta__primary {
                width: 100%
            }
        }
    }  
}
