//style presets
:root {
  // text
  --msv-account-balance-text-font-size: var(--msv-body-font-size-m);
  --msv-account-balance-text-font-weight: var(--msv-font-weight-light);

  // bolder text
  --msv-account-balance-bolder-text-font-weight: var(--msv-font-weight-bold);

  // title
  --msv-account-balance-title-font-size: var(--msv-body-font-size-xl);
  --msv-account-balance-title-font-weight: var(--msv-font-weight-light);

  // account details section title
  --msv-account-balance-details-section-title-font-size: var(--msv-body-font-size-xl);
  --msv-account-balance-details-section-title-font-weight: var(--msv-font-weight-light);

  // customer account number text
  --msv-account-balance-customer-account-number-text-font-size: #{$msv-heading-text-size};

  // credit amount text
  --msv-account-balance-credit-amount-text-font-size: var(--msv-body-font-size-xxl);
}

.ms-account {
  &-customer-balance {
    max-width: 640px;
    font-weight: var(--msv-account-balance-text-font-weight);
    font-size: var(--msv-account-balance-text-font-size);

    h2 {
      padding: 25px 0;
      border-bottom: 1px solid #616365;
      font-weight: var(--msv-account-balance-title-font-weight);
      font-size: var(--msv-account-balance-title-font-size);
      line-height: normal;
    }

    h3 {
      padding-top: 30px;
    }

    .ms-account-customer-balance__credit-summary-heading {
      @include add-icon($msv-ListAlt, before);
      &:before {
        padding-right: 5px;
      }
    }

    &__account,
    &__credit-summary {
      display: flex;
      padding: 1rem 0;
      justify-content: space-between;

      div {
        width: 100%;
        padding: 0.5rem 0;
      }
    }

    &__account {
      border-bottom: 1px dashed #616365;

      &__details {
        h4 {
          padding: 0.5rem 0;
          font-weight: var(--msv-account-balance-details-section-title-font-weight);
          font-size: var(--msv-account-balance-details-section-title-font-size);
        }

        .ms-account-customer-balance__account-number {
          font-size: var(--msv-account-balance-customer-account-number-text-font-size);
        }
      }
    }

    &__account__credit,
    &__credit-summary__values {
      > div {
        font-weight: var(--msv-account-balance-bolder-text-font-weight);
        text-align: right;
      }
    }

    &__credit-summary__values {
      .ms-account-customer-balance__excess-credit-label {
        &:before {
          content: '(';
        }
        &:after {
          content: ')';
        }
      }
    }

    &__account__credit {
      .ms-account-customer-balance__available-credit-label {
        font-weight: var(--msv-account-balance-text-font-weight);
        font-size: var(--msv-account-balance-customer-account-number-text-font-size);
      }
      .ms-account-customer-balance__available-credit-amount {
        font-weight: var(--msv-account-balance-text-font-weight);
        font-size: var(--msv-account-balance-credit-amount-text-font-size);
        line-height: 3rem;
      }
    }

    &__action {
      &__bar {
        margin-top: 10px;
      }

      &__requestStatement {
        border: 1px solid var(--msv-accent-brand-color);
        padding: 10px;
      }

      &__submitRequest {
        background-color: var(--msv-accent-brand-color);
        color: var(--msv-font-secondary-color);
        border: 1px solid var(--msv-font-secondary-color);
        padding: 10px;
        width: 25%;
      }

      &__cancelRequest {
        background-color: var(--msv-font-secondary-color);
        border: 1px solid var(--msv-accent-brand-color);
        padding: 10px;
        width: 25%;
        margin-left: 20px;
      }
    }

    &__requestStatement__modal {
      position: absolute;
      left: 25%;
      top: 30%;
      width: 50%;
    }

    &__requestStatement__fromDate {
      padding-top: 20px;
      float: left;
      display: block;
      width: 90%;
      height: calc(2.25rem + 2px);
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      font-weight: $msv-font-weight-light;
      line-height: 1.5;
      color: #495057;
      background-clip: padding-box;
    }

    &__requestStatement__toDate {
      padding-top: 20px;
      float: right;
      display: block;
      width: 90%;
      height: calc(2.25rem + 2px);
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      font-weight: $msv-font-weight-light;
      line-height: 1.5;
      color: #495057;
      background-clip: padding-box;
    }

    &__dateSelector {
      margin-top: 5px;
      display: block;
    }

    &__sendToEmail {
      font-weight: bold;
      padding-top: 5px;
    }
  }
}
