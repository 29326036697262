$tile-padding-bottom-mobile: 48px;
$tile-details-padding-top: 20px;
$tile-first-margin-left: 0px;
$tile-last-margin-right: 0px;
$tile-last-padding-bottom-mobile: 0px;
$tile-link-margin-left-right: 24px;
$tile-link-padding-top: 10px;

:root {
  // link
  --msv-content-block-link-color: var(--msv-font-primary-color);
  --msv-content-block-link-font-size: var(--msv-body-font-size-m);
}

.ms-content-block {
  &[data-m-layout='tile'] {
    width: fit-content;
    padding:$spacer;
    width: 100%;
    
    @include media-breakpoint-up(md) {
      padding:$spacer-xl 0;
      &.no-spaces {
        padding-top:0;
        padding-bottom:0;
      }
    }
    &.no-spaces {
      padding-top:0;
      padding-bottom:0;
      min-height: 0;
    }
    /*
    &:after {
      content:"";
      border-bottom:2px solid $msv-secondary;
      width: 60%;
      max-width:14rem;
      display:block;
      margin-top:$spacer-m;
    }*/

    .ms-content-block__link {
      display:block;
    }

    .msc-cta__primary {

    }
    .ms-content-block__details
    {
      padding-top: $tile-details-padding-top;
    }

    .ms-content-block__details,
    &.textplacement__left .ms-content-block__details {
      text-align: left;

      .ms-content-block__cta {

        a[class^='msc-cta__'] {
          &:first-child {
            margin-left: $tile-first-margin-left;
          }
        }
      }
    }

    &.textplacement__right .ms-content-block__details {
      text-align: right;

      .ms-content-block__cta {
        a[class^='msc-cta__'] {
          margin-right: $tile-link-margin-left-right;

          &:last-child {
            margin-right: $tile-last-margin-right;
          }
        }
      }
    }

    &.textplacement__center .ms-content-block__details {
      text-align: center;

      .ms-content-block__cta {
        a[class^='msc-cta__'] {
          margin-left: $tile-link-margin-left-right / 2;
          margin-right: $tile-link-margin-left-right / 2;

          &:first-child {
            margin-left: $tile-first-margin-left;
          }

          &:last-child {
            margin-right: $tile-last-margin-right;
          }
        }
      }
    }

    @media (max-width: $msv-breakpoint-m) {
      width: 100%;

      & .ms-content-block__image img {
        width: 100%;
      }
    }
  }

  &.savings_block,
  &.financing_block,
  &.keys-in-hand_block {
    &:before {
      font-size: 5rem;
      color:$msv-secondary;
    }

  }
  &.savings_block {
    @include add-icon-icomoon($search);

  }
  &.financing_block {
    @include add-icon-icomoon($financing);
  }
  &.keys-in-hand_block {
    @include add-icon-icomoon($keys-in-hand);
  }
}

.col-sm:first-child .ms-content-block[data-m-layout='tile']:first-child,
.col-md:first-child .ms-content-block[data-m-layout='tile']:first-child {
  margin-left: $tile-first-margin-left;
}

.col-sm:last-child .ms-content-block[data-m-layout='tile']:last-child,
.col-md:last-child .ms-content-block[data-m-layout='tile']:last-child {
  margin-right: $tile-last-margin-right;
}

@media (max-width: $msv-breakpoint-m) {
  &.col-sm:last-child .ms-content-block[data-m-layout='tile']:last-child {
    padding-bottom: $tile-last-padding-bottom-mobile;
  }
}

.cookie-table {
  table {
    width: 100%;
    td {
      padding-right: 20px;
    }
  }
}