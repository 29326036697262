$navigation-parent-menu-margin-left:  24px;
$navigation-parent-menu-margin-right:  24px;
$navigation-parent-menu-margin-top:  20px;
$navigation-menu-list-min-width:  250px;
$navigation-sub-menu-padding-left:  20px;
$navigation-sub-menu-padding-right:  20px;
$navigation-menu-active-item-font-weight: 600;
$navigation-menu-item-height: 48px;
$navigation-menu-item-line-height: 45px;
$navigation-menu-button-icon-margin-left: 8px;
$navigation-menu-icon-line-height: 40px;
$navigation-menu-item-underline: 6px;
$navigation-menu-wrapper-height: auto;
$navigation-menu-wrapper-width: 100%;
$navigation-menu-wrapper-left:0px;
$navigation-menu-image-height:400px;
$navigation-menu-max-image-width:370px;
$navigation-menu-max-image-height:330px;
$navigation-menu-min-image-height:300px;
$navigation-menu-image-width:50%;
$navigation-menu-image-padding:40px;
$navigation-menu-image-bottom:14px;
$navigation-ms-nav-list-width:100%;
$navigation-ms-nav-submenu-margin-left:15px;
$navigation-ms-nav-area-width:63%;
$navigation-ms-category-img-padding:20px;
$navigation-menu-list-min-width:180px;

.ms-nav {
    background: var(--msv-nav-bg);
    border: 1px solid var(--msv-nav-border);
    color: $white;
    width: 100%;
    &__list {
        z-index: 1000;
        .level-2 {
            > li{
                font-weight: $navigation-menu-active-item-font-weight;
            }
            &.navmenu-multi-level{
                li.ms-nav__list__item { 
                    &.havesubmenu{
                        ul.ms-nav__list{
                            margin-left:$navigation-ms-nav-submenu-margin-left
                        }
                    }    
                    .havesubmenu{
                        font-weight:normal
                        
                    }
                }  
            } 
        }   
        &__item {
            font-size: var(--msv-nav-font-size);
            font-style: normal;
            white-space: nowrap;
            .havesubmenu{
                font-weight: $navigation-menu-active-item-font-weight;
            }
            .level-3{
                .havesubmenu{
                    .ms-nav__list{
                        margin-left: $navigation-ms-nav-submenu-margin-left;
                    }
                }
            }
            &__image {
                position: absolute;
                right: 0;
            }
            &__link.active,
            &.active .ms-nav__list__item__link {
                color: $msv-secondary;
            }
        }
    }

    &__list {
        &.level-2 {
            width: 100%;
        }
    }

    >.ms-nav__list {
        background: var(--msv-nav-bg);

        >.ms-nav__list__item {
            background: var(--msv-nav-bg);

            >.ms-nav__list {
                background: var(--msv-nav-bg);
                min-width: $navigation-menu-list-min-width;
            }
            >.ms-nav__feature{
                background:var(--msv-nav-bg);
                height:$navigation-menu-wrapper-height;
                left:$navigation-menu-wrapper-left;
                min-width: $navigation-menu-list-min-width;
                position: absolute;
                outline: none;
                overflow: hidden;
                width: $navigation-menu-wrapper-width; 
 
                >.category-image { 
                    float: right;
                    padding:  $navigation-ms-category-img-padding;
                    .category-image-title{
                        text-align: center;
                        display:none;
                    }
                    picture{
                        + div.category-image-title{
                            display:block;
                        }
                    }
                    img {
                        min-height:$navigation-menu-min-image-height;
                        max-height: $navigation-menu-max-image-height;
                        max-width:$navigation-menu-max-image-width;
                        >.ms-nav-image__item {
                            display: block; 
                            padding:$navigation-menu-image-padding;  
                        
                        } 
                    }  
                }
            }
        }
    }
}

@media screen and (min-width: $msv-breakpoint-xs) {
    .ms-nav {
        &__list {
            display: block;
            flex-wrap: nowrap;
            &__mobile__container {
                border-bottom: 1px solid var(--msv-nav-dropdown-border);
                padding-bottom: 13px;
                display: flex;
                &__button {
                    @include vfi();
                    @include add-icon($msv-ChevronLeft);
                    background-color: var(--msv-nav-bg);
                    border: none;
                    color: $white;
                    font-family: var(--msv-nav-font-family);
                    padding-left: 0;
                    padding-right: 0;

                    &:focus {
                        outline-offset: -1px;
                    }
                }
                &__span {
                    margin-left: 21px;
                    font-weight: 600;
                    color: $white;
                    font-family: var(--msv-nav-font-family);
                    font-size: var(--msv-nav-font-size);
                }
            }

            &__item {
                &__image {
                    display: none;
                }
                &__button {
                    color: $white;
                    padding-left: 0;
                    padding-right: 0;
                    text-align: left;
                    width: 100%;
                    &:after {
                        @include msv-icon();
                        content: $msv-ChevronRight;
                        display: inline-flex;
                        float: right;
                        line-height: $navigation-menu-item-line-height;
                        text-align: right;
                    }
                }

                &__button,
                &__link {
                    background-color: var(--msv-nav-bg);
                    border: none;
                    display: block;
                    font-family: var(--msv-nav-font-family);

                    &:focus {
                        outline-offset: -1px;
                    }
                    &:hover {
                        text-decoration:none;
                        color:$msv-secondary;
                    }
                    &:not(.active) {
                        color: $white;
                    }
                }
            }
        }
        >.ms-nav__list {
            outline: none;
            width: 100%;
            .ms-nav__list {
                height: 100vh;
                padding-top:$spacer-half;
                padding-bottom:$spacer-half;
                background: $msv-primary;
            }
            .havecateImage{
                float:left;
                position:relative;
                width:$navigation-ms-nav-area-width;
            }
            >.ms-nav__list__item {
                >.ms-nav__list__item__button {
                    color: $white;
                }
            }
        }
    }

    .ms-nav.child {
        position: absolute;
        top: 0;
    }
}

@media screen and (min-width: $msv-breakpoint-l) {
    .ms-nav {
        display: flex;
        &__list {
            position: absolute;
            &__mobile__container {
                display: none;
            }
        }
        &__list {
            &.level-2 {
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                left: 0;
                background:var(--msv-nav-bg);
                .ms-nav__list {
                    position: relative;
                    .ms-nav__list__item {
                        font-weight: normal;
                        margin-left: 0;
                    }
                }
            }
        }

        >.ms-nav__list {
            display: flex;
            flex-wrap: wrap;
            outline: none;
            position: relative;
            width: $navigation-ms-nav-list-width;
            .ms-nav__list {
                height: auto;
                min-width: $navigation-menu-list-min-width;
            }
            >.ms-nav__list__item:first-child {
                margin-left: 0;
            }

            >.ms-nav__list__item {
                margin:0;

                &.active .ms-nav__list__item__link {
                    color: $msv-secondary;
                }
                &:not(.active) .ms-nav__list__item__link {
                    color: $white;
            
                    &:hover,
                    &:focus {
                        color: $msv-secondary;
                    }
                }

                >.ms-nav__list__item__button,
                >.ms-nav__list__item__link {
                    padding:0.5rem 1rem;
                    align-items: flex-end;
                    background-color: var(--msv-nav-bg);
                    border: none;
                    display: flex;
                    justify-content: center;
                    position: relative;
                    line-height: 1.3em;
                    
                    &:hover,
                    &:focus {
                        background: transparent;
                        color:$msv-secondary;
                        outline:none;
                        text-decoration: none;
                    }

                    &:hover {
                        cursor: pointer;
                        text-decoration: none;
                    }
                }
                .mobile-view & {
                    >.ms-nav__list__item__button:not(.active),
                    >.ms-nav__list__item__link:not(.active) {
                        color: $white;
                    }   
                }

                >.ms-nav__list__item__button {
                    @include add-icon-icomoon($chevron,after,true,8px);
                    &:after {
                        transition:all .15s;
                    }
                    &[aria-expanded="true"]{
                        color:$msv-secondary;
                        &:after {
                            transform: scaleY(-1);
                        }
                    }
                }

                .ms-nav__list__item__link {
                    padding:0.5rem 1rem;
                    justify-content: flex-start;
                    width: 100%;
                }

                >.ms-nav__list__item__link {
                    display: flex;
                    justify-content: center;
                    line-height: 1.3em;
                    padding:0.5rem 1rem;
                    &:hover,
                    &:focus {
                        background: transparent;
                        text-decoration: none;
                        &:before {
                            width: 100%
                        }
                    }
                }

                >.ms-nav__list__item__button:first-child {
                    margin-left: 0;
                }
                >.ms-nav__list {
                    border: 1px solid var(--msv-nav-dropdown-border);
                    box-shadow: 0px 8px 15px $msv-box-shadow-color;
                }
            }
        }
    }
}
