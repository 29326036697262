.msc-modal {
    .msc-order-template-name-dialog {
        &__dialog {
            &__content {
                display: flex;
                flex-wrap: wrap;
            }

            &__order-template-name-label,
            &__order-template-name {
                width: 100%;
                font-size: 18px;
                line-height: 24px;
            }

            &__order-template-name {
                @include form-input-el();
                @include vfi();
                margin-top: 10px;
                width: 100%;
            }

            &__description {
                width: 100%;
                font-size: 16px;
                line-height: 21px;
                font-weight: 300;
                margin-top: 20px;
            }

            &__footer {
                margin-top: 20px;
            }

            &__create-order-template-button {
                @include primary-button();
            }

            &__cancel-button {
                @include secondary-button($msv-white);
                margin: 0 20px;
            }
        }
    }
}