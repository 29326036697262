footer {
    background-color: $black;
    color: $white;

    .ms-footer {
        &__list{
            flex: initial;
            width: auto;
            font-size:12px;
        }

        .ms-back-to-top {
            @include vfi();
            background: var(--msv-bg-color);
            border: 1px solid var(--msv-accent-brand-color);
            border-radius: 2px;
            bottom: 1.5rem;
            cursor: pointer;
            display: none;
            height: 3rem;
            min-width: auto;
            opacity: 0;
            padding: 0;
            position: fixed;
            right: 1.5rem;
            text-align: center;
            vertical-align: middle;
            width: 3rem;

            &:hover {
                background: var(--msv-accent-secondary-btn);
            }
            .fa-arrow-up {
                color: var(--msv-accent-brand-color);
                font-size: var(--msv-body-font-size-xl);
                left: 50%;
                line-height: $msv-line-height-l;
                position: absolute;
                top: 50%;
                transform: translate(-50%, -50%);

                &:before {
                  @include msv-icon();
                  content: $msv-ChevronUp;
                }
            }
        }
        &__item {
            padding: 5px 0px;

            a {
                color: $white;

                &:hover {
                    color: $msv-secondary;
                    text-decoration:none;
                }
            }
            &.facebook,
            &.twitter,
            &.linkedin,
            &.instagram {
                font-size: 1.2rem;

                a {
                    color: $msv-gray-300;
                    display: inline-block;
                    vertical-align: top;

                    &:hover {
                        text-decoration: none;
                        color:$msv-secondary;
                    }
                }
            }
            &.facebook a {
                @include add-icon-icomoon($facebook);
            }
            &.twitter a {
                @include add-icon-icomoon($twitter);
            }
            &.linkedin a {
                @include add-icon-icomoon($linkedin);
            }
            &.instagram a {
                @include add-icon-icomoon($instagram);
            }
        }
        &__heading__title {
            font-size: $msv-font-size-m;
        }
        &__link { 
            @include vfi();
            display: inline-flex;
            font-size: $msv-font-size-xs;

            img {
                @include vfi();
            }
        }
        & > * {
            justify-content: space-between;
            margin-right: auto;
            margin-left: auto;
            padding: $spacer-xl 0 0 0;
    
            > * {
                max-width: 25%;
                padding-bottom: $spacer-xl;
            
                &:nth-child(4) .ms-footer__list {
                    display: flex;
                    width: auto;
                }
                &:last-child {
                    max-width: 0;
                }
            }
        }
    }
    .ms-text-block {
        color: $white;
        
        p {
            font-size: $msv-font-size-xs;
        }
    }
    .submenu--social {
        flex-wrap: wrap;

        li:first-child {
            width:100%;
        }
        li {
            margin-right: $layout-gutter;
        }
    }
    @media (max-width: $msv-breakpoint-m) {
        .ms-footer {
            margin-left: 0;

            &__item {
                padding-right: 28px;
            }
            > * {
                padding-top: 2rem;

                > * {
                    max-width: 100%;

                    &:nth-child(4) {
                        .ms-footer__list {
                            display: inline-flex;
                        }
                
                        @for $i from 3 to (3 + 2) {
                            .ms-footer__list-item:nth-child(#{$i}) {
                                left: 0px;
                            }
                        }
                    }
                }
            }
        }
    }
    .ms-text-block {
        margin-top:0;
    }
    @media (min-width: $msv-breakpoint-m) {
        .ms-footer .ms-back-to-top[aria-hidden="false"] {
            display: block;
            opacity: 1;
            transform: translateX(0%);
        }
    }
}