.loader {
  background: rgba(255,255,255,0.7);
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  color: $msv-secondary;
  text-align: center;
  &.is--loading {
    opacity: 1;
    visibility: visible;
  }
  &:before {
    position:absolute;
    top:50%;
    left:50%;
    //transform: translate(-50%,-50%);
    content:"";
    width: 2.6rem;
    height: 2.6rem;
    margin-top:-1rem;
    margin-left: -1rem;
    border: 6px solid $msv-gray-300;
    border-top-color: $msv-primary;
    display: block;
    animation: rotate 1s infinite;
    border-radius: 50%;
    -webkit-box-shadow: 0 0 2px 0 rgba(255,255,255,1);
    box-shadow: 0 0 2px 0 rgba(255,255,255,1);
  }
}
@keyframes rotate{
  to{
    transform: rotate(1turn)
  }
}