.ms-promo-banner {
    background-color: $msv-gray-500;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    padding-bottom: $spacer-half;
    padding-top: $spacer-half;
    width: 100%;

    .ms-promo-banner__close-button,
    .ms-promo-banner__text,
    .ms-promo-banner__link {
        color: $msv-white;
    }

    &.alignment__center {
      text-align: center;

      @media (max-width: $msv-breakpoint-m) {
        text-align: left;
      }
    }
    &.alignment__left {
        text-align: left;
    }
    &.alignment__right {
        text-align: right;
    }

    &.theme__light {
        background-color: $msv-gray-100;
        
        .ms-promo-banner__close-button,
        .ms-promo-banner__text,
        .ms-promo-banner__link {
            color: $msv-secondary;
        }
    }

    .ms-promo-banner__content {
        letter-spacing: 0.09rem;

        @media (max-width: $msv-breakpoint-s) {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
        @media (max-width: $msv-breakpoint-m) {
            padding-right: 1.25rem;
            padding-left: 1.25rem;
        }
        .ms-promo-banner__text,
        .ms-promo-banner__link {
            font-size: $msv-font-size-m;
            font-style: normal;
            font-weight: $msv-font-weight-normal;
            overflow-wrap: anywhere;
        }
    
        .ms-promo-banner__link {
            margin-left: $spacer-half;
            text-decoration: underline;
        }
    }

    .msc-carousel__indicators {
        display: none;
    }

    .ms-promo-banner__carousel {
        margin: auto;
        width: 95%;

        @media (max-width: $msv-breakpoint-m) {
            width: 100%;
        }
    }

    .ms-promo-banner__close-button {
        background-color: transparent;
        border: none;
        cursor: pointer;
        &:after {
            @include msv-icon();
            content: $msv-x-shape;
        }
        .ms-promo-banner__close-button__label {
            display: none;
        }
    }
}