.toggler-container {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 1rem;

    &:focus-visible {
        border: none;
        outline: none;
    }

    > span {
        color: $msv-gray-500;
        font-size: $msv-font-size-m;
        font-weight: $msv-font-weight-light;
        line-height: $msv-line-height-m;
        letter-spacing: 0.01em;
        min-width: 2rem;
        text-transform: uppercase;

        @media (max-width: $msv-breakpoint-m) {
            &:first-of-type {
                margin-right: .5rem;
            }
            &:last-of-type {
                margin-left: .5rem;
            }
        }

        &.active {
            color: $msv-black;
            transition: color .35s ease;
        }
    }

    .toggler {
        background: $msv-secondary;
        width:2.6rem;
        display: flex;
        align-items: center;
        border-radius: 1rem;
        margin:0 5px;
        #toggle {
            opacity: 0;
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            cursor: pointer;
            margin: 0;
            z-index: 1;
        }

        #control {
            background: $msv-white;
            border-radius: 100%;
            cursor: pointer;
            display: block;
            height: 0.875rem;
            margin: 3px;
            position: relative;
            transition: 0.2s cubic-bezier(0.55, 0, 0.1, 1);
            width: 0.875rem;
            z-index: 2;
        }
    }
    &:hover {
        .toggler {
            background: $msv-primary--hover;
        }
    }
    &[aria-checked='false']{
        .toggler {
            background: $msv-gray-400;
            justify-content: end;
        }
        &:hover {
            .toggler {
                background: $msv-gray-800;
                box-shadow: none;
            }
        }
    }
}
