.financing-container {
    border: 1px solid $msv-gray-100;
    padding: $spacer*3 $spacer-xl 1.75rem $spacer-xl;
    transition: padding .35s ease;
    
    &.toggled {
        padding: $spacer*3 $spacer-xl $spacer-xl $spacer-xl;
    }

    .financing-container__header {
        display: flex;
        justify-content: space-between;
        align-items: start;

        .financing-container__instructions {
            padding-bottom: .75rem;
    
            h3 {
                font-weight: $msv-font-weight-heavy;
                font-size: $msv-font-size-l;
                letter-spacing: 1.5px;
                line-height: $msv-line-height-l;
            }

            h4 {
                color: $msv-secondary;
                font-size: $msv-font-size-s;
                font-weight: $msv-font-weight-medium;
                text-transform: uppercase;
                letter-spacing: 0.1rem;
            }
            li {
                font-size: $msv-font-size-s;
                line-height: $msv-line-height-s;
                letter-spacing: 0.1rem;
                margin-bottom: .75rem;

                &:before {
                    content: '-';
                    margin-right: 0.25rem;
                }
                span {
                    font-weight: $msv-font-weight-bold;
                    text-transform: uppercase;
                }
            }
        }
    }

    &__request-text {
        margin-top: 1.75rem;
        margin-bottom: 2rem;
        line-height: $msv-line-height-m;

        a {
            color: $msv-secondary;
            text-decoration: underline;
        }
    }
    &__request-checkbox {
        margin-top: .5rem;
        margin-bottom: $spacer-m;
    }
    &__request-actions {
        display: flex;
        justify-content: space-between;
        margin: 1.5rem 0;

        a,
        button {
            cursor: pointer;
            width: 373px;
        }
        .secondary-button:disabled {
            &:hover {
                color: $msv-gray-100;
                background-color: $msv-white;
                background-position: unset;
            }
        }
        .secondary-button::not(:disabled) {
            border: 2px solid $msv-black;

            &:hover, 
            &:focus {
                border-color: $msv-primary;
                background-position: 100%;
            }
        }
    }
    .financing-simulation_error {
        color:$msv-red;
        padding: 0 $spacer-l $spacer-l $spacer-l;
        text-align: center;
    }
}
.ms-checkout__guided-card-btn-redo {
    display: none;
}
.financing-toggeable {
    display: flex;
    flex-direction: column;
    position: relative;
}
.financing-table__heading {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-left: $layout-gutter*2;
    position: relative;

    label,
    .financing-table__heading-title {
        color: $msv-black;
        font-size: $msv-font-size-s;
        line-height: $msv-line-height-m;                
        font-weight: $msv-font-weight-heavy;
        letter-spacing: 0.1rem;
        text-transform: uppercase;
    }

    .sub-label {
        font-size: $msv-font-size-xs;
        line-height: $msv-line-height-xs;
        letter-spacing: 0.1rem;
    }

    .financing-table__heading-subtitle {
        font-size: $msv-font-size-xs;
        font-weight: $msv-font-weight-light;
        line-height: $msv-line-height-xs;
        letter-spacing: 0.01em;
    }
}
.financing-options {
    border: 1px solid $msv-gray-100;
    // margin-right: 7%;
    // flex-basis: 60%;
    width: 100%;

    fieldset {
        border: none;
        margin: 0;
        padding: 0;

        .financing-options__field {
            display: inline-flex;
            align-items: center;
            padding: .75rem .75rem .75rem 1.75rem;
            width: 50%;
            
            &:first-of-type {
                border-right: 1px solid  $msv-gray-100;
            }
            input {

                &+div::before {
                    background-color: $msv-white;
                    box-shadow: 0 0 0 1px $msv-gray-500;
                    position: absolute;
                    top: 1px;
                    left: 0;
                }
                &[aria-checked="true"] ~ div:nth-child(2)::before {
                    background-color: $msv-secondary;
                    box-shadow: 0 0 0 1px $msv-secondary;
                }
            }
        }
    }

    .financing-option {
        border-top: 1px solid $msv-gray-100;
        padding: 0 3.75rem 2rem 3.75rem;

        .financing-legals {
            border-bottom: 1px solid $msv-gray-100;
            padding: 1.25rem 0 3rem 0;

            .text-tooltip > p {
                font-size: $msv-font-size-s;
                font-weight: $msv-font-weight-light;
                line-height: $msv-line-height-s;
                letter-spacing: 1.5px;
    
                span {
                    font-weight: $msv-font-weight-heavy;
                    text-decoration: underline;
                }
                .tooltip {
                    width: 450px;

                    @media screen and (max-width: $msv-breakpoint-m) {
                        width: 100vw;
                    }
                }
            }
        }

        .financing-amount,
        .financing-contribution,
        .financing-duration,
        .financing-table {
            display: flex;
            align-items: center;
        }

        .financing-contribution > div,
        .financing-duration > div {
            flex-basis: 50%;
            width: 100%;
        }

        .financing-amount {
            // border-top: 1px solid $msv-gray-100;
            border-bottom: 1px solid $msv-gray-100;
            justify-content: space-between;
            // margin-top: 1.75rem;
            padding: 1.25rem 0;

            p {
                font-size: $msv-font-size-s;
                font-weight: $msv-font-weight-heavy;
                line-height: $msv-line-height-s;
                letter-spacing: 1.5px;
                margin-bottom: 0;
            }
            span {
                font-size: $msv-font-size-l;
                font-weight: $msv-font-weight-heavy;
                line-height: $msv-line-height-m;
                letter-spacing: 1.5px;
            }
        }

        .financing-contribution {
            border-bottom: 1px solid $msv-gray-100;
            justify-content: space-between;
            padding: .5rem 0;

            span {
                font-size: $msv-font-size-s;
                font-weight: $msv-font-weight-heavy;
                line-height: $msv-line-height-s;
                letter-spacing: 1.5px;
            }
            p {
                color: $msv-gray-500;
                font-size: $msv-font-size-xs;
                font-style: normal;
                font-weight: $msv-font-weight-light;
                line-height: $msv-line-height-s;
                letter-spacing: 0.01em;
                max-width: 349px;
            }
            .input__currency-prefixed {

                > div {
                    position:relative;
                }

                >div::before {
                    content: '€';
                    position: absolute;
                    left: $layout-gutter/2;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    font-weight: $msv-font-weight-medium;
                    line-height: 35px;
                }

                input {
                    min-height: $spacer;
                    padding-left: 1.75rem;
                    -moz-appearance: textfield;
    
                    &::-webkit-outer-spin-button,
                    &::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                    }
                }
            }
        }

        .financing-duration {
            border-bottom: 1px solid $msv-gray-100;
            justify-content: space-between;
            padding: 1.5rem 0;

            > div:first-of-type {
                display: flex;
                align-items: center;
            }

            .financing-duration__slider-container .slider.slider-horizontal {
                margin-top: 1.5rem;
                margin-left: auto;
                width: 60%;

                .slider__track {
                    background-color: transparent;
                }
            }

            .financing-duration__single-container {
                text-align: right;
                font-weight: $msv-font-weight-bold;
            }
            p {
                font-size: $msv-font-size-s;
                font-weight: $msv-font-weight-heavy;
                line-height: $msv-line-height-s;
                letter-spacing: 1.5px;
            }
            .slider .slider__labels-item {
                font-weight: $msv-font-weight-medium;
                margin-top: $spacer;
                padding-bottom: 0;
            }
        }

        .financing-table {
            flex-direction: column;
            padding-left: 0;

            li {
                align-items: center;
                border-bottom: 1px solid $msv-gray-100;
                display: flex;
                justify-content: space-between;
                padding: 1.25rem $layout-gutter/4;

                &:first-of-type {
                    border-top: 1px solid $msv-gray-100;
                }
                > span:first-of-type {
                    flex: 1;
                    font-size: $msv-font-size-s;
                    font-weight: $msv-font-weight-heavy;
                    line-height: $msv-line-height-s;
                    letter-spacing: 1.5px;
                }
                > span:last-of-type {
                    font-size: $msv-font-size-l;
                    font-weight: $msv-font-weight-heavy;
                    line-height: $msv-line-height-m;
                    letter-spacing: 1.5px;
                }
            }
        }

        .financing-cta button {
            display: block;
            margin: 2rem auto 0 auto;
            @extend %primary-button;
        }
    }
}
.financing-simulation {
    padding: 0 3rem 2rem 3rem;
    
    .financing-table__heading {
        border-bottom: 1px solid $msv-gray-100;
        padding: .75rem .75rem .75rem 1.75rem;

        .financing-table__heading-title {
            margin-bottom: $spacer/4;
        }
    }

}
.financing-simulation__table {
    background-color: #f7f7f7;
    padding: 1.25rem 2rem 1.5rem 2rem;
    
    &-simulation {
        display: none;
    }

    .financing-simulation__table-head {
        margin-bottom: 2rem;

        p {
            font-size: $msv-font-size-s;
            font-weight: $msv-font-weight-medium;
            letter-spacing: 1px;
            margin-bottom: 0;
            text-transform: uppercase;
        }
        p:last-of-type {
            color: $msv-secondary;
        }
    }
    li {
        border-bottom: 1px solid $msv-gray-100; 
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-top: $spacer*2;
        padding-bottom: $spacer/4;

        &.financing-simulation__maxirata {
            display: block;
            border-bottom-color: $msv-black;
            padding-bottom: $spacer;

            > div:first-of-type {
                display: flex;
                justify-content: space-between;
                align-items: baseline;
            }
            > div:last-of-type {
                margin-top: 1rem;

                p {
                    margin-bottom: .25rem;
                }
                p:last-of-type > span {
                    text-transform: uppercase;

                    &.bold {
                        font-weight: $msv-font-weight-medium;
                    }
                    &.financing-simulation__maxirata-tan,
                    &.financing-simulation__maxirata-taeg {
                        display: inline-flex;
                        align-items: center;
                        margin-left: 1.5rem;

                        > span {
                            font-weight: $msv-font-weight-medium;
                            margin-left: .25rem;
                        }
                        .icon-tooltip.icon-tooltip__small {
                            display: inline;
                            margin-left: $spacer;
                        }
                    }
                }
            }
        }
        .simulation-value {
            font-size: $msv-title-font-size-xs;
            font-weight: $msv-font-weight-medium;
            line-height: $msv-line-height-l;
            letter-spacing: 0;
            text-align: right;
        }
        .simulation-label {
            color: $msv-gray-500;
            display: flex;
            align-items: baseline;
            font-size: $msv-font-size-s;
            font-weight: $msv-font-weight-medium;
            line-height: $msv-line-height-s;
            letter-spacing: 1.5px;
            text-transform: uppercase;

            .icon-tooltip {
                color: $msv-black;
                margin-right: $layout-gutter;
            }
        }
    }
}
.ms-order-confirmation__side.financing-simulation .financing-simulation__table,
.ms-checkout__side .financing-simulation__table {
    background-color: transparent;
    padding: 0;

    .financing-table__heading-title {
        border-bottom: 1px solid $msv-gray-100;
        font-weight: $msv-font-weight-medium;
        font-size: $msv-font-size-l;
        line-height: $msv-line-height-m;
        padding: .5rem .5rem .5rem 0; 
        margin-top: 2rem;
    }
    .no-bd {
        border: none;
    }
    ul:last-of-type li:last-of-type span:last-of-type {
        color: $msv-black;
    }
}
@media (max-width: $msv-breakpoint-l) {
    .financing-toggeable {
        flex-direction: column;
    }
    .financing-options {
        margin-right: 0;

        .financing-option {

            .financing-amount,
            .financing-contribution,
            .financing-duration,
            .financing-table {
                align-items: flex-start;
            }
        }
    }
    .financing-simulation {
        margin-top: $spacer;
        padding: 0;
    }
}
@media (max-width: $msv-breakpoint-m) {

    .financing-container,
    .financing-container.toggled {
        padding: $spacer $layout-gutter;

        .financing-container__header {
            flex-direction: column;
            margin-bottom: 2rem;
            align-items: flex-start;
        }
    }
    .financing-container.requested {
        border: none;
        padding: 0;

        .financing-container__title {
            font-size: $msv-font-size-l;
        }
        .financing-container__request-text {
            font-size: $msv-font-size-s;
            margin-top: 0;
        }
        .financing-container__request-actions {
            margin-top: 3rem;
            flex-direction: column;
    
            button {
                width: 100%;
            }
            .primary-button {
                margin-top: $spacer;
            }
        }
    }
    .financing-options {

        fieldset {

            .financing-options__field {
                padding: .75rem .75rem;
                width: 100%;

                &:first-of-type {
                    border-right: 0;
                    border-bottom: 1px solid $msv-gray-100;
                }
                input {

                    &+div::before {
                        top: 3px;
                        height: $spacer-m;
                        width: $spacer-m;
                    }
                }
            }
        }
        .financing-option {
            padding: 0 .75rem $spacer .75rem;
            
            .financing-legals{
                padding-bottom: 1.25rem;
            }
            .financing-amount,
            .financing-contribution,
            .financing-duration {
                flex-direction: column;
                margin: 0;
                padding: 1rem 0;
            }
            .financing-amount {
                flex-direction: row;
                align-items: center;

                p {
                    color: $msv-gray-500;
                    font-size: $msv-font-size-xs;
                    margin-bottom: 0;
                }
                span {
                    font-weight: $msv-font-weight-light;
                }
            }
            .financing-contribution {
                .input__currency-prefixed {
                    margin: $spacer 0 0 0;

                    >div {
                        margin-left: 0;
                        width: 100%;
                    }
                    input {
                        border: 1px solid $msv-gray-100;
                    }
                }
            }
            .financing-duration {
                padding-bottom: 2rem;

                .financing-duration__slider-container {
                
                    .slider.slider-horizontal {
                        margin: $spacer-l 0;
                        width: 100%;
                        
                        .slider__labels-item {
                            margin-left: 0;
                        }
                    }
                }
                .financing-duration__single-container {
                    font-weight: $msv-font-weight-light;
                    margin-bottom: -$spacer;
                    text-align: left;
                }
            }
            .financing-table li {

                span:first-of-type {
                    color: $msv-gray-500;
                    font-weight: $msv-font-weight-heavy;
                }
                span:last-of-type {
                    font-size: $msv-font-size-m;
                    font-weight: $msv-font-weight-light;
                }
            }
            .financing-cta button {
                font-size: $msv-font-size-m;
                padding: $spacer 2rem;
                width: 100%;
            }
        }
    }
    .financing-simulation__table {
        padding-right: .75rem;
        padding-left: .75rem;

        li {
            flex-direction: row-reverse;

            .simulation-value {
                font-size: $msv-font-size-m;
                font-weight: $msv-font-weight-light;

            }
            .simulation-label {
                font-size: $msv-font-size-xs;
                
                .icon-tooltip {
                    order: 1;
                    margin-left: $layout-gutter;
                }
            }
            &.financing-simulation__maxirata {
                display: flex;
                flex-direction: column;

                > div:first-of-type {
                    flex-direction: row-reverse;
                    width: 100%;
                }
                > div:last-of-type p:last-of-type > span {

                    &.financing-simulation__maxirata-tan,
                    &.financing-simulation__maxirata-taeg {
                        display: block;
                        margin: .25rem 0;
                    }
                }
            }
        }
    }
    .financing-table__heading {
        padding-left: 2.5rem;
        padding-right: 1rem;
    }
}