.default-container {
    &.container {
        padding-left:$spacer-m;
        padding-right: $spacer-m;

        @media screen and (max-width: $msv-breakpoint-s) {
            padding-left: $spacer;
            padding-right: $spacer;
        }
    }
    @media screen and (max-width: $msv-breakpoint-s) {
        &.homepage-gotostore__banner  {
            .ms-content-block.textplacement__center.texttheme__dark {
                margin-left: -$spacer;
                margin-right: -$spacer;
                margin-bottom: -5rem;

                &[data-m-layout="full-width"] .ms-content-block__details {
                    padding: $spacer;
                }
            }
        }
    }
    &.theme--gray {
        position: relative;
        background: $msv-gray-100;

        &:before,
        &:after {
            position:absolute;
            z-index:0;
            top:0;
            left: 0;
            height: 100%;
            width: 25vw;
            content:"";
            display: block;
            background: $msv-gray-100;
            transform: translateX(-100%);
        }
        &:after {
            transform: translateX(0);
            left: auto;
            right: -25vw;
        }
    }
    &.theme--dark {
        color:$msv-white;

        .row {
            margin:0;
            background:$msv-primary;
        }
    }
    &.homepage-howto {
        margin-top: -1px;
        padding: 0 4.75rem;

        .row {
            padding: 0 2rem;
        }

        @media (max-width: $msv-breakpoint-l) {
            padding: 0;
            
            .row > div {
                padding: 0;
            }
        }
    }
}