$spacer-size-desktop: $spacer-xl;
$spacer-size-mobile: $spacer-m;

.ms-spacer {
  height: $spacer-size-mobile;

  @media screen and (min-width: $msv-breakpoint-m) {
    & {
      height: $spacer-size-desktop;
    }
  }
  &1x {
    height: $spacer;
  }
  &2x {
    height: $spacer*2;
  }
  &3x {
    height: $spacer*3;
  }
  &4x {
    height: $spacer*4;
  }
  &5x {
    height: $spacer*5;
  }
  &6x {
    height: $spacer*6;
  }
  &7x {
    height: $spacer*7;
  }
  &8x {
    height: $spacer*8;
  }
  &9x {
    height: $spacer*9;
  }
  &10x {
    height: $spacer*10;
  }
}
