.msc-invoices-list {
    @include font-content(var(--msv-font-weight-normal), var(--msv-body-font-size-m), $msv-line-height-m);

    &__header {
        @include font-content(var(--msv-font-weight-normal), var(--msv-body-font-size-xl), $msv-line-height-xl);
        color: var(--msv-font-primary-color);
        height: 40px;
        margin: 20px 0;
    }

    &__filter {
        padding-bottom: 20px;

        @media screen and (min-width: $msv-breakpoint-m) {
            position: absolute;
            right: 0;
        }

        &__expand-filter-button {
            background-color: unset;
            min-width: 140px;

            &:after {
                @include msv-icon();
                content: $msv-ChevronDown;
                font-size: var(--msv-header-font-size);
                margin-left: $header-myaccount-margin-left;
                text-align: center;
                vertical-align: text-bottom;
                line-height: 1.2;
                margin: 0 8px;
            }
        }

        &__expand-filter-button[aria-expanded=true] {
            &:after {
                content: $msv-ChevronUp;
            }
        }

        .msc-popover {
            background-color: var(--msv-header-bg);
            background-clip: padding-box;
            border: 1px solid $msv-gray-500;
            display: block;
            left: 0;
            max-width: $account-links-max-width;
            position: absolute;
            top: 0;
            word-wrap: break-word;
            z-index: 1060;

            a {
                align-items: center;
                display: flex;
                font-size: 16px;
                height: 100%;
                line-height: 20px;
                padding: 0 20px;
                height: 48px;
                cursor: pointer;

                &:hover {
                    text-decoration: none;
                }
            }

            .msc-btn {
                color: var(--msv-header-font-color);
                font-weight: var(--msv-font-weight-normal);
                height: $popover-menu-item-height;
            }

            .msc-arrow {
                display: block;
                height: .5rem;
                margin: 0 .3rem;
                position: absolute;
                top: calc((.5rem + 1px) * -1);
                width: 1rem;

                &::before,
                &::after {
                  border-color: transparent;
                  border-style: solid;
                  border-width: 0 .5rem .5rem .5rem;
                  content: "";
                  display: block;
                  position: absolute;
                }

                &::before {
                    border-bottom-color: $msv-gray-500;
                    top: 0;
                }

                &::after {
                    border-bottom-color: var(--msv-border-color);
                    top: 1px;
                }
            }
        }
    }

    &__empty-lines__container {
        text-align: center;
        padding-top: 80px;

        &__title {
            font-size: 24px;
            line-height: 31px;
        }
        &__description {
            font-size: 16px;
            line-height: 21px;
            margin-top: 20px;
        }
        &__actions__continue-shopping {
            @include primary-button();
            margin: 40px auto;
            display: block;
            width: fit-content;

            @media only screen and (max-width: $msv-breakpoint-l) {
                width: 100%;
            }
        }
    }

    &__container {
        &__header {
            &__make-payment {
                @include primary-button();

                @media only screen and (max-width: $msv-breakpoint-l) {
                    width: 100%;
                }
            }
        }

        &__content {
            margin-top: 20px;

            &__table {
                width: 100%;
                border-collapse: collapse;

                .checkbox-container {
                    display: block;
                    position: relative;
                    margin-left: 18px;
                    padding-left: 35px;
                    cursor: pointer;
                    font-size: 22px;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                    height: 19px;

                    .checkbox-input {
                        position: absolute;
                        opacity: 0;
                        cursor: pointer;
                        height: 0;
                        width: 0;
                    }

                    .checkmark {
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 19px;
                        width: 19px;
                        background-color: transparent;
                        border: 1px solid var(--msv-accent-brand-color);
                        border-radius: 1rem;
                    }

                    input:checked ~ .checkmark {
                        background-color: var(--msv-accent-brand-color);
                    }

                    .checkmark:after {
                        content: "";
                        position: absolute;
                        display: none;
                    }

                    input:checked ~ .checkmark:after {
                        display: block;
                    }

                    .checkmark:after {
                        left: 6px;
                        top: 2.25px;
                        width: 5px;
                        height: 10px;
                        border: solid yellow;
                        background-color: green;
                        border-width: 0 1.75px 1.75px 0;
                        -webkit-transform: rotate(45deg);
                        -ms-transform: rotate(45deg);
                        transform: rotate(45deg);
                    }
                }

                &__header {
                    text-align: left;
                    @include divider(bottom);

                    &__row {
                        height: 50px;

                        th {
                            font-style: normal;
                            font-weight: normal;
                            font-size: 16px;
                            line-height: 21px;
                        }
                    }
                }

                &__row {
                    height: 81px;
                    @include divider(bottom);

                    &__open-invoice {
                        text-decoration: underline;
                        cursor: pointer;
                    }

                    &__pay-invoice {
                        @include secondary-button();
                    }

                    &__extra-actions-cell {
                        &__toggle {
                            @include add-icon($msv-Ellipses-V);
                            background: none;
                            height: 82px;
                        }

                        &__actions-container {
                            align-items: flex-start;
                            background: $msv-white;
                            border-radius: 2px;
                            box-shadow: 0px 3.2px 7.2px rgba(0, 0, 0, 0.132), 0px 0.6px 1.8px rgba(0, 0, 0, 0.108);
                            flex-direction: column;
                            height: auto;
                            padding-left: 0px;
                            position: absolute;
                            left: 0;
                            width: 100%;
                            z-index: 10;

                            &__view-details {
                                @include add-icon($msv-List);
                            }

                            &__pay-invoice {
                                @include add-icon($msv-Arrow-Right-In-Circle, before, $msv-outline-icon-weight);
                            }

                            button {
                                background: none;
                                height: 60px;
                                padding: 0px 8px;
                                text-align: left;
                                width: 100%;

                                &:before {
                                    margin-right: 12px;
                                    width: 16px;
                                    color: var(--msv-accent-brand-color);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}