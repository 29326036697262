// Import bootstrap css
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/grid";
@import '../00-settings/responsive-breakpoints';

// Container width
body .container {
  @media screen and (min-width: $msv-breakpoint-s) {
    max-width: $msv-breakpoint-xl;
    width: 100%;
  }
}