@media (max-width: $msv-breakpoint-m) {
    .default-container.container.OrderDetails {
        padding-right: $layout-gutter;
        padding-left: $layout-gutter;
    }
}
.OrderDetails h1 {
    text-align: center;
    @extend %h2;
    padding: $spacer-l $layout-gutter $spacer $layout-gutter;
    width: 100%;
}
.ms-order-details {
    margin-bottom:$spacer-l;

    &__product-mobile {
        display: none;
    }
    .small {
        p {
            font-size: $msv-font-size-xs;
        }

        a {
            color:$msv-secondary;
            text-decoration:underline;
        }
    }
    &__order-information {
        & > * {
            letter-spacing: 1.5px;
        }
        &-sales-id,
        &-created-date {
            font-weight: $msv-font-weight-heavy;
        }
    }
    &__yourcommand {
        color: $msv-secondary;
        font-size: $msv-title-font-size-xs;
        font-weight: $msv-font-weight-heavy;
        line-height: 54px;
        margin-bottom: 0;
    }
    &__groups {
        margin-top: 24px;
    }
    &__sales-lines_container {
        background-color: #f5f5f5;   
    }
    &__sales-status {
        display: none;
    }
    .msc-cart-line {
        display: flex;
        color: $msv-black;

        .msc-order-summary-totalPrice-label {
            display:none;
        }
        &__product-image {
            background-color: #e7e7e8;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 0;
            min-height: 284px;
            max-width: 482px;
            padding: $spacer*2 $layout-gutter 1.25rem $layout-gutter;

            img {
                width: 448px;
            }
        }
        &__content {
            display: flex;
            flex-direction: column;
            padding: 1.25rem 2.5rem 1.25rem 2.25rem;

            > div {
                display: flex;
                justify-content: space-between;
            }
        }
        &__product-title {
            font-size: $msv-title-font-size-xs;
            line-height: 1.75rem;
            margin-bottom: 1.75rem;

            &:hover {
                text-decoration: none;
            }
        }

        &__product {
            flex: unset;

            &-price {
                display: block;
                margin-top: $spacer-m;
            }
            &-savings {
               display: none;
            }
        }
        &__quantity {
            display: none;
        }
        .msc-price__prefix,
        .msc-price__actual {
            font-weight: $msv-font-weight-heavy;
            letter-spacing: 1.5px;
            text-transform: uppercase;
        }
        .msc-price__suffix {
            color: $msv-gray-500;
            font-size: $msv-font-size-xs;
            letter-spacing: 1.5px;

            .icon-tooltip.icon-tooltip__small {
                top: 3px;
                margin-left: 2px;
            }
        }
    }
    
    .substep {
        &__progress-text,
        &__progress-bar {
            &__Creato {
                width: 25%;
            }
            &__Confermato {
                width: 50%;
            }
            &__Pagato {
                width: 75%;
            }
            &__Completo {
                width: 100%;
            }
        }
        &__progress-text {
            display: flex;
            align-items: center;
            height: 3.375rem;

            > span {
                font-size: $msv-font-size-l;
                font-weight: $msv-font-weight-heavy;
                text-align: center;
            }
        }
        &__progress-bar {
            background-color: $msv-gray-100;
            height: .5rem;
            width: 100%;

            > div {
                background-color: $msv-secondary;
                height: .5rem;
            }
        }
        &-title {
            font-size: 2rem;
            font-weight: $msv-font-weight-heavy;
            line-height: 3.375rem;
            margin-top: $spacer-m;
            padding-left: .25rem;
    
            span {
                color: $msv-secondary;
            }
        }
        &-infos {
            margin-top: 1.25rem;
            h6 {
                margin-bottom: 4px;
            }
        }
        &-info__container {
            display: flex;
            align-items: center;
            outline: 1px solid $msv-gray-100;
            padding: 1.25rem;

            .icon {
                color: $msv-secondary;
                font-size: $msv-title-font-size-s;
                margin-right: 1.875rem;
                width: 4.125rem;
            }
            p {
                font-size: $msv-font-size-s;
            }
        }
        &-extra-info__container {
            margin-bottom: $spacer-l;
        }
        &-info {
            display: flex;
            flex-direction: column;
            flex: 1;

            &__title {
                font-weight: $msv-font-weight-heavy;
                text-transform: uppercase;
            }
            &__description {
                margin-bottom: 0;
            }
            &__payment {
                margin-top: $spacer-l;
                border:1px solid $msv-gray-100;
                padding: 0 $spacer;
                > div {
                    padding: $spacer;
                }
                &__amount {
                    text-transform: uppercase;
                    padding: $spacer;
                    border-bottom:1px solid $msv-gray-100;
                    h5 {
                        margin: 0;
                    }
                    span {
                        color:$msv-secondary;
                    }
                }
            }
        }
    }
    .msc-cart-line__financing {
        &-variants__title {
            margin-bottom: $spacer-m;
        }
    }
    .financing-container {
        margin: $spacer-l auto;

        .financing-container__title {
            font-size: 1.375rem;
            font-weight: $msv-font-weight-heavy;
            line-height: $msv-line-height-m;
            letter-spacing: 1.5px;
        }
    }
    .proceed-btn.primary-button {
        display: flex;
        justify-content: center;
        margin: 0 auto 2.5rem auto;
        width: 277px;
    }
    .cancel-order__container {
        display: flex;

        .cancel-order {
           // border: 1px solid $msv-gray-100;
            display: inline-flex;
            align-items: center;
            flex-wrap: wrap;
            margin-top: 2.5rem;
            margin-left: auto;
            padding: $spacer 3rem $layout-gutter 2rem;

            > .icon {
                margin-right: 1.25rem;
            }
            .icon {
                font-size: $msv-font-size-l;
            }
            .cancel-order__heading {
                display: flex;
                align-items: center;
    
                .label {
                    font-size: $msv-font-size-xs;
                    line-height: $msv-line-height-s;
                    letter-spacing: 1.5px;
                    margin-right: $layout-gutter;
                }
            }
            .cancel-order__action,
            .cancel-order__instructions {
                font-size: $msv-font-size-xs;
                line-height: $msv-line-height-xs;
                letter-spacing: 1.5px;
                margin-bottom: 0;
            }
            .cancel-order__action {
                font-weight: $msv-font-weight-medium;
            }
            .cancel-order__instructions a {
                text-decoration: underline;
            }
        }
    }
    &__recap-container {
        border: 1px solid $msv-gray-100;
        margin: 3rem 0;
        padding: 2.5rem 3rem;

        &__title {
            font-size: 1.375rem;
            font-weight: $msv-font-weight-heavy;
            line-height: $msv-line-height-l;
        }
        .ms-accordion {
            padding: 0;

            .drawer__buttontext span {
                font-size: $msv-font-size-s;
                margin-left: .5rem; 
            }
        }
    }
    &__documents-addresses {
        margin-top:$spacer-xl;
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: $spacer-xl;

        @media (max-width: $msv-breakpoint-l) {
            display: flex;
            flex-direction: column;
        }
    }
    &__documents,
    &__addresses {
        border: 1px solid $msv-gray-100;
        padding: $spacer-l;
    }
    &__documents {
        @media (max-width: $msv-breakpoint-l) {
            margin-bottom: 2rem;
        }

        .subtitle {
            color:$msv-secondary;
            font-size: 1rem;
        }
        .instructions {
            font-size: $msv-font-size-xs;
        }
        &__type-line {
            border:1px solid $msv-gray-100;
            margin:$spacer 0;
            padding:$spacer;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            &__title {
                text-align: left;
                flex: 0 0 50%;

                .small {
                    margin-top: .5rem;
                }
            }
            &__list {
                text-align: right;
                max-width: 50%;

                .file {
                    display: flex;
                    justify-content: flex-end;
                    align-items: last baseline;

                    button {
                        color:$msv-secondary;
                        background: none;
                        outline: none;
                        border: none;
                        margin-left: $spacer-half;
                        padding: 0;
                        line-height: 1.5em;
                        cursor: pointer;
                    }
                }
                .file-name {
                    width: 100%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: block;
                    font-size:0.875rem;
                }
            }
        }
        &__upload-file {
            @extend %underline-button;
            cursor: pointer;
            outline: none;
        }
    }
    &__addresses {
        white-space: pre-wrap;
        form {
            position:relative;
        }
        &__heading {
            display: flex;
            justify-content: space-between;
            align-items: center;
            button {
                @extend %underline-button;
            }
        }
        small {
            font-size: $msv-font-size-xs;
        }
        label.type__radio {
            margin-bottom: $spacer-l;

            input[type="radio"] + span > span {
                margin-left: 2rem;
            }
        }
        input[type="radio"] ~ *:nth-child(2) {
            display: flex;
        }
        button[type='submit']{
            @extend %primary-button;
            width:100%;
        }
        input:read-only {
            background-color: $msv-gray-100;
            border: none;
        }
    }

	@media (max-width: $msv-container-max-width-m) {
        .msc-cart-line {
            flex-direction: column;

            &__product-image {
                max-height: 300px;
                min-width: 100%;
            }
            &__product {
                width: 100%;
            }
            &__content {

                > div {
                    flex-direction: column;

                    .msc-cart-line__financing {
                        border-top: 1px solid rgba(104, 109, 113, 0.49);
                        border-left: none;
                        padding-left: 0;
                        padding-top: 1.75rem;
                        margin-top: 1.75rem;
                        width: 100%;
                    }
                }
            }
        }
    }
}
@media (max-width: $msv-breakpoint-m) {
    .OrderDetails .col-12 {
        position: static;
    }
    .ms-order-details__product-desktop,
    .ms-order-details__order-information {
        display: none;
    }
    .ms-order-details__product-mobile {
        background: $msv-white;
        display: block;
        position: sticky;
        top: 0;
        outline: 1px solid $msv-black;
        padding: 1.25rem $layout-gutter 1.25rem $layout-gutter;
        z-index: 1;

        .side__mobile-head {
            display: flex;
            align-items: flex-start;
            
            > div:first-of-type {
                display: flex;
                flex-direction: column-reverse;
                padding-right: 0;
                max-width: calc(100% - 120px);
            }
            > div:last-of-type {
                margin-left: auto;

                .btn-primary {
                    background-color: $msv-black;
                    color: $msv-white;
                    padding: .875rem .5rem;
                }
                .icon-close-modal {
                    display: none;
                }
            }
        }
        .ms-order-details {
            margin-bottom: 0;

            &__groups {
                margin-top: 0;
            }
            .msc-cart-line {

                &__product-variants__title,
                &__product-variants,
                &__product-image,
                &__product-price .msc-price__prefix,
                &__product-price .msc-order-summary-totalPrice-label {
                    display: none;
                }
                &__content {
                    padding: 0 $layout-gutter 0 0;
                }
                &__product-title {
                    line-height: 1.2em;
                }
                &__product {
                    
                    &-image {
                        background-color: rgba(191, 194, 196, 0.1);
                        margin-left: -$layout-gutter;
                        max-width: none;
                        width: calc(100vw - 2rem);
                    }
                    &-title {
                        font-size: $msv-font-size-s;
                        margin-bottom: 0;
                        margin-top: -2px;
                    }
                    &-variants__title {
                        margin-bottom: 1.5rem;
                    }
                    &-variant-item {
                        margin-bottom: 1.75rem;
        
                        .label {
                            font-weight: $msv-font-weight-medium;
                            letter-spacing: normal;
                        }
                        .name {
                            font-weight: $msv-font-weight-light;
                        }
                    }
                }
                &__product-price {
                    text-transform: unset;

                    .msc-price__suffix {
                        color: $msv-black;
                        font-size: $msv-font-size-xs;
                        margin-top: 0;
                    }
                }
            }
            &__sales-lines_container {
                background-color: transparent;

                .msc-order-summary {
        
                    &-totalPrice {
                        margin-bottom: .5rem;
                    }
                    &-totalPrice-value {
                        font-size: 1rem;
                        font-weight: $msv-font-weight-light;
                    }
                    &-totalPrice-info {
                        color: $msv-black;
                        font-size: $msv-font-size-xs;
                        text-transform: unset;
                        margin-top: 10px;
                    }
                }
            }
        }
        &.opened {
            max-height: calc(100vh - 2px);
            overflow-x: hidden;
            overflow-y: scroll;
            padding-top: 0;
            position: fixed;
            top: 1px;
            width: calc(100vw - 2rem);

            .side__mobile-head {
                flex-wrap: wrap;

                > div:first-of-type {
                    max-width: 100%;
                }
            }
            .ms-order-details {

                .msc-cart-line {
                    &__product-variants__title,
                    &__product-variants {
                        display: block;
                    }
                    &__product-price .msc-price__prefix {
                        display: inline;
                    }
                    &__product-image {
                        display: flex;
                    }
                    &__product-title {
                        position: absolute;
                        top: 1.125rem;
                        width: 75%;
                    }
                }
            }
            .side__mobile-head > div:last-of-type {

                .btn-primary {
                    display: none;
                }
                .icon-close-modal {
                    display: block;
                    font-size: 1.5rem;
                    position: absolute;
                    top: 1rem;
                    right: 1rem;
                }		
            }
        }
    }
    .ms-order-details {

        .ms-order-details__yourcommand {
            display: none;
        }
        .substep {
            display: flex;
            flex-direction: column;

            &-title {
                order: 0;
                font-size: $msv-font-size-m;
                line-height: 2.5rem;
                padding-left: 0;
            }
            &__progress-bar {
                order: 1;
            }
            &__progress-text {
                order: 2;
                height: auto;

                > span {
                    font-size: $msv-font-size-s;
                    line-height: $msv-line-height-m;
                    text-align: left;
                }
            }
            &__progress-text__Pagato {
                margin-bottom: 1.5rem;
            }
            &-extra-info__container {
                margin-top: 2.5rem;;
            }
            &-info__container {
                align-items: flex-start;
                outline-color: $msv-secondary;

                .icon {
                    font-size: $msv-font-size-xl;
                    margin-top: .25rem;
                    margin-right: $layout-gutter;
                    width: auto;
                }
            }
        }
        .proceed-btn.primary-button {
            background-position: 100%;
            margin-bottom: 0;
            width: 100%;
        }
        .cancel-order__container .cancel-order {
            justify-content: center;
            margin-right: auto;
            padding: 0;
            width: 100%;
        }
        &__documents-addresses {
            margin-top: 2rem;
        }
        &__documents {
            padding: $spacer $layout-gutter;

            &__upload-file {
                border-bottom-color: $msv-secondary;
                border-bottom-width: 1px;
                color: $msv-secondary;
                font-size: $msv-font-size-s;
                font-weight: $msv-font-weight-light;
                line-height: normal;
            }
            &__type-line {
                
                &__list {
                    align-self: center;
                }
            }
        }
        &__addresses {
            padding: $spacer $layout-gutter;
            position: relative;

            &__heading {
                h5 {
                    margin-bottom: 2rem;
                }
                button {
                    border-bottom-color: $msv-secondary;
                    border-bottom-width: 1px;
                    color: $msv-secondary;
                    font-size: $msv-font-size-s;
                    font-weight: $msv-font-weight-light;
                    line-height: normal;
                    position: absolute;
                    bottom: 1rem;
                    right: 1rem;
                    text-transform: none;
                }
            }
            .personal-data--recap {
                margin-top: -1.5rem;
            }
            label {
                color: $msv-black;
                line-height: $msv-line-height-xs;
                margin-bottom: 2px;
                text-transform: unset;
            }
            input {
                border: 1px solid $msv-gray-500;
                font-size: $msv-font-size-s;
                font-weight: $msv-font-weight-light;
                padding: .75rem .5rem;
            }
            select {
                color: $msv-black;
                font-weight: $msv-font-weight-light;
                padding: 0 .5rem;
    
                &:disabled {
                    background-color: $msv-gray-100;
                }
            }
            button[type="submit"] {
                margin-top: 2.5rem;
            }
        }
        &__recap-container {
            border: none;
            padding: 0;

            &__title {
                font-size: $msv-font-size-m;
            }
            .ms-accordion-item__drawer .drawer__button.msc-btn {
                font-weight: $msv-font-weight-light;
            }
        }
    }
}