//style presets
:root {
    --msv-buybox-btn-bg: #{$msv-white};
    --msv-buybox-btn-font-color: var(--msv-font-primary-color);
    --msv-buybox-btn-border-color: var(--msv-border-color);
    --msv-buybox-btn-font-size: var(--msv-body-font-size-l);

    //title
    --msv-buybox-title-font-size: var(--msv-body-font-size-xxl);
    --msv-buybox-title-font-color: var(--msv-font-primary-color);

    // rating star
    --msv-buybox-rating-star: var(--msv-font-primary-color);

    --msv-buybox-btn-extra-actions-color: var(--msv-accent-brand-color);

    //text
    --msv-buybox-text-font-size: var(--msv-body-font-size-m);
    --msv-buybox-text-font-color: var(--msv-font-primary-color);

    // primary button
    --msv-buybox-primary-btn-bg: var(--msv-accent-brand-color);
    --msv-buybox-primary-btn-font-color: var(--msv-font-secondary-color);
    --msv-buybox-primary-btn-border: var(--msv-accent-brand-color);

    // secondary button
    --msv-buybox-secondary-btn-bg: var(--msv-secondary-button-background-color);
    --msv-buybox-secondary-btn-font-color: var(--msv-font-primary-color);
    --msv-buybox-secondary-btn-border: var(--msv-accent-brand-color);
}

.ms-buybox {
    display: flex;
    flex-wrap: wrap;
    flex-flow: row;
    margin-top: 2.25rem;
    &__cart__warning {
        color:$msv-red;
    }
    .ms-media-gallery {
        .msc-carousel__inner {
            background-color: rgba(191, 194, 196, 0.1);
            max-height: 732px;
        }
    }

    &__inventory-info {
        margin-top: 1.25rem;
    }

    &__product-title {
        @extend %h3;
        font-weight: $msv-font-weight-heavy;
    }

    &__inventory-label {
        @include font-content(var(--msv-font-weight-normal), var(--msv-body-font-size-l), $msv-line-height-m);
    }

    &__product-description {
        margin-top: 1.25rem;
        margin-bottom: 4px;
    }

    .msc-rating {
        &__count,
        &__star {
            color: var(--msv-buybox-rating-star);
        }

        &__half-star {
            &:after {
                color: var(--msv-buybox-rating-star);
            }
        }
    }
    .product-infos {
        margin-bottom: $spacer;

        .pre-price__actual {
            display: inline-block;
            font-size: $msv-font-size-l;
            margin-right: $layout-gutter/4;
        }

        .msc-price__actual {
            font-size: $msv-font-size-s * 2;
            font-weight: $msv-font-weight-medium;
            line-height: $msv-line-height-s * 2;
            letter-spacing: $layout-gutter/10;
        }

        .price-offer {
            font-size: $msv-font-size-s;
            line-height: $msv-line-height-s;
            margin-bottom: $spacer-half;
            > div {
                margin-bottom: 4px;
            }
            .price-original {
                color: $msv-gray-500;
            }
            .discount-lines {
                span {
                    &:after {
                        content: ', ';
                    }
                    &:last-child {
                        &:after {
                            content: none;
                        }
                    }
                }
            }
            .msc-price__strikethrough {
                margin-left: $layout-gutter/4;
            }

            .offer-duration,
            .financing-val,
            .tan-val,
            .taeg-val {
                text-transform: uppercase;
                font-weight: $msv-font-weight-medium;
            }
        }

        .price-note {
            color: $msv-gray-500;
            font-size: 0.75rem;
            letter-spacing: $layout-gutter/100;
        }

        .red-link {
            color: $msv-secondary;
            font-size: $msv-font-size-xs;
            letter-spacing: 0;
            margin-bottom: $spacer-half;
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }
    }

    .msc-product__text {
        font-size: $msv-font-size-s;
        letter-spacing: $layout-gutter/10;
        margin-bottom: $spacer-half;
    }
    .msc-product__delivery-info {
        color: $msv-gray-500;
        font-size: $msv-font-size-xs;
        margin-top: $spacer-xl;
    }
    &__configure {
        .ms-buybox__dropdown {
            border-bottom: 1px solid $msv-gray-100;
            margin-top: 1.25rem;
            padding-bottom: $spacer-half;

            label {
                color: $msv-gray-500;
                font-size: $msv-font-size-xs;
                font-weight: $msv-font-weight-light;
                line-height: $spacer;
                letter-spacing: 2.5px;
            }
        }
    }

    &__media-gallery {
        display: block;
        flex-basis: 66%;
        min-width: 66%;
        flex-grow: 1;
        flex-wrap: nowrap;
        padding-right: 2.5rem;
    }

    .msc-dropdown__select {
        @include form-control();
    }

    .quantity {
        .quantity-input {
            @include form-control();
            width: 80px;
            display: inline-block;
            margin-left: 8px;
            margin-right: 8px;
            -moz-appearance: textfield;
            padding: $spacer/4;
            vertical-align: bottom;

            &::-webkit-inner-spin-button,
            &::-webkit-outer-spin-button {
                -webkit-appearance: none;
            }
        }

        .quantity__controls {
            padding: $spacer/4;
            padding-left: 0px;
            padding-right: 0px;
            background: var(--msv-buybox-btn-bg);
            background-position: center;
            background-repeat: no-repeat;
            border: 1px solid var(--msv-buybox-btn-border-color);
            min-width: auto;
            width: 48px;
            height: 48px;
            cursor: pointer;

            &:disabled {
                color: $msv-gray-300;
            }
        }

        .increment {
            @include add-icon($msv-Add, after);
            color: var(--msv-buybox-btn-font-color);

            &.disabled {
                color: $msv-gray-300;
            }

            &:not(:disabled):not(.disabled):active,
            &:not(:disabled):not(.disabled):active:focus {
                color: var(--msv-buybox-btn-font-color);
            }
        }

        .decrement {
            @include add-icon($msv-Remove, after);
            color: var(--msv-buybox-btn-font-color);

            &.disabled {
                color: $msv-gray-300;
            }

            &:not(:disabled):not(.disabled):active,
            &:not(:disabled):not(.disabled):active:focus {
                color: var(--msv-buybox-btn-font-color);
            }
        }
    }

    &__quantity {
        margin-top: 20px;
    }

    &__product-quantity-label {
        @include font-content-l(var(--msv-font-weight-light));
        color: var(--msv-buybox-title-font-color);
        display: block;
        margin-bottom: $spacer/4;
    }

    .ms-buybox__key_in_price {
        margin-top: 20px;

        .ms-buybox__key_in_price-label {
            @include font-content-l(var(--msv-font-weight-light));
            color: var(--msv-buybox-title-font-color);
            display: block;
            margin-bottom: $spacer/4;
        }

        .ms-buybox__key_in_price_custom-amount__input {
            @include form-control();
            -moz-appearance: textfield;
            padding: $spacer/4 $layout-gutter/4;
            text-align: center;
            &::-webkit-inner-spin-button,
            &::-webkit-outer-spin-button {
                -webkit-appearance: none;
            }
        }
    }

    .ms-text-block {
        margin-top: 0px;
        margin-bottom: 1rem;
        @include font-content(var(--msv-font-weight-normal), var(--msv-buybox-text-font-size), $msv-line-height-m);
    }

    .product-add-to-cart {
        margin-top: 2rem;

        .buttons {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
        }

        .msc-add-to-cart {
            @extend %primary-button;
            width: 100%;
            font-size: $msv-font-size-xs;
            font-weight: $msv-font-weight-light;
            &:before {
                content: 'Ordina ora';
                font-size: 1.125rem;
                font-weight: $msv-font-weight-medium;
                text-transform: uppercase;
                display: inline-block;
                width: 100%;
            }
        }

        .ms-wishlist-items__product-status {
            width: 100%;
            padding-top: $spacer;
        }

        .msc-add-to-cart-extra-actions {
            @include font-glyph-standard();
            @include secondary-button(
                var(--msv-buybox-secondary-btn-bg),
                var(--msv-buybox-secondary-btn-font-color),
                var(--msv-buybox-secondary-btn-border)
            );
            color: var(--msv-buybox-btn-extra-actions-color);
            padding-left: 0px;
            padding-right: 0px;
            position: relative;
            min-width: $layout-gutter * 3;
            width: $layout-gutter * 3;
            flex: 0 0 $layout-gutter * 3;
            margin-left: $layout-gutter/2;

            &:not(:disabled):hover,
            &:not(:disabled):not(.disabled):active:focus {
                color: var(--msv-buybox-btn-extra-actions-color);
                border-color: transparent;
            }
        }
    }

    &__find-in-store {
        margin-top: 20px;
        margin-bottom: 20px;
        width: auto;
    }

    &__find-in-store-heading {
        @include font-content-l(var(--msv-font-weight-light));
    }

    &__find-in-store-description {
        display: none;
    }

    &__find-in-store-pickup-option {
        margin-top: 8px;
        margin-bottom: $spacer-half;
        @include font-content-s();
        @include add-icon($msv-Checkbox-Circle-Checked, before);

        &:before {
            margin-right: 10px;
            color: --msv-buybox-primary-btn-font-color;
        }
    }

    &__minmax_label_text {
        margin-top: 12px;
        font-weight: var(--msv-font-weight-bold);
        &:before {
            @include msv-icon();
            content: $msv-InfoCircle;
            margin-right: $layout-gutter/2;
        }
    }

    &__find-in-store-button {
        width:100%;
        @extend %secondary-button;
    }

    &__shop-similar-looks-button {
        @include button-default();
        @include secondary-button(
            var(--msv-buybox-secondary-btn-bg),
            var(--msv-buybox-secondary-btn-font-color),
            var(--msv-buybox-secondary-btn-border)
        );
        font-size: var(--msv-buybox-btn-font-size);
        line-height: $msv-line-height-l;
    }

    &__dialog {
        max-width: 400px;

        .msc-modal__content {
            padding: 1.25rem;
        }

        &__header {
            .msc-modal__title {
                @include font-content-l();

                span {
                    &:nth-child(1) {
                        font-weight: var(--msv-font-weight-bold);
                    }
                }
            }
        }

        &__body {
            @include font-content-l();
            text-align: center;

            .msc-empty_image {
                @include image-placeholder(240px);
                padding-bottom: 10px;
            }
        }

        &__image {
            margin-right: auto;
            margin-left: auto;
        }

        &__product-title {
            padding: $spacer/4 0;
        }

        &__product-variants {
            @include font-content(var(--msv-font-weight-normal), var(--msv-buybox-text-font-size), $msv-line-height-m);
            padding: $spacer/4 0;
        }

        &__product-price {
            font-weight: var(--msv-font-weight-bold);
            padding: $spacer/4 0;
        }

        &__go-to-cart {
            @include primary-button(
                var(--msv-buybox-primary-btn-bg),
                var(--msv-buybox-primary-btn-font-color),
                var(--msv-buybox-primary-btn-border)
            );
            margin: $spacer-half 0;
            width: 100%;
        }

        &__back-to-shopping {
            @include secondary-button();
            margin: $spacer-half 0;
            width: 100%;
        }
    }
}
.external-link-buttons {
    display: flex;
    justify-content: space-between;
	margin: 4rem -15px 5rem -15px;

	.external-link-button {
        margin-top: 0;
    }

    a {
        border: 1px solid $msv-gray-100;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: $msv-font-size-l;
        font-weight: $msv-font-weight-heavy;
        letter-spacing: 0.01em;
        padding: $spacer-m;
        width: 100%;
        position: relative;
        padding-right: 4rem;
        &:hover,
        &:focus {
            color: $msv-primary--hover;
            text-decoration: none;
        }
        @include add-icon-icomoon($arrow, after);
        &:after {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: $spacer;
            border: 1px solid $msv-gray-100;
            border-radius: 100%;
            color: $msv-secondary;
            font-size: $msv-font-size-s;
            width: 2rem;
            height: 2rem;
            display: inline-block;
            text-align: center;
            line-height: 2rem;
        }
    }

    @media (max-width: $msv-breakpoint-m) {
        flex-flow: column;

        a {
            font-size: $msv-font-size-s;
            padding: 0.75rem 4rem 0.75rem 1rem;

            &::after {
				font-size: $msv-font-size-m;
                border: none;
				right: .25rem;
            }
        }
    }

    @media (max-width: $msv-breakpoint-l) {
		flex-flow: column;
		margin-top: 2.25rem;
        margin-bottom: .5rem;

        .external-link-button {
            margin-bottom: 1.25rem;
            width: 100%;
        }
    }
}
@media (max-width: $msv-breakpoint-m) {
    .ms-buybox {
        margin-bottom: 2.5rem;

        &__media-gallery {
            margin-top: -36px;
        }
        .ms-media-gallery .msc-carousel {
            &__control__prev {
                left: $layout-gutter;
            }
            &__control__next {
                right: $layout-gutter;
            }
        }
    }
}
@media (max-width: $msv-breakpoint-l) {
    .ms-buybox {
        flex-flow: column;
        margin-bottom: 2.5rem;

		.product-add-to-cart {
			margin-top: 1.25rem;
		}

        &__media-gallery {
            display: block;
            flex-grow: 1;
            flex-wrap: nowrap;
            padding-right: 0;
        }

        .ms-media-gallery {
            padding-top: 0;
            padding-bottom: 54px;
            margin-bottom: 36px;

            .msc-carousel {
                &__control__prev,
                &__control__next {
                    display: inline;
                    height: auto;
                    padding: $spacer;
                    top: auto;
                    bottom: -36px;
                    transform: translateY(50%);
                }
                &__control__next__icon {
                    margin-right: 0;
                }
                &__indicators {
                    bottom: -36px;
                    top: auto;
                    max-width: calc(100% - 11.25rem);
                    margin: auto;

                    li {
                        border: none;
                        &.active {
                            background: $msv-secondary;
                        }
                        &:not(.active) {
                            background: $msv-gray-100;
                        }
                    }
                }
            }
        }
    }
}

.disclaimer--images {
    &.ms-text-block {
        color: $msv-gray-500;
        margin-top: 0;
    }
    color: $msv-gray-500;
    p {
        font-size: 0.875rem;
    }
}