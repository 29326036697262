// Form elements

@mixin error-message() {
    @include font-content-m($font-weight-bold);
    display: block;
    color: var(--msv-error-color);
}

@mixin form-control() {
    display: block;
    width: 100%;
    color: #495057;
    background-color: #fff;
    border: 1px solid #6B727A;
    border-radius: 0;

    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,Arial,sans-serif;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    text-transform: none;
    height: 48px;
    padding: 4px 8px;
    background-clip: border-box;
    border-color: transparent;
    position: relative;
    outline-offset: 1px;
}

@mixin form-input-label() {
    font-size: 0.75rem;
    display: block;
    margin-bottom: 4px;
    color:$msv-gray-500;
    letter-spacing: 0.1em;
    text-transform: uppercase;
}

@mixin form-input-checkbox($border-color:$msv-gray-500,$color:$msv-secondary,$border-color--hover:$msv-primary,$border-color--checked:$msv-primary,$border-color--disabled:$msv-gray-300) {
    vertical-align: middle;
    padding: 0;
    margin: 0;
    opacity: 0;
    position: absolute;
    z-index: 10;
    cursor: pointer;
    width: 2rem;
    height: 2rem;
    text-align: center;
    ~ *:nth-child(2) {
        margin-left:0;
        display: flex;
        @include add-icon-icomoon($check);
        &:before {
            width: 2em;
            height: 2em;
            min-width: 2em;
            line-height: 2em;
            background: $white;
            color:transparent;
            border:1px solid $border-color;
            display: inline-block;
            margin-right:$layout-gutter;
            text-align: center;
        }
    }
    &:hover ~ *:nth-child(2) {
        &:before {
            border-color: $border-color--hover;
        }
    }
    &:checked ~ *:nth-child(2) {
        &:before {
            color: $color;
            border-color: $border-color--checked;
        }
    }
    &:disabled ~ *:nth-child(2) ,
    &[disabled] ~ *:nth-child(2)  {
        color:$msv-gray-500;
        &:before {
            border-color: $border-color--disabled;
        }
    }
}

@mixin form-input-radio {
    @include vfi();
    vertical-align: middle;
    padding: 0;
    margin: 0;
    opacity: 0;
    position: absolute;
    z-index: 10;
    cursor: pointer;
    width: 2em;
    height: 2em;
    ~ *:nth-child(2) {
        margin-left:0;
        &:before {
            content: "";
            min-width: 1.4em;
            max-width: 1.6em;
            height: 1.4em;
            background: $white;
            color:$msv-gray-500;
            border:3px solid $white;
            border-radius: 100%;
            display: inline-block;
            box-shadow: 0 0 0 1px $msv-gray-500;
            margin-right:$layout-gutter/2;
            transform: translateY(0.25em);
        }
    }
    &:checked ~ *:nth-child(2),
    &[aria-checked="true"] ~ *:nth-child(2) {
        &:before {
            background: $msv-secondary;
            box-shadow: 0 0 0 1px $msv-secondary;
        }
    }
}


@mixin form-input-radio-label {
    @include font-content-m(var(--msv-font-weight-light));
    vertical-align: middle;
    margin-left: 20px;
}

@mixin form-input-el($bg-color: transparent, $color: var(--msv-font-primary-color), $border: $msv-gray-300) {
    background: $bg-color;
    font-family: $msv-primary-font-family;
    border:none;
    border-bottom:1px solid $msv-gray-300;
    line-height:1em;
    padding:0.5rem 0;
    outline: none;
    font-size:1rem;
    color:$color;
    display: block;
    width: 100%;
    font-weight: $msv-font-weight-medium;
    &:focus {
        border-bottom: 1px solid $color;
        outline: none;
    }
}

@mixin form-input-el-error() {
    border: 1px dashed var(--msv-error-color);
    border-left: 3px solid var(--msv-error-color);
}

@mixin form-input-error-message() {
    @include error-message();
    @include add-icon($msv-IncidentTriangle);
    margin-bottom: 4px;
    &:before {
        margin-right: 8px;
    }
}

@mixin form-input-alert-message() {
    @include font-content-s-height-l();
    color: $msv-text-color;
    margin-bottom: 4px;
}

@mixin alert {
    background-color: $pink;
    border: 1px solid $red;
    max-width: 512px;
    padding: 8px;
}
  
@mixin alert-title {
    @include error-message();
    margin-bottom: 10px;
    margin-top: 2px;
    &:before {
        @include font-awesome-icon($alert-icon);
        margin-right: 8px;
    }
}
label, %label {
    @include form-input-label;
}
input, textarea,
%input-text {
    @include form-input-el;
}

[aria-invalid='true']{
    input, textarea {
        border-color:$msv-red;
    }
}

select {
    outline:none;
}

@mixin text-alert($color:$msv-secondary, $icon:true) {
    color:$color;
    @if $icon {

    }
}

%text-alert {
    &--error {
        @include text-alert;
        @include add-icon-icomoon($error,before,true, 0.5rem);
        > p {
            display:inline;
        }
    }
}
input[type="checkbox"]:not(#toggle),
%input-checkbox:not(#toggle) {
    @include form-input-checkbox();
    + span {
        text-transform:none;
        font-size:1rem;
        color:$msv-primary;
        line-height: 2rem;
        letter-spacing: 0;
    }
}
input[type="radio"], %input-radio {
    @include form-input-radio();
}
label {
    &.type {
        &__radio, &__checkbox {
            line-height: 2em;
            font-size: 1rem;
            color:$msv-primary;
            text-transform: none;
            letter-spacing: 0;
        }
    }
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $msv-gray-500;
}
::-moz-placeholder { /* Firefox 19+ */
    color: $msv-gray-500;
}
:-ms-input-placeholder { /* IE 10+ */
    color: $msv-gray-500;
}
:-moz-placeholder { /* Firefox 18- */
    color: $msv-gray-500;
}