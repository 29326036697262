.msc-cart__btn-checkout,
.msc-cart__btn-guestcheckout,
.msc-promo-code__apply-btn {
	@extend %primary-button--red;
	white-space: nowrap;

	&:focus {
		background-position: 100% 50%;
	}
}

.ms-cart {
	display: flex;
	flex-wrap: wrap;
	padding-top: $spacer-l;
	padding-bottom: $spacer-l;
	.complete-order__button {
		margin-left: $spacer-m;
		align-self: flex-end;
		margin-bottom: $spacer;
		.msc-cart-line__bopis__instructions {
			margin-top: $spacer;
			max-width: 17rem;
		}
	}
	.msc-cart-line__financing {
		display: none;
	}
	.msc-cart__heading {
		@extend %h2;
		flex-basis: 100%;
		padding-bottom: $spacer;
		text-align: center;
	}
	.msc-order-summary-wrapper {
		background:#F5F5F5;
		padding: $spacer-m;
	}
	.msc-cartline-wraper {
		background: #F5F5F5;
		flex: 1;
	}
	.msc-cart-line {
		display: flex;
		margin-bottom: $spacer;
		flex-grow:1;
		width: 100%;
		.msc-order-summary-totalPrice-label {
			display:none;
		}
		&__financing {
			display: none;
		}
		&__product-image {
			display: flex;
			align-items: center;
			background: $msv-gray-100;
			flex: 0 0 41.666667%;
			max-width: 41.666667%;
			padding:$spacer-m;
		}
		&__content {
			display: block;
			padding: 0 $spacer-m;
		}
		// &__product-variants__title {
		// 	margin-bottom: $spacer;
		// }
		&-group__extra-actions {
			padding: $spacer-m;
			position: absolute;
			left: 41.666667%;
			bottom: 0;
		}
		&s-item {
			position: relative;
			display: flex;
			flex-wrap: wrap;
			> .complete-order__button {
				@include media-breakpoint-up(md) {
					display: none;
				}
			}
			&__row {
				flex-basis: 100%;
				flex-wrap: nowrap;
				display:flex;
				@include media-breakpoint-up(md) {
					align-items: flex-end;
				}
				&:first-child {
					border-bottom: $border-separator;
				}
				&:last-child {
					align-items: flex-end;
				}
			}
			> div {
				margin: $spacer $spacer-m $spacer 1.25rem;
			}
		}
		&__bopis {
			&__instructions {
				flex-grow: 1;
			}
			&-container {
				display: grid;
				grid-template-columns: 1fr 1fr;
				column-gap: $spacer-xl;
				max-width: 800px;
				align-items: start;
				h5 {
					text-transform: uppercase;
					margin-bottom:4px;
				}
				> div {
					p {
						font-size: 12px;
						margin-bottom: 0;
					}
					flex-basis: 50%;
					font-size: 11px;
					display: flex;
					align-items:start;
					button.radio {
						border: none;
						flex-basis: 3rem;
						min-width: 3rem;
						padding:0;
						
						&[disabled] {
							&:before {
								background: $msv-secondary;
								box-shadow: 0 0 0 1px $msv-secondary;
							}
						}
						&:before {
							content: "";
							min-width: 1.4em;
							max-width: 1.6em;
							height: 1.4em;
							background: #fff;
							color: $msv-gray-500;
							border: 3px solid #fff;
							border-radius: 100%;
							display: inline-block;
							box-shadow: 0 0 0 1px $msv-gray-500;
							margin-right: .5rem;
							transform: translateY(0.25em);
						}
					}

					button {
						@extend %underline-button;
						margin-top: $spacer-half;


					}
					&:first-child {
						font-size:0.875rem;
					}
				}
			}
			&-name {
				font-weight: $msv-font-weight-heavy;
			}
		}
		&__extra-actions {
			flex-grow:1;
			display: flex;
			justify-content: flex-end;
		}
	}
	.msc-cart-lines-group,
	.msc-cart-lines-group-wraper {
		flex: 1;
	}
	@media (max-width: $msv-breakpoint-l) {
		flex-direction: column;

		.complete-order__button {
			display: block;
			width: 100%;
			a {
				width: 100%;
			}
		}
		.msc-cart-lines-item > div {
			margin: $spacer $spacer-m;
		}
		.msc-cart-lines-item__row {
			flex-direction: column;

			&:last-child {
				align-items: flex-start;
			}
			.msc-cart-line {
				flex-direction: column;

				&__content {
					padding: $spacer 0;
				}
				&__product-image {
					justify-content: center;
					max-width: 100%;
				}
				&-group__extra-actions {
					left: 0;
				}
			}
			.complete-order__button {
				display: none;
			}
			.msc-cart-line__bopis-container {
				display: flex;
				max-width: none;
				flex-direction: column;
				width: 100%;

				> div {
					margin-bottom: $spacer;
					width: 100%;
				}
			}
			.msc-cart-line__extra-actions {
				margin: 2rem 0 0 auto;
			}
		}
		.msc-order-summary-wrapper {
			display: flex;
			justify-content: center;
		}
	}
}
