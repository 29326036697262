.ms-content-block[data-m-layout='left-right'] {
    &.textplacement {
        &__left,
        &__right {
            display: flex;
			flex-direction: column;
        }
        &__left {
			@media screen and (min-width: $msv-breakpoint-m + 1) {
				flex-direction: row-reverse;

				.ms-content-block__details .card-cta {
					justify-content: flex-start;
				}
            }
        }
		&__right {
			@media screen and (min-width: $msv-breakpoint-m + 1) {
            	flex-direction: row;

				.ms-content-block__details .card-cta {
					justify-content: flex-end;
				}
			}
        }
        &__center .ms-content-block__details {
            align-items: center;
            text-align: center;

            .card-cta {
                justify-content: center;
            }
        }
    }
    h3 {
        @extend %h1;
    }
    h5 {
        font-weight: $msv-font-weight-normal;
    }
    .msc-cta {
        &__primary {
            @extend %secondary-button;
            text-align: center;

			@media screen and (max-width: $msv-breakpoint-s) {
				padding-left: $spacer;
				padding-right: $spacer;
            }
        }
        &__secondary {
            padding: .5rem $spacer;
        }
    }
    .ms-content-block {
        &__details,
        &__image {
            flex-basis: 50%;
        }
        &__details {
			margin-top: 21px;
            margin-bottom: auto;
			padding: 0 .5rem;

			@media screen and (min-width: $msv-breakpoint-m + 1) {
            	margin-top: auto;
				padding: $spacer-m;
			}
			@media screen and (min-width: $msv-breakpoint-m + 1) and (max-width: $msv-breakpoint-l) {
				padding-top: 0;
			}
            @media screen and (min-width: $msv-breakpoint-l) {
                padding: $spacer-m $spacer-xxl;
            }
        }
        &__image {
          overflow: hidden;
			img {
				width: 100%;
			}
		}
        &__title {
            color: $black;
            @include font-content($msv-font-weight-normal, $msv-font-size-xxl, $msv-line-height-xxl);
        }
        &__cta {
            display: flex;
            align-items: flex-end;
        }
    }
    * + .ms-content-block__text {
        margin-top: 21px;
    }
    * + .ms-content-block__cta {
        margin-top: 2rem;
    }
}
