html {
  font-size: 16px;
  font-family:$msv-primary-font-family;
  font-weight: $msv-font-weight-light;
  @media (min-width: 992px) and (max-width: 1500px) {
    font-size: 15px;
  }
  p,
  ul,
  div,
  span,
  button,
  a {
    line-height: 1.3em;
  }
}
html,
body {
  background: $white;
  color: $black;
  max-width: 100%;
  overflow-x: hidden;
}
.default-container {
  &.fluid {
    > .row {
      margin-left: 0;
      margin-right: 0;
      > .col-12 {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
  &-2-slots {
    [class^=col-] {
      display:flex;
      flex-direction:column;
    }
  }
  .heading {
    &.container-heading {
      @extend %col-12;
    }
  }
}
@media screen and (max-width: $msv-breakpoint-m) {
  .hideOnMobile {
    display: none;
  }
}
$border-separator:1px solid $msv-gray-100;