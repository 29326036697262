.select-accordion__container {
    border-bottom: 1px solid $msv-gray-500;
    position: relative;
    @include add-icon($msv-ChevronDown, after);

    .select-accordion {
        background-color: transparent;
        border: 0;
        cursor: pointer;
        padding: $spacer $layout-gutter $spacer $layout-gutter;
        position: relative;
        width: 100%;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        z-index: 1;
    }

    &::after {
        position: absolute;
        top: $spacer*1.25;
        right: $layout-gutter/4;
        z-index: 0;
    }
}