.variant-select  {
    border-bottom: 1px solid $msv-gray-100;
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 4px;
    padding-top: $spacer*0.75;
    padding-bottom: $spacer;

    &:last-of-type {
        border-bottom: 0;
    }
    > div,
    > ul {
        padding-left: $layout-gutter;
        padding-right: $layout-gutter;
    }
    .select-accordion__container {
        padding:0;

        .select-accordion {
            color:$msv-gray-500;
            font-size:$msv-font-size-xs;
        }
    }
    .variant-selected__text,
    .variant-selected__text-mobile {
        font-size: $spacer*0.75;
        font-weight: $msv-font-weight-light;
        color: $msv-gray-500;
        margin-top: $spacer-half;
        letter-spacing: 0.01rem;

        span {
            text-transform: Capitalize;
        }
    }
    .variant-selected__text-mobile {
        color: $msv-black;
        display: none;
        text-transform: capitalize;
    }
    &__heading {
        color: $msv-gray-500;
        font-size: $spacer*0.75;
        text-transform: uppercase;
        letter-spacing: 2.5px;
        margin-bottom: $spacer*0.75;
    }
    &__colors {
        display: flex;
        margin-bottom: 4px;
        flex-wrap:wrap;
    }
    &__colorbox {
        cursor: pointer;
        margin-right: 10px;
        padding: 2px;
        width: auto;
        height: auto;
    
        button {
            background-color: transparent;
            border: 1px solid #E3E5E6;
            color:transparent;
            cursor: pointer;
            display: flex;
            width: 1.625rem;
            height: 1.625rem;
            overflow: hidden;
            outline: none;
            padding:0;
    
            span {
                display: block;
                width: 1.625rem;
                height: 1.625rem;
            }
        }
        &.selected {
            outline: 1px solid $msv-primary;
        }
    }
}
@media (max-width: $msv-breakpoint-m) {
    .variant-select  {
        margin-bottom: 0;
        padding-top: .5rem;
        
        &:first-of-type {
            border-top: 1px solid $msv-gray-100;
        }
        &__heading {
            font-weight: $msv-font-weight-heavy;
            color: $msv-black;
            letter-spacing: normal;
            margin-top: 0;
            margin-bottom: 0;
            order: -1;
            text-transform: unset;
        }
        .variant-selected__text {
            display: none;
        }
        .variant-selected__text-mobile {
            display: inline;
        }
        > div,
        > ul {
            padding: 0;
        }
        > ul {
            margin-top: $spacer;
        }
        .select-accordion__container {
            border-bottom-color: $msv-gray-100;

            &::after {
                top: 0;
            }
            .select-accordion {
                color: $msv-black;
                // font-size of 16px prevent iPhones to zoom in when opening the select, which did not zoom back to normal once option selected
                font-size: 1rem;
                margin-top: .25rem;
                padding: 0 $layout-gutter .5rem 0;
            }
        }
        &__colorbox {
            button,
            span {
                height: 2.75rem;
                width: 2.75rem;
            }
        }
    }
}