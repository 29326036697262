$content-block-min-height: 150px;
$hero-body-max-length: 50vw;
$hero-body-top-margin: 8px;
$hero-content-text-margin-top: 16px;
$hero-content-button-margin-top: 32px;
$hero-content-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.108), 0px 1.6px 3.6px rgba(0, 0, 0, 0.132);
$hero-heading-color: $black;
$hero-heading-font-size: 32px;
$hero-heading-line-height: 43px;
$hero-heading-max-length: 30em;
$hero-link-to-link-spacing: 16px;
$hero-links-top-margin: 32px;
$hero-links-secondary-padding: 12px 20px;

:root {
  // heading
  --msv-content-block-heading-font-size: #{$hero-heading-font-size};
  --msv-content-block-heading-font-color: var(--msv-font-primary-color);

  // Text theme  
  --msv-content-block-textheme-dark: #{$msv-white};
  --msv-content-block-textheme-light: var(--msv-font-primary-color);

  --msv-content-block-text-font-size: var(--msv-body-font-size-m);
  
  // primary button
  --msv-content-block-primary-btn-bg: var(--msv-accent-brand-color);
  --msv-content-block-primary-btn-font-color: var(--msv-font-secondary-color);
  --msv-content-block-primary-btn-border: var(--msv-accent-brand-color);
 }

@mixin overlay-text-on-image {
  .ms-content-block__details {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: $spacer-xl;
    height:100%;
    position:relative;
    z-index:1;
  }
}
.ms-content-block {
  min-height: $content-block-min-height;
  &.textplacement__center {
    text-align: center;
  }
  .ms-content-block__image {
  }
  &[data-m-layout='full-width'] {
    position: relative;
    min-height:30rem;
    display: flex;
    align-items: center;
    .ms-content-block__image {
      position:absolute;
      z-index: 0;
      img {
        object-fit: cover;
        object-position: bottom;
      }
    }

    .ms-content-block__image,    
    .ms-content-block__image img {
      width: 100%;
      height:100%;
    }

    &.texttheme__dark {
      .ms-content-block__image {
        &:after {
          position:absolute;
          top:0;
          left:0;
          background:rgba(0,0,0,0.5);
          width: 100%;
          height: 100%;
          content:"";
        }
      }
      .ms-content-block__details .msc-cta__primary {
        @extend %secondary-button--light;
      }
    }

    .ms-content-block__link {
      width: 100%;
    }

    .ms-content-block__details {

    }

    .ms-content-block__details,
    &.textplacement__left .ms-content-block__details {
      text-align: left;
      align-items: flex-start;    
    }

    &.textplacement__center .ms-content-block__details {
      align-items: center;
      text-align: center;      
    }

    &.textplacement__right .ms-content-block__details {
      text-align: right;
      align-items: flex-end;     
    }
    
    .ms-content-block__title {
      @extend %h1;
      max-width: $hero-heading-max-length;
      line-height: $hero-heading-line-height;
    }

    .ms-content-block__text {
      font-size:0.875rem;
      text-transform: uppercase;
      max-width: $hero-body-max-length;
      letter-spacing: 0.05em;
    }

    &.texttheme__light .ms-content-block__title, &.texttheme__light .ms-content-block__text {
      color: var(--msv-content-block-textheme-light);
      text-shadow: $hero-content-shadow;
    }

    &.texttheme__dark .ms-content-block__title, &.texttheme__dark .ms-content-block__text  {
      color: var(--msv-content-block-textheme-dark);
      p {
        color:$white;
      }
    }

    .ms-content-block__cta {
      align-items: flex-end;
      justify-content: center;
    }

    /* links should have top margin only when there's something above it */
    * + .ms-content-block__cta {
      margin-top: $hero-content-button-margin-top;
    }

    .ms-content-block__details {
      @include msv-layout-page-side-padding;
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;

      .msc-cta__primary {
        @extend %secondary-button;
        display: inline-block;
      }

      .msc-cta__secondary {
        margin: $hero-link-to-link-spacing / 2;
        padding: $hero-links-secondary-padding;
      }
    }

    /**
    * When the image is not added in content block. 
    */
    div.ms-content-block__image:empty {
      + div.ms-content-block__details {
        position: relative;
        height: unset;
      }
    }

    /**
    * Text over image by default only in modern browsers.
    * We must reflow the text to bottom in IE 11 because it doesn't support srcset and the image will be too short to put text over it.
    */
    @supports (object-fit: cover) {
      .ms-content-block__image {
        object-fit: cover;
      }

      @include overlay-text-on-image;
    }

    /**
    * When the image is tall enough for IE 11 to display text on top of it. 
    */
    @media screen and (min-width: $msv-breakpoint-l) {
      @include overlay-text-on-image;
    }

    @media screen and (min-width: $msv-breakpoint-m) {      
      .ms-content-block__details,
      &.textplacement__left .ms-content-block__details,
      &.textplacement__center .ms-content-block__details,
      &.textplacement__right .ms-content-block__details  {          
        width: max-content;
        overflow: inherit;
        justify-content: center;
      }
      &.textplacement__center .ms-content-block__details{
        margin: 0 auto;
      }
      &.textplacement__right .ms-content-block__details{
        margin-left: auto;
      }
    }

    @media screen and (max-width: $msv-breakpoint-m) {
      .ms-content-block__text {
        max-width: 100%;
      }
      .ms-content-block__cta {
        display: flex;
      }
    }
  }
}