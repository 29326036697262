.CheckoutPage {

	h1 {
		text-align: center;
		@extend %h2;
		padding: $spacer-l $layout-gutter $spacer $layout-gutter;
		width: 100%;
		
		@media screen and (max-width: $msv-breakpoint-m) {
			font-size: $msv-font-size-l;
		}
	}
	@media screen and (max-width: $msv-breakpoint-m) {
		.col-12 {
			position: static;
		}
	}
}
.ms-checkout {
	.msc-cart-line__product-price {
		display: none;
	}
	&__body {
		width: 100%;
		padding-right: $layout-gutter;
		padding-left: $layout-gutter;
		margin-right: auto;
		margin-left: auto;
	}
	&__title {
		padding-top: $spacer-l;
		padding-bottom: $spacer;
	}
	&__guided-card-title {
		@extend %h3;
		text-transform: uppercase;
	}
	&__error-message {
		width: 100%;
		background-color: var(--msv-error-color);
		border: 1px solid var(--msv-error-color);
		color: var(var(--msv-font-secondary-color));
		padding: 1.125rem;
		margin-bottom: 1.25rem;

		&:before {
			@include msv-icon();
			content: $msv-IncidentTriangle;
			margin-right: 0.5rem;
		}
	}
	&-section-container {
		padding-left: 0;

		&__item.hidden {
			display: none;
		}
	}
	&__title {
		text-align: center;
		@extend %h2;
	}
	&__main {
		flex: auto;
		border: 1px solid $msv-gray-100;
		padding: $spacer-m;

		.ms-checkout__terms-and-conditions {
			margin-bottom: 0.625rem;
			padding-right: 2rem;
			padding-left: 2rem;
		}
	}
	&__main-control .ms-checkout__btn-place-order {
		margin-right: 0;
	}
	&__side-control-first {
		display: block;
		justify-content: center;
		margin-top: $spacer-xl;
	}
	&__side-control-second {
		display: none;
	}
	&__main-control {
		display: flex;
		flex-direction: row-reverse;
	}
	&__btn-place-order {
		@extend %primary-button;
		width: 100%;

		&.is-busy {
		cursor: progress;

			&:before {
				@include msv-icon();
				content: $msv-Spinner;
				margin-right: 0.5rem;
				-webkit-animation: spin 1s steps(8) infinite;
				animation: spin 1s steps(8) infinite;
			}
		}
	}
	&__btn-keep-shopping {
		@include button-link(var(--msv-font-primary-color));
	}
	&__side-container_mobile {
		display: none;
	}
	&__side {
		border: 1px solid $msv-gray-100;
		padding: $spacer-m;

		.ms-checkout__btn-place-order {
			margin-top: 2rem;
			display: none;
		}
	}
	&__guided-form {
		margin-bottom: 1.25rem;
	}
	&-payment-instrument__error {
		background-color: var(--msv-error-color);
		border: 1px solid var(--msv-error-color);
		color: var(var(--msv-font-secondary-color));
		max-width: 32rem;
		padding: 0.5rem;

		&-title {
			display: block;
			margin-bottom: 0.625rem;
			margin-top: 0.125rem;

		&:before {
			@include msv-icon();
			content: $msv-IncidentTriangle;
			margin-right: 0.5rem;
		}
		}
		&-message {
			display: block;
			margin-bottom: 0;
			color: var(--msv-font-secondary-color);
		}
	}
  	&__guided-card {
		&:first-child {
			.ms-checkout__guided-card-footer {
				display: none;
			}
		}
		&-header {
			display: flex;
			border-bottom: 1px solid $msv-primary;
			padding-top: $spacer;
			align-items: center;
			justify-content: space-between;
		}
		&.hidden {
			display: none;
		}
		&-title {
			display: flex;
		}
		&-title-step {
			width: 2rem;
		}
		&-body {
			padding-top: $spacer-m;
			padding-bottom: $spacer-m;

			&.hidden {
				display: none;
			}
		}
		&-footer {
			margin-top: 1.25rem;
			margin-bottom: 1.25rem;
		}
		&-custom-footer {
			margin-bottom: $spacer-l;

			.ms-checkout__guided-card-btn-cancel {
				display: none;
			}
		}
		&.visted {
			.ms-checkout__guided-card-btn-cancel {
				display: inline-block;
			}
		}
		&-btn-cancel,
		&-btn-save {
			width: 100%;

			&.is-submitting {
				cursor: progress;

				&:before {
					@include msv-icon();
					content: $msv-Spinner;
					margin-right: 0.5rem;
					-webkit-animation: spin 1s steps(8) infinite;
					animation: spin 1s steps(8) infinite;
				}
			}
		}
		&-btn-save {
			@extend %primary-button;
		}
		&-btn-edit {
			@extend %underline-button;
		}
  	}
  	&__line-items-delivery-group {
		.ms-checkout__line-items-group-title {
		display: none;
		}

		.msc-cart-line {
			&__financing {
				display: none;
			}
			&__quantity,
			&__product-variants {
				font-weight: $msv-font-weight-normal;
				color: var(var(--msv-font-primary-color));
				font-size: var(var(--msv-body-font-size-m));
				line-height: $msv-line-height-m;
			}
			&__content {
				display: block;

				.msc-cart-line-item-product-discount {
				color: var(--msv-success-color);
				}
			}
    	}
  	}
	.msc-empty_image {
		@include image-placeholder(6.75rem);
	}
	.ms-checkout__side-control-first {
		.financing-simulation__table-delivery {
			.financing-table__heading-title {
				border-bottom: 0;
				margin-bottom: 0;
			}
			li {
				margin-top: 0;
				margin-bottom: $spacer;
			}
		}
		.financing-simulation__table-simulation {
			display: none;
		}
		.financing-simulation__table-deposit {
			border-top: 1px solid $msv-gray-100;

			li {
				margin-top: $spacer;
			}
		}
	}
	@media screen and (max-width: $msv-breakpoint-l - 1) {
		margin-right: 0;
		margin-left: 0;

		label {
			color: $msv-black;
			line-height: $msv-line-height-xs;
			margin-bottom: 2px;
			text-transform: unset;
		}
		input {
			border: 1px solid $msv-gray-500;
			font-size: $msv-font-size-s;
			font-weight: $msv-font-weight-light;
			padding: .75rem .5rem;
		}
		select {
			color: $msv-black;
			font-weight: $msv-font-weight-light;
			padding: 0 .5rem;

			&:disabled {
				background-color: $msv-gray-100;
	
			}
		}
		&__body {
			margin-left: -$layout-gutter;
			margin-right: -$layout-gutter;
			min-width: calc(100% + 2rem);
			padding: 0;
		}
		&__main {
			border: none;
			padding-right: .5rem;
			padding-left: .5rem;

			.ms-checkout__terms-and-conditions {
				display: none;
			}
		}
		&__side-container_mobile {
			background: $msv-white;
			display: block;
			position: sticky;
			top: 0;
			margin-left: -1.5rem;
			padding: 0 $layout-gutter;
    		width: 100vw;
			z-index: 1;

			@media screen and (max-width: $msv-breakpoint-s) {
				width: calc(100vw + 1rem);
			}
			.side__mobile-head {
				display: flex;

				> div:first-of-type {
					display: flex;
					flex-direction: column-reverse;
					padding-right: 0;
					margin-top: -5px;
					max-width: calc(100% - 120px);
				}
				> div:last-of-type {
					margin-left: auto;

					.btn-primary {
						background-color: $msv-black;
						color: $msv-white;
						padding: .875rem .5rem;
					}
				}
			}
			.msc-cart-line__product,
			.msc-cart-line__product-image,
			.msc-cart-line__product-price,
			.msc-order-summary-totalPrice-label,
			.ms-checkout__side-control-first,
			.ms-checkout__side-control-second,
			.icon-close-modal {
				display: none;
			}
			.msc-order-summary {

				&-totalPrice {
					margin-bottom: .5rem;
				}
				&-totalPrice-value {
					font-size: 1rem;
					font-weight: $msv-font-weight-light;
				}
				&-totalPrice-info {
					color: $msv-black;
					font-size: $msv-font-size-xs;
					text-transform: unset;
					margin-top: 10px;

					@media screen and (max-width: $msv-breakpoint-l) {
						width: calc(100vw - 2rem);
					}
				}
			}
			.msc-cart-line__product-image {
				background-color: rgba(191, 194, 196, 0.1);
				margin-left: -1rem;
				width: calc(100vw - 2rem);
			}
			.msc-cart-line__product {
				margin-top: 1.25rem;

				&-image {
					margin-bottom:  1.25rem;
				}
				&-title {
					font-size: $msv-font-size-s;
					margin-bottom: 0;
					margin-top: -2px;
				}
				&-variants__title {
					margin-bottom: 1.5rem;
				}
				&-variant-item {
					margin-bottom: 1.75rem;
	
					.label {
						font-weight: $msv-font-weight-medium;
						letter-spacing: normal;
					}
					.name {
						font-weight: $msv-font-weight-light;
					}
				}
			}
			.ms-checkout__side-control-first {
				margin-top: 0;
			}
			&.opened {
				box-shadow: 0 0 0 100vh $msv-white;
				height: 100vh;
				overflow-x: hidden;
				overflow-y: scroll;
				position: fixed;

				.ms-checkout__side {
					padding-top: 0;
					padding-bottom: 1.125rem;
					position: relative;

					.side__mobile-head > div:first-of-type {
						display: flex;
    					flex-direction: column;
						margin-top: 0;
						padding-right: 0;
						width: 100%;

						.msc-order-summary {
							order: -1;

							&-totalPrice {
								margin-top: 3.5rem;
							}
						}
					}
				}
				.msc-cart-line__product-title {
					position: absolute;
					top: 1.125rem;
					width: 75%;
				}
				.icon-close-modal {
					font-size: 1.5rem;
					position: fixed;
					top: 1rem;
					right: 1.5rem;
				}		
				.financing-simulation__table {

					.financing-table__heading-title {
						border-bottom: none;
						font-weight: $msv-font-weight-heavy;
						margin-top: 1rem;
					}
					li {
						border-bottom: none;
						margin-top: 1.125rem;
						padding-bottom: 0;

						.simulation-label {
							color: $msv-black;
							font-size: $msv-font-size-s;
							text-transform: none;
						}
					}
				}
				.msc-cart-line__product-image,
				.ms-checkout__side-control-first,
				.icon-close-modal {
					display: block;
				}
				.msc-cart-line__product {
					display: flex;
					flex-direction: column;
				}
				.msc-btn.btn-primary {
					display: none;
				}
			}
		}
		&__side-container_desktop {
			display: none;
		}
		&__side {
			background: $msv-white;
			border-color: $msv-gray-500;
			display: flex;
			flex-direction: column;
			margin: 0;
			padding: 1.25rem 1rem .0 1rem;
    		width: 100%;
		}
		&__guided-card {
			&.closed .ms-checkout__guided-card-title {
				color: $msv-gray-500;
			}
			&:first-of-type .ms-checkout__guided-card-header {
				padding-top: 0;
			}
			&-body {
				padding: 0.75rem 0;
			}
			&-header {
				border-bottom-color: $msv-gray-500;
				align-items: flex-start;
			}
			&-title {
				display: block;
				font-size: $msv-font-size-l;
				font-weight: $msv-font-weight-light;
				margin-bottom: .25rem;

				&-step {
					width: auto;
				}
			}
			&-btn-edit {
				border-bottom-color: $msv-secondary;
				border-bottom-width: 1px;
				color: $msv-secondary;
				font-size: $msv-font-size-s;
				font-weight: $msv-font-weight-light;
				line-height: normal;
				margin: auto 0 auto auto;
			}
			&-btn-save,
			&-btn-cancel {
				display: block;
				margin: auto;
				width: auto;
			}
		}
		&-custom-footer {
			margin-top: 2rem;
			margin-bottom: 0;
		}
		&__side-control-second {
			background: var(--msv-bg-color);
			display: block;

			.ms-checkout__btn-keep-shopping {
				margin-top: 1.25rem;
			}
		}
	}
}
