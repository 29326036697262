// bootstrap color variables
$msv-white: #fff;
$msv-black: #000;
$msv-blue:    #0062af;
$msv-green: #107C10;
$msv-red: #ED0000;
$msv-pink: #FCDFDF;
$msv-cyan: #0776d1;
$msv-yellow:#ffff00;

$msv-gray-100: #E3E5E6;
$msv-gray-200: $msv-gray-100;
$msv-gray-300: #bfc2c4;
$msv-gray-400: $msv-gray-300;
$msv-gray-500: #686d71;
$msv-gray-800: $msv-gray-500;
$msv-gray-900: $msv-gray-500;
$msv-gray-1000: #000000;

$black: #000000;
$white: #FFFFFF;

$msv-primary: #000000;
$msv-primary--hover: #ed0000;
$msv-primary--focus: #be0000;
$msv-primary-hover: #ed0000;
$msv-secondary: #ed0000;
$msv-secondary-hover: #ffffff;
$msv-secondary--focus: #e3e5e6;

$msv-text-color: $msv-gray-1000;
$msv-link-color: $msv-gray-1000;
$msv-divider-color: $msv-gray-300;
$msv-error-color: $msv-red;
$msv-success-color: $msv-green;
$modal-input-background: $msv-gray-100;
$msv-modal-overlay: rgba(0, 0, 0, 0.7);
$msv-box-shadow-color: rgba(0, 0, 0, 0.132);
$msv-selected-user-background-color: rgba(0, 0, 0, 0.04);

$msv-flyout-empty-img-border: #F3F2F1;

// Social button color variables
$facebook-bg-color: #3C3C41;
$facebook-hover-color: #1D1D1D;
$microsoft-bg-color: #3C3C41;
$microsoft-hover-color: #1D1D1D;
$social-bg-color: #3C3C41;
$social-hover-color: #1D1D1D;

$msv-unfilled-rating-color: $msv-gray-800;



// koelliker
$msv-primary-text-color:$msv-black;
$msv-primary-text-color:$msv-black;


// Style presets
:root {
    // Background
    --msv-bg-color: #{$msv-white};
    
    // Font
    --msv-font-primary-color: #{$msv-black};
    --msv-font-secondary-color: #{$msv-white};
 
    // Borders
    --msv-border-color: #{$msv-gray-100};
   
    // Brand accents
    --msv-accent-brand-color: #{$msv-primary};
    --msv-accent-brand-alt-color: #{$msv-primary-hover};
    --msv-accent-secondary-btn:#{$msv-secondary-hover};

    --msv-primary: #{$msv-primary};
    --msv-primary--hover:#{$msv-primary--hover};

    // Status
    --msv-error-color: #{$msv-red};
    --msv-success-color: #{$msv-green};

    // Modal
    --msv-modal-bg-color: #{$msv-white};
    --msv-modal-font-color: var(--msv-font-primary-color);
    --msv-flyout-bg-color: #{$msv-white};
}