.ms-account {
  &-welcome-tile {
  }

  &-generic-tile {
    &.order-tile {
    }

    &.profile-tile {
    }
  }

  &-wishlist-tile__heading {
  }

  &-order-templates-tile__heading {
  }

  &-address-tile__heading {
  }

  &-loyalty-tile__heading {
  }

  &-organization-users-tile__heading {
  }

  &-customer-balance-tile__heading {
  }

  &-invoice-tile__heading {
  }
  

  &-generic-tile,
  &-wishlist-tile,
  &-order-templates-tile,
  &-address-tile,
  &-loyalty-tile,
  &-organization-users-tile,
  &-customer-balance-tile,
  &-invoice-tile {

    &__heading {
    }

    &__links {
    }

    &__link {
    }

    &__link-button {
    }

    &__description {
    }

    &__value {
      &:before, &:after {
        content: ' ';
      }
    }
  }
}
