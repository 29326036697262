.ms-checkout-contract {
  position: relative;
  .loader {
    &__ctn {
      &.is--loading {
        top:0;
        left:0;
        background: rgba(255,255,255,0.5);
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
      }

    }
  }
  &__introtext {
    margin-bottom:$spacer-m;
  }
  &__link {
    @extend %underline-button;
    display: inline-block;
    margin-bottom: 4px;
  }
  &__alert {
    color:$msv-red;
    font-size:11px;
    margin-bottom: $spacer-m;
  }
  .ready & {
    padding-bottom: $spacer;
  }
}