//style presets
:root {
  --msv-cart-border: #{$msv-gray-300};
}

.msc-cart {
  &-lines-item{
    .msc-price__suffix{
      color: $msv-gray-500;
      font-size: $msv-font-size-xs;
      letter-spacing: 0.1em;
      text-transform: none;
    }
  }
  &-line {
    &__content {
      display: flex;
      width: 100%;
    }
    &__product {
      &-variants__title {
        font-weight: $msv-font-weight-heavy;
        letter-spacing: 1.5px;
        margin-bottom: 2rem;

        .ms-checkout & {
          margin-bottom: $spacer;
        }
        .msc-flyout & {
          display: none;
        }
        .msc-price__suffix {
          font-weight: $msv-font-weight-light;
        }
      }
      &-variants {
        color: $msv-black;
      }
      &-variant-item {
        font-size: $msv-font-size-s;
        letter-spacing: 1px;
        margin-bottom: $spacer-half;
        
        .name,
        .value {
          font-weight: $msv-font-weight-heavy;
          margin-left: $spacer-xs;
          
          .msc-flyout & {
            font-weight: $msv-font-weight-normal;
            color:$msv-gray-500;
          }
        }
        .label {
          .msc-flyout & {
            display: none;
          }
        }
      }
    }
    &__product {
      &-image {
        width: 100%;

        img {
          width: 100%;
        }
      }
      &-price {
        margin-top: 2rem;
        font-size: 0.875rem;
        text-transform: uppercase;
        margin-bottom: $spacer-xs;

        .msc-price__prefix,
        .msc-price__actual {
          font-weight: $msv-font-weight-heavy;
          letter-spacing: 1.5px;
        }
        .msc-price__suffix {
          font-size: $msv-font-size-xs;
          margin-top: .25rem;
          text-transform: uppercase;
        }
      }
    }
    &__promo-codes {
      display: inline-block;
    }
    &__product-title {
      @extend %h3;
      font-weight: $msv-font-weight-heavy;
      margin-bottom: 1.75rem;
    }
    &__remove-item {
      margin-bottom: $spacer-m;
    }
    &__remove-item,
    &__add-to-order-template,
    &__add-to-wishlist {
      @extend %underline-button;

      &:after {
        content: ''
      }
    }
    &__bopis-method .ship {
      color: var(--msv-cart-label-color);
    }
    &__bopis-shipping {
      align-self: baseline;

      input {
        @include vfi();
      }
    }
    &__bopis-store-pickup {
      align-self: baseline;
    }
  }
  &__empty-cart {
    margin: auto;
    width: auto;
    background: #fff;

    .msc-cart__btn-backtoshopping {
      @extend %secondary-button;
    }
  }
  &-lines-group {
    &-wraper {
      &__bopis {
        &-heading {//msc-cart-lines-group-wraper__bopis-method
          border-bottom: 1px solid var(--msv-cart-border);
          position: relative;
          top: 10px;

          &-title {
            display: flex;
            flex: 1 1 100px;
          }
          &-pickup-icon {
            position: absolute;
            justify-content: center;
            font-size: 28px;
            padding-top: 10px;
            padding-left: 20px;
          }
          &-shipping-icon,
          &-email-icon,
          &-ship-icon {
            position: absolute;
            justify-content: center;
            font-size: 28px;
            padding-top: 10px;
            padding-left: 20px;
          }
        }
        &-changestore,
        &-btn {
          @include button-link(var(--msv-cart-btn-link-color));
          display: block;
          height: auto;
          text-decoration-line: underline;
        }
      }
    }
  }
}