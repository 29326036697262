.collapse {
    &:not(.show) {
        display: none;
    }
}

.collapsing {
    height: 0;
    overflow: hidden;
    position: relative;
}