@import '../00-settings/colors';
@import '../00-settings/font';
@import '../00-settings/responsive-breakpoints';

$msv-btn-min-width: 10rem;
$msv-btn-side-padding: 20px;
$msv-btn-vertical-padding: 12px;

:root {
  // Background
  --msv-secondary-button-background-color: #{$msv-white};
}

@mixin button-link($color: $msv-link-color) {
  border-color: transparent;
  background-color: transparent;
  color: $color;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

@mixin primary-button($bg-color: var(--msv-accent-brand-color), $color: var(--msv-font-secondary-color), $border-color: var(--msv-accent-brand-color)) {
  background-color: $bg-color;
  border: 1px solid $border-color;
  color: $color;
  padding: $msv-btn-vertical-padding $msv-btn-side-padding;
  min-width: $msv-btn-min-width;

  &:hover,
  &:focus {
    background-color: var(--msv-accent-brand-alt-color);
    border-color: var(--msv-accent-brand-alt-color);
  }

  &:disabled,
  &[disabled] {
    cursor: default;
    border-color: $msv-gray-300;
    background-color: $msv-gray-300;
    color: var(--msv-font-secondary-color);
  }
}

@mixin primary-button--k($bg-color: $msv-primary, $color: #ffffff, $bg-color--hover: $msv-primary--hover, $color--hover: #ffffff, $bg-color--focus: $msv-primary--focus, $color--focus: #ffffff, $bg-color--disabled: $msv-gray-100, $color--disabled: #ffffff
) {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 0.875rem;
  letter-spacing: 0.1em;
  background-color: $bg-color;
  border: none;
  color: $color;
  padding: $spacer $spacer-l;
  min-width: $msv-btn-min-width;
  transition: color .3s ease-in-out,background-position .3s ease-in-out;
  background-image: linear-gradient(
                  -60deg
          ,$bg-color--hover 50%,$bg-color 0);
  background-size: 400% 400%;
  background-position: 0 50%;
  cursor:pointer;
  &:hover {
    background-color: $bg-color--hover;
    color:$color--hover;
    background-position: 100% 50%;
    text-decoration:none;
  }
  &:focus {
    background-color: $bg-color--focus;
    color:$color--focus;
    outline:none;
  }

  &:disabled,
  &[disabled] {
    cursor: default;
    background-color: $bg-color--disabled;
    color:$color--disabled;
    background-image: linear-gradient(
                    -60deg
            ,$bg-color--disabled 50%,$bg-color--disabled 0);
  }
}

@mixin secondary-button($bg-color: transparent, $color: var(--msv-font-primary-color), $border-color: var(--msv-font-primary-color), $disableBtn: true) {
  background-color: $bg-color;
  border: 1px solid $border-color;
  color: $color;
  padding: $msv-btn-vertical-padding $msv-btn-side-padding;
  min-width: $msv-btn-min-width;
  &:hover,
  &:focus {
    background-color: var(--msv-accent-secondary-btn);
  }
  
  @if $disableBtn {
    &:disabled,
    &[disabled] {
      cursor: default;
      border-color: $msv-gray-300;
      background-color: $msv-gray-300;
      color: var(--msv-font-secondary-color);
    }
  }
}

@mixin secondary-button--k(
  $bg-color: transparent, $color: $msv-primary,
  $bg-color--hover: $msv-primary, $color--hover: #ffffff,
  $bg-color--focus: $msv-gray-900, $color--focus: #ffffff,
  $bg-color--disabled: transparent, $color--disabled: $msv-gray-100
) {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 0.875rem;
  letter-spacing: 0.1em;
  background-color: $bg-color;
  border: 2px solid $color;
  color: $color;
  padding: calc(#{$spacer} - 2px) $spacer-l;
  min-width: $msv-btn-min-width;
  transition: color .3s ease-in-out,background-position .3s ease-in-out;
  background-image: linear-gradient(
                  -60deg
          ,$color 50%,transparent 0);
  background-size: 400% 400%;
  background-position: 0 50%;
  cursor: pointer;
  &:hover {
    // background-color: $bg-color--hover;
    color:$color--hover;
    border-color:$bg-color--hover;
    background-position: 100% 50%;
    text-decoration:none;

  }
  &:focus {
    background-color: $bg-color--focus;
    color:$color--focus;
    border-color:$bg-color--focus;
    background-position: 100% 50%;
  }

  &:disabled,
  &[disabled] {
    cursor: default;
    background-color: $bg-color--disabled;
    color:$color--disabled;
    border-color:$color--disabled;
  }
  @media screen and (max-width: $msv-breakpoint-s) {
    padding: $spacer $spacer-m;
  }
}

@mixin underline-button--k(
  $color: $msv-primary,
  $color--hover: $msv-primary--hover,
  $color--focus: $msv-primary--focus,
  $color--disabled: $msv-gray-500
) {
  cursor:pointer;
  background-color: transparent;
  border:none;
  border-bottom: 2px solid $color;
  color: $color;
  padding: 0;
  line-height:2em;
  min-width:0;
  transition: all .1s ease-in-out;
  text-decoration: none;
  font-weight: $msv-font-weight-heavy;
  text-transform: uppercase;
  font-size: 0.875rem;
  letter-spacing: 0.1em;
  &:hover {
    color:$color--hover;
    border-color:$color--hover;
    text-decoration: none;
  }
  &:focus {
    color:$color--focus;
    border-color:$color--focus;
  }

  &:disabled,
  &[disabled] {
    cursor: default;
    color:$color--disabled;
    border-color:$color--disabled;
  }
}

@mixin button-default() {
  @include vfi();
  font-family:$msv-primary-font-family;
  font-weight: bold;
  text-transform:uppercase;
  cursor: pointer;
  font-size: 0.875rem;
  letter-spacing: 0.1em;
  line-height: 1.3em;
  padding: $spacer $spacer-l;
  min-width: $msv-btn-min-width;
  text-align: center;
}

@mixin button-icon {
  width: 48px;
  height: 48px;
}
