$stepper-icon-size:3rem;

.ms-stepper {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	width: 80%;
	margin:auto;
	position: relative;
	text-align: center;
	padding-bottom: $spacer-l;

  &:before {
    content:"";
    position: absolute;
    top: 1.5rem;
    border-bottom: 2px dotted $msv-gray-500;
    width: 100%;
    display: block;
  }
  .icon {
    display: inline-block;
    border:1px solid $msv-gray-300;
    border-radius: 50%;
    color: $msv-white;
    background: $msv-gray-300;
    -webkit-box-shadow:inset 0 0 0 5px rgba(255,255,255,1);
    box-shadow:inset 0 0 0 5px rgba(255,255,255,1);
    width:$stepper-icon-size;
    height: $stepper-icon-size;
    line-height:$stepper-icon-size;
    text-align: center;
    position: relative;
    z-index: 1;
    margin-bottom: 0.5rem;

    &:before {
      font-size:1.7rem;
      line-height: 2.9rem!important;
    }
  }
  .current {
    .icon {
      background: $msv-secondary;
      border-color: $msv-secondary;
    }
    .ms-stepper--title {
      color:$msv-secondary;
    }
    .ms-stepper--description {
      color:$msv-primary;
    }
  }
  &--step1 {
    .icon {
      @include add-icon-icomoon($check-out);
    }
  }
  &--step2 {
    .icon {
      @include add-icon-icomoon($improvement);
    }
  }
  &--step3 {
    .icon {
      @include add-icon-icomoon($reception);
    }
  }
  &--step4 {
    .icon {
      @include add-icon-icomoon($preparazione);
    }
  }
  &--step5 {
    .icon {
      @include add-icon-icomoon($preparazione);
    }
  }
  &--step6 {
    .icon {
      @include add-icon-icomoon($billed);
    }
  }
  &--step7 {
    .icon {
      @include add-icon-icomoon($registered);
    }
  }
  &--step8 {
    .icon {
      @include add-icon-icomoon($sent);
    }
  }
  &--step9 {
    .icon {
      @include add-icon-icomoon($delivered);
    }
  }
  > div {
    color:$msv-gray-300;
    position: relative;
    flex-grow: 1;
    flex-basis: 0;

    &:before {
      background: $msv-white;
      position: absolute;
      left:0;
      top: 1.5rem;
      height: 4px;
      width: 50%;
    }
    &:last-child, &:first-child {
      &:before {
        content:"";
      }
    }
    &:last-child {
      &:before {
        left: auto;
        right:0;
      }
    }
  }
  &--title {
    text-transform: uppercase;
    margin-bottom: 0;
  }
  &--description {
    font-size: 12px;
	margin: auto;
	width: 70%;
  }
  &__2nd-level {
    margin: $spacer-l 0;
    padding:0;
    width: 100%;
    &__ctn {
      margin-top:$spacer-xl;
    }
  }
}
@media (max-width: $msv-breakpoint-m) {
  .ms-stepper {
    display: none;
  }
}