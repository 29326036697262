.OrderConfirmation h1 {
    text-align: center;
    @extend %h2;
    padding: $spacer-l $layout-gutter $spacer $layout-gutter;
    width: 100%;
}
.order-confirmation__container {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
.ms-order-confirmation {
    margin: 0 auto 2.5rem auto;
    outline: 1px solid $msv-gray-100;
    position: relative;
    padding: .75rem 1.75rem;
    width: 100%;

    &__title {
        font-weight: $msv-font-weight-medium;
        text-align: center;
        padding-top: 2.5rem;
        padding-bottom: 1rem;
    }
    &__heading {
        color: $msv-red;
        font-size: $msv-title-font-size-xs;
        font-weight: $msv-font-weight-heavy;
        line-height: 54px;
        margin-bottom: 0;
    }
    &__recap {
        &__owner-data {
            h4 {
                font-size: 1.25rem;
                line-height: 1.75rem;
                margin-bottom: 0.625rem;
            }
            h6 {
                margin: $spacer-half 0;
            }
        }

    }
    .checkout-gotoaccount {
        p {
            font-size: 0.875rem;
            color: $msv-red;
            font-weight: $msv-font-weight-heavy;
            letter-spacing: 1.5px;
            text-transform: uppercase;
        }
        .primary-button--red {
            justify-content: center;
            width: 100%;
        }
    }
    &__recap-title {
        border-bottom: 1px solid $msv-black;
        font-size: 2rem;
        line-height: 2.5rem;
        margin: $spacer-m 0;
        padding-bottom: $spacer-m;
        text-transform: uppercase;
    }
    &__order-information {
        display: flex;
        flex-direction: column;

        > span {
            @include font-content-m();
            letter-spacing: 1.5px;

            span {
                font-weight: $msv-font-weight-heavy;
            }
        }
        &-channel-reference-id {
            order: -1;
        }
        &-receipt-email {
            margin: 1.5rem 0 .75rem 0;
        }
        &-count {
            border-right: 1px solid $msv-black;
            padding-left: .5rem;
            padding-right: .5rem;
        }
        &-amount {
            padding-left: .5rem;
        }
    }
    &__groups {
        border-top: 1px solid $msv-black;
        @include font-content-m();
        display: flex;
        flex-wrap: wrap;
    }
    &__group {

        &-delivery {
            padding-top: 1.25rem;
            padding-bottom: 1.25rem;

            &-heading {
                font-size: 1.25rem;
                font-weight: $msv-font-weight-heavy;
                line-height: 28px;
                margin-bottom: .5rem;
            }
        }
    }
    &__sales-lines-shipping-items-label {
        display: none;
    }
    &__tracking-link {
        text-decoration: underline;
    }
    &__payment-methods {
        @include font-content-m();
        padding: 1.25rem 0;
        
        &-title {
            font-size: 1.25rem;
            font-weight: $msv-font-weight-heavy;
            line-height: 28px;
            margin-bottom: .5rem;
        }
        &-line {
            margin-bottom: .5rem;

            p:first-of-type {
                font-weight: $msv-font-weight-heavy;
                letter-spacing: .5px;
            }
        }
        &-card-amount,
        &-loyalty-amount,
        &-gift-card-amount {
            margin-left: .25rem;
        }
    }
    &__address {

        > span {
            letter-spacing: 0.5px;
            text-transform: capitalize;
        }
        &-name {
            font-weight: $msv-font-weight-heavy;
            margin-right: .25rem;
        }
        &-header,
        &-county {
            display: none;
        }
        &-phone {
            display: block;
        }
    }
    &__pickup-date-timeslot {
        padding-top: 1.75rem;
        margin-bottom: .5rem;

        &-title {
            font-size: 1.25rem;
            font-weight: $msv-font-weight-heavy;
            line-height: 28px;
            letter-spacing: 1.5px;
        }
        &-content {
            font-weight: $msv-font-weight-light;
        }
    }
    &__alert {
        display: block;
        margin-bottom: 1.25rem;
        margin-top: 1.25rem;
    }
    .msc-empty_image {
        @include image-placeholder(81px);
        background-size: 100%;
    }
}
.ms-order-confirmation__side {
    border: 1px solid $msv-gray-100;
    margin: 0 auto 2.5rem auto;
    padding: 1.5rem;
    .msc-price__prefix {
        display: none;
    }
    .msc-price__actual {
        @extend .msc-order-summary-totalPrice-value;
        display:block;
    }
    .ms-order-confirmation__groups {
        border-top: none;

        .msc-cart-line__content {
            flex-direction: column;

            .msc-price__suffix{
                color: $msv-gray-500;
                font-size: $msv-font-size-xs;
                margin-top: 1.5rem;
                text-transform: uppercase;
                letter-spacing: 0.1em;
            }
        }
        .msc-cart-line__financing {
            display:none;
        }
    }
}
@media (max-width: $msv-breakpoint-l) {
    .order-confirmation__container {
        flex-direction: column;
    }
}
@media (max-width: $msv-breakpoint-m) {
    .ms-order-confirmation {
        padding: .75rem $spacer;

        .checkout-gotoaccount {
            margin-top: $spacer;

            .primary-button--red {
                padding: $spacer;
            }
        }
    }
}