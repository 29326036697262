//spacing
$header-container-spacing-desktop: 40px;
$header-container-spacing-mobile-left: 6px;
$header-container-spacing-mobile-right: 12px;
$header-container-spacing-mobile: 0px;
$signin-info-spacing-left: 2px;
$navigation-spacing-left: 8px;
$cart-icon-spacing-left: 20px;
$heading-padding-top: 25px;
$signin-info-padding: 12px 14px;
$header-height: 48px;
$header-mobile-height: 48px;
$header-icon-width: 48px;
$account-link-height: 35px;
$account-links-max-width: 276px;
$signin-link-padding: 0 20px;
$signin-link-height: 48px;
$header_container-max-width: $msv-breakpoint-xl;
$header-myaccount-margin-left: 8px;
$header-logo-margin-right: 20px;
$header-logo-margin: auto auto auto 12px;
$header-icon-padding-right: 10px;
$header-icon-padding-left: 10px;
$header-cart-icon-margin-left: 4px;
$popover-menu-item-height: 48px;
$msv-default-container-padding-bottom: 25px;
$nav-padding-top-m: 20px;

header {
    background-color:var(--msv-header-bg);
}

.ms-header {
    background-color:var(--msv-header-bg);
    color: var(--msv-header-font-color);
    margin-left: auto;
    margin-right: auto;
    padding-left: $header-container-spacing-mobile;
    padding-right: $header-container-spacing-mobile;
    padding-top: $spacer;
    button {
        letter-spacing: 0.02em;
    }
    .msc-btn {
        text-transform: none;
        &:hover {
            color:$msv-secondary;
        }
    }
    &__container {
        padding-bottom: $spacer-half;

        .ms-header__logo {
            margin-right: $layout-gutter;
            a {
                @include vfi();
                display: block;

                &:focus {
                    outline-offset: -2px;
                }
                img {
                    width: auto;
                    max-height: 4.5rem;
                    height: auto;
                }
            }
        }

        .ms-header__collapsible-hamburger {
            .ms-header__divider {
                display: none;
            }
        }
    }
    .ms-header__preferred-store-text{
        color: var(--msv-header-font-color);
        font-size: var(--msv-header-font-size);
        margin: 0 8px;
    }
    .ms-header__preferred-store-btn {
        @include add-icon($msv-Shop-Address);
    }

    .ms-header__preferred-store-btn,
    .ms-search__icon,
    .ms-header__signin-button,
    .msc-wishlist-icon,
    .msc-cart-icon,
    .ms-header__profile-button {
        background-color: transparent;
        color: var(--msv-header-font-color);
        justify-content: center;

        &:focus {
            outline-offset: -1px;
        }
    }

    .ms-search__form-cancelSearch {

        justify-content: center;
    }

    .ms-search__form-control,
    .ms-search__form-cancelSearch {
        &:focus {
            outline-offset: -1px;
        }
    }


    // hiding the header icons from login page
    &.hide-header-options {
        .ms-header__nav-icon,
        .ms-header__account-info.account-desktop,
        .ms-header__account-info.account-mobile,
        .msc-cart-icon,
        .ms-header__divider,
        .msc-wishlist-icon {
            display: none;
        }
    }

    .ms-header__topbar {
        align-items: center;
        display: flex;
        flex-wrap: nowrap;
        margin-bottom:$spacer;
        .msc-btn {
            font-size:1rem;
        }
        .msc-popover.show a {
            color: $msv-white;
            height: 3rem;

            &:hover {
                color: $msv-primary-hover;
            }
            &:focus {
                color: $msv-primary--focus;
            }
        }
    }

    .ms-search {
        display: none;
    }

    .ms-header__desktop-view {
        display: none;
    }

    .ms-header__account-info {
        display: none;
        justify-content: flex-end;

        &__account-link-container {
            height: $account-link-height;
        }

        a {
            align-items: center;
            display: flex;
            height: 100%;
            padding: 1rem 2.5rem;
            font-weight: normal;

            &:hover {
                text-decoration: none;
            }
        }

        .ms-header__signin-button {
            &:focus {
                outline-offset: -1px;
            }
        }

        .msc-popover {
            background-color: var(--msv-header-bg);
            background-clip: padding-box;
            border: 1px solid $msv-gray-500;
            display: block;
            left: 0;
            max-width: $account-links-max-width;
            position: absolute;
            top: 0;
            word-wrap: break-word;
            z-index: 1060;

            .msc-btn {
                color: var(--msv-header-font-color);
                font-weight: var(--msv-font-weight-normal);
                height: $popover-menu-item-height;
            }

            .msc-arrow {
                display: block;
                height: .5rem;
                margin: 0 .3rem;
                position: absolute;
                top: calc((.5rem + 1px) * -1);
                width: 1rem;

                &::before,
                &::after {
                  border-color: transparent;
                  border-style: solid;
                  border-width: 0 .5rem .5rem .5rem;
                  content: "";
                  display: block;
                  position: absolute;
                }

                &::before {
                    border-bottom-color: $msv-gray-500;
                    top: 0;
                }

                &::after {
                    border-bottom-color: var(--msv-border-color);
                    top: 1px;
                }
            }
        }
    }

    .msc-wishlist-icon {
        &:hover {
            text-decoration: none;
        }
    }

    .ms-header__wishlist-desktop {
        align-items: center;
        display: none;

       // @include add-icon-icomoon($heart);

        .msc-wishlist-icon__text {
            display: none;
        }
    }

    .ms-header__nav-icon {
        align-items: center;
        background: var(--msv-header-bg);
        color:#fff;
        font-size:1.3rem!important;
        padding:1rem;
        display: flex;
        justify-content: center;
        width: 55px;
        @include add-icon-icomoon($menu);
        &:focus {
            outline-offset: -1px;
        }

        &:hover {
            text-decoration: none;
        }
        &[aria-expanded="true"] {
            @include add-icon-icomoon($close);
        }
    }

    .msc-cart-icon {
        display: flex;
        align-items: center;
        padding:$spacer-half $layout-gutter;
        @include add-icon-icomoon($cart);

        &:hover {
            text-decoration: none;
        }

        &__count {
            @include font-content-s();
            margin-left: $header-cart-icon-margin-left;
        }
    }

    @media (min-width: $msv-breakpoint-xs) {
        .ms-nav.desktop-vp {
            display: none;
        }

        .msc-cart-icon {
            &:before {
                width: $header-icon-width / 2;
            }
        }
    }
    @media (max-width: $msv-breakpoint-l) {
        padding-left: $layout-gutter;
        padding-right: $layout-gutter;

        .msc-wishlist-icon {
           // @include add-icon($msv-Heart, before, 400);
            &:before {
                padding-right: $header-icon-padding-right;
            }
        }

        &__container {
            .ms-header__topbar {
                justify-content: space-between;
                
                .ms-header__logo {
                    margin: auto;

                    > a {
                        margin-right: -28px;

                        .hide-header-options & {
                            margin-right: 0;
                        }
                    }
                }
                .ms-site-picker {
                    display: none;
                }
            }
            .ms-header__collapsible-hamburger {
                .ms-nav {
                    .ms-nav__drawer {
                        .drawer__button {
                            @include font-content(var(--msv-font-weight-normal), var(--msv-header-font-size), $msv-line-height-m);
                            color: var(--msv-header-font-color);
                            background: transparent;
                            text-align: left;
                            width: 100%;

                            .ms-nav__drawer-open {
                                @include add-icon($msv-ChevronUp, after);
                            }
                            .ms-nav__drawer-close {
                                @include add-icon($msv-ChevronDown, after);
                            }
                            .drawer__glyph__end {
                                position: absolute;
                                right: 10px;
                            }
                        }
                        .msc-btn {
                            outline-offset: -1px;
                        }
                    }
                    .ms-nav__list__item__link {
                        @include font-content(var(--msv-font-weight-normal), var(--msv-header-font-size), $msv-line-height-m);
                        align-items: center;
                        display: flex;
                        padding: 1rem 2.5rem;
                    }
                }
            }
        }
        .ms-header__account-info.account-mobile {
            display: unset;
            margin-left: 0;

            .drawer__button {
                color: $msv-white;
                @include font-content(var(--msv-font-weight-normal), var(--msv-header-font-size), $msv-line-height-m);
                &:before {
                    font-family: 'icomoon' !important;
                    content: "\e918";
                    padding-right: $header-icon-padding-right;
                }
                background: transparent;
                text-align: left;
                width: 100%;

                .ms-header__drawer-open {
                    @include add-icon($msv-ChevronUp, after);
                }
                .ms-header__drawer-close {
                    @include add-icon($msv-ChevronDown, after);
                }
                .drawer__glyph__end {
                    position: absolute;
                    right: 2.5rem;
                }
            }
            .ms-header__signin-button {
                display: flex;
                justify-content: flex-start;
                @include add-icon-icomoon($user-profile);

                span.ms-header__signin-button-text {
                    padding-left: 10px;
                }
            }
            .msc-btn {
                outline-offset: -1px;
            }
            .collapse a,
            .collapse a.msc-btn {
                color: $msv-white;
                font-size: 16px;
                letter-spacing: normal;
                padding-left: 3.5rem;
            }
        }

        .ms-header__collapsible-hamburger {
            background: var(--msv-header-bg);
            left: 0px;
            margin-top: 2px;
            height: calc(100vh - 115px);
            position: absolute;
            width: 100%;
            z-index: 1002;

            .ms-header__wishlist-mobile {
                display: none;
            }

            .ms-search {
                justify-content: flex-start;
                display: flex;

                .ms-search__label {
                    display: flex;
                    width: 100%;

                    .ms-search__icon {
                        display: flex;
                        justify-content: flex-start;
                        width: 100%;
                    }

                    .ms-search__icon-text {
                        display: unset;
                        padding-left: $header-icon-padding-left ;
                    }
                }
            }
        }
    }
    @media (max-width: $msv-breakpoint-s) {
        padding-right: 0;
        padding-left: 0;
    }
    @media (min-width: $msv-breakpoint-l) {
        padding-left: $spacer-l;
        padding-right: $spacer-l;

        .ms-header__desktop-view {
            display: block;
        }

        .ms-nav.desktop-vp {
            display: flex;
        }

        &__modal {
            display: none;
        }

        &__profile-button {
            color: var(--msv-header-font-color);
            font-weight: var( --msv-font-weight-normal);
            background: var(--msv-header-bg);
            &:after {
                @include msv-icon();
                content: $msv-ChevronDown;
                font-size: var(--msv-header-font-size);
                margin-left: $header-myaccount-margin-left;
                text-align: center;
                vertical-align: text-bottom;
            }
        }

        &__profile-button[aria-expanded=true] {
            &:after {
                content: $msv-ChevronUp;
            }
        }

        &__profile-button[aria-expanded=false] {
            &:after {
                content: $msv-ChevronDown;
            }
        }

        &__divider {
            display: none;
        }

        .ms-header__account-info.account-desktop {
            display: block;
        }
        .ms-header__collapsible-hamburger {
            display: none;
            .ms-header__wishlist-mobile {
                text-align: left;
                width: 100%;
            }
        }

        .ms-search {
            display: flex;
            &__icon {
                align-items: center;
                font-weight: normal;
                
                &:focus {
                    outline-offset: -1px;
                }
            }
        }

        .ms-header__wishlist-desktop {
            //display: flex;
            display:none;
            padding:0.5rem $layout-gutter;
        }

        .ms-header__nav-icon {
            display: none;
        }

        .ms-cart-icon {
            width: unset;

            &:before {
                width: unset;
                margin-right: 4px;
            }
        }
    }
}