.slider-horizontal {
    background-color: $msv-gray-300;
    height: $spacer/4;
}
.slider {
    box-shadow: inset 0 0 2px;
    cursor: pointer;
    display: block;
    padding: 0;
    position: relative;
    touch-action: none;

    .slider__container {
        background-color: $msv-gray-300;
        height: 100%;
        width: 100%;
    }
    .slider__track {
        background-color: $msv-secondary;
        height: 100%;
        position: absolute;
        top: 0;
    }
    .slider__thumb {
        @include vfi();
        background-color: $msv-white;
        background-size: 12px 12px;
        border: 4px solid $msv-primary--focus;
        border-radius: 100%;
        display: inline-block;
        height: $spacer;
        outline-offset: 2px;
        position: absolute;
        top: 50%;
        transform: translate3d(-50%, -50%, 0);
        width: $layout-gutter;
    }
    .slider__thumb:before {
        content: '';
        display: block;
        outline-offset: calc(0px + 0.0625rem);
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
    .slider__thumb:focus, 
    &__thumb__range:focus + .slider__thumb {
        outline: 1px dashed $black;
        outline-offset: 2px;
        box-shadow: none;
    }
    .slider__labels-item {
        color: var(--msv-refiner-values-font-color);
        font-size: var(--msv-refiner-values-font-size);
        cursor: pointer;
        display: inline-block;
        margin-top: $spacer-m;
        padding-bottom: 20px;
    }
    .slider__labels-item.start {
        float: left;
    }
    .slider__labels-item.end {
        float: right;
    }
}