$msv-sign-up-width: 40%;
$msv-sign-up-width-m: 80%;
$msv-sign-up-account-item-margin-top: $spacer-l;
$msv-sign-up-message-margin-top: $spacer-l;
$msv-sign-up-message-padding: $spacer-l;
$msv-sign-up-email-verification-buttons-margin-top: $spacer-l;
$msv-sign-up-button-group-spacing: $spacer-xl;
$msv-sign-up-button-margin-top: $spacer;

//style presets
:root {
  --msv-sign-up-font-size: var(--msv-body-font-size-m);
  --msv-sign-up-font-color: var(--msv-font-primary-color);

  // heading
  --msv-sign-up-heading-font-size: var(--msv-body-font-size-xl);
  --msv-sign-up-heading-font-color: var(--msv-font-primary-color);

  // alert
  --msv-sign-up-success-alert-bg: #{$msv-gray-300};
  --msv-sign-up-success-alert-font-color: var(--msv-font-primary-color);
  --msv-sign-up-success-alert-border: #{$msv-gray-900};
  
  --msv-sign-up-error-alert-bg: var(--msv-error-color);
  --msv-sign-up-error-alert-font-color: var(--msv-font-secondary-color);
  --msv-sign-up-error-alert-border: var(--msv-error-color);

  --msv-sign-up-error-color: var(--msv-error-color);

  // primary button
  --msv-sign-up-primary-btn-bg: var(--msv-accent-brand-color);
  --msv-sign-up-primary-btn-font-color: var(--msv-font-secondary-color);
  --msv-sign-up-primary-btn-border: var(--msv-accent-brand-color);

   // secondary button
   --msv-sign-up-secondary-btn-bg: var(--msv-secondary-button-background-color);
   --msv-sign-up-secondary-btn-font-color: var(--msv-font-primary-color);
   --msv-sign-up-secondary-btn-border: var(--msv-accent-brand-color);
}

.ms-sign-up {
  padding-top:$spacer-xl;
  padding-bottom: $spacer-xl;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__container {
    width: $msv-sign-up-width;
  }

  &__heading {
    @extend %h1;
  }

  &__account-item {
    margin-top: $msv-sign-up-account-item-margin-top;

    &-input {
      @extend %input-text;
    }
  }

  &__email-verification-buttons {
    margin-top: $spacer;
  }

  .row__labels {
    display: flex;
    > * {
      margin-right: $spacer-m;
    }
  }

  .privacy2_field_ctn {
    p {
      font-size: 0.875rem;
      color:$msv-gray-500;
    }
  }
  /*Override inline style inject by AAD so we need important*/
  &__account-item-emailVerificationCode[aria-hidden="false"],
  &__page-success[aria-hidden="false"],
  &__page-error[aria-hidden="false"] {
    display: block !important;
  }

  &__page-success {
    @include validation-success(var(--msv-sign-up-success-alert-bg), var(--msv-sign-up-success-alert-border), var(--msv-sign-up-success-alert-font-color));
    display: none;
    margin-top: $msv-sign-up-message-margin-top;
    padding: $msv-sign-up-message-padding;
  }

  &__page-error {
    @extend %text-alert--error;
    display: none;
    margin-top: $spacer;
  }

  &__item-error {
    color:var(--msv-sign-up-error-color);
  }

  &__email-verification-button,
  &__create-button {
    @extend %primary-button;
    margin-top: $msv-sign-up-button-margin-top;
    width: 100%;
  }

  &__cancel-button {
    @extend %secondary-button;
    margin-top: $msv-sign-up-button-margin-top;
    width: 100%;
  }

  @media screen and (max-width: $msv-breakpoint-m) {
    &__container {
      width: $msv-sign-up-width-m;
    }
  }
}
