[dir="rtl"] {
    .msc-invoices-list {
        &__container {
            &__content {
                &__table {
                    &__header {
                        text-align: right;
                    }

                    &__row__extra-actions-cell__actions-container button {
                        text-align: right;

                        &:before {
                            margin-right: unset;
                            margin-left: 12px;
                        }
                    }
                }
            }
        }

        &__filter {
            @media screen and (min-width: $msv-breakpoint-m) {
                position: absolute;
                right: unset;
                left: 0;
            }
        }
    }
}