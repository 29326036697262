$search-autoSuggest-max-width: 400px;
$search-autoSuggest-categoryResults-item-height: 48px;
$search-autoSuggest-categoryResults-item-padding: 0 24px;
$search-autoSuggest-categoryResults-title-color: $msv-gray-500;
$search-autoSuggest-categoryResults-title-padding: 16px 24px 8px 12px;
$search-autoSuggest-categoryResults-item-price-color: $msv-gray-500;
$search-autoSuggest-keywordResults-item-height: 48px;
$search-autoSuggest-keywordResults-item-padding: 13px 12px 14px 12px;
$search-autoSuggest-picture-height: 50px;
$search-autoSuggest-input-text-height: 48px;
$search-autoSuggest-picture-margin-right: 12px;
$search-autoSuggest-productResults-item-height: 60px;
$search-autoSuggest-productResults-item-grid-columns-width: 52px;
$search-autoSuggest-productResults-item-padding-left: 20px;
$search-autoSuggest-productResults-item-padding-right: 12px;
$search-autoSuggest-productResults-title-color: $msv-gray-500;
$search-autoSuggest-productResults-title-padding: 16px 12px 14px 12px;
$search-searchForm-min-width: 240px;
$search-searchForm-height: 48px;
$search-autoSuggest-border-width: 8px;
$search-autoSuggest-top: $search-searchForm-height;
$search-autoSuggest-min-width: $search-searchForm-min-width;
$search-autoSuggest-hover-color: $msv-gray-200;
$search-form-control-padding: 8px;
$search-form-control-max-width: 304px;
$search-form-cancelBtn-height: $search-searchForm-height;
$search-form-cancelBtn-width: 48px;
$search-form-icon-height: $search-searchForm-height;
$search-form-icon-padding-left: 10px;
$search-form-submitBtn-height: $search-searchForm-height;
$search-form-submitBtn-width: 48px;
$search-form-submitBtn-border-radius: 0px;
$search-form-inactive-width-desktop: 94px;
$search-form-inactive-width-mobile: 48px;

//style presets
:root {
    
    //Search

    --msv-search-font-size: var(--msv-body-font-size-m);
    --msv-search-font-family: #{$msv-primary-font-family};

    //Search form
    --msv-search-form-bg: #{$msv-gray-100};
    --msv-search-form-border: #{$msv-gray-100};
    --msv-search-form-search-btn-color: var(--msv-accent-brand-color);
    --msv-search-form-cancel-btn-color: #{$msv-gray-100};

    //AutoSuggest
    --msv-autoSuggest-bg: var(--msv-bg-color);
    --msv-autoSuggest-border: #{$msv-primary};
    
 }

.ms-search {
    display: flex;
    justify-content: flex-end;
    flex-basis: 0;
    flex-grow: 1;

    .bx-hide {
        display: none;
    }

    button {
        border: 0;
    }

    picture {
        grid-column: 1 / 2;
        grid-row-end: span 2;
        height: $search-autoSuggest-picture-height;
        margin-bottom: auto;
        margin-right: $search-autoSuggest-picture-margin-right;
        margin-top: auto;
    }

    &__icon {
        align-items: center;
        background: transparent;
        cursor: pointer;
        display: inline-flex;
        justify-content: center;
        @include add-icon-icomoon($search);

        &-text {
            display: none;
        }
    }

    &__form {
        left: 0;
        position: absolute;
        width: 100%;
        z-index: 1001;
    }

    &__form-cancelSearch {
        align-items: center;
        background-color: transparent;
        order: 1;
        display: flex;
        min-width:0;
        padding:0;
        @include add-icon-icomoon($close);
        color:#fff;
        margin-left:-1rem;
    }

    &__form-submitSearch {
        align-items: center;
        background-color: var(--msv-search-form-search-btn-color);
        border-radius: $search-form-submitBtn-border-radius;
        color: var(--msv-font-secondary-color);
        order: 2;
        display: flex;
        padding: 0;
        @include add-icon-icomoon($search);
        &:before {
            margin:0 1rem;
            color:$msv-secondary;
        }
    }

    &__autoSuggest {
        background: var(--msv-autoSuggest-bg);
        box-sizing: border-box;
        justify-content: flex-end;
        left: 0;
        margin-top: 3rem;
        position: absolute;
        width: 100%;
        z-index: 1001;
    }

    &__searchForm {
        display: flex;
        height: $search-searchForm-height;
        justify-content: flex-end;
        min-width: $search-searchForm-min-width;
    }

    &__form-control {
        order: 0;
        width: 100%;
        color: $msv-white;
        padding:0;

        &::-ms-clear {
            display: none;
        }
        @media (max-width: $msv-breakpoint-l) {
            background-color: $msv-black;
            width: calc(100% - 5rem);
        }
    }

    @media (min-width: $msv-breakpoint-l) {
        &__form {
            left: unset;
            position: unset;
            top: unset;
            z-index: unset;

            &-control {
                max-width: $search-form-control-max-width;
            }
        }

        &__autoSuggest {
            height: unset;
            left: unset;
            max-width: $search-autoSuggest-max-width;
            min-width: $search-autoSuggest-min-width;
            position: absolute;
            top: unset;

            &__keyword,
            &__product,
            &__category {
                border-left: $search-autoSuggest-border-width solid var(--msv-autoSuggest-border);
                border-right: $search-autoSuggest-border-width solid var(--msv-autoSuggest-border);
            }

            &__keyword {
                border-top: $search-autoSuggest-border-width solid var(--msv-autoSuggest-border);
            }

            &__category {
                border-bottom: $search-autoSuggest-border-width solid var(--msv-autoSuggest-border);
            }
        }

        &__icon {
            padding:0 $layout-gutter;
            &-text {
                display: unset;
                padding-left: $search-form-icon-padding-left;
            }
        }
    }
}

a.msc-autoSuggest__productResults-item {
    display: grid;
    grid-template-columns: $search-autoSuggest-productResults-item-grid-columns-width;
    padding-left: $search-autoSuggest-productResults-item-padding-left;
    padding-right: $search-autoSuggest-productResults-item-padding-right;
    width: 100%;
}

.msc-autoSuggest {
    &__categoryResults-item {
        display: flex;
        font-size: var(--msv-search-font-size);
        height: $search-autoSuggest-categoryResults-item-height;

        a {
            @include vfi();
            display: flex;
            height: $search-autoSuggest-categoryResults-item-height;
            line-height: $search-autoSuggest-categoryResults-item-height;
            padding: $search-autoSuggest-categoryResults-item-padding;
            width: 100%;
        }

        :hover {
            background-color: $search-autoSuggest-hover-color;
        }
    }

    &__categoryResults-title {
        color: $search-autoSuggest-categoryResults-title-color;
        font-size: var(--msv-body-font-size-s);
        font-weight: bold;
        padding: $search-autoSuggest-categoryResults-title-padding;
        text-transform: none;
    }

    &__keywordResults-item {
        display: flex;
        font-size: var(--msv-search-font-size);
        height: $search-autoSuggest-keywordResults-item-height;

        a {
            @include vfi();
            display: flex;
            height: $search-autoSuggest-keywordResults-item-height;
            padding: $search-autoSuggest-keywordResults-item-padding;
            width: 100%;
        }

        :hover {
            background-color: $search-autoSuggest-hover-color;
        }
    }

    &__productResults-item {
        @include vfi();
        display: flex;
        font-size: var(--msv-search-font-size);
        color: $msv-primary;
        height: $search-autoSuggest-productResults-item-height;

        &-price {
            color: $search-autoSuggest-categoryResults-item-price-color;
            grid-column: 2 / 2;
        }

        &-text {
            grid-column: 2 / 2;
        }

        :hover {
            background-color: $search-autoSuggest-hover-color;
        }
    }

    &__productResults-title {
        color: $search-autoSuggest-productResults-title-color;
        font-size: var(--msv-body-font-size-s);
        font-weight: var( --msv-font-weight-bold);
        line-height: $msv-line-height-s;
        padding: $search-autoSuggest-productResults-title-padding;
        text-transform: none;
    }

    &__screen-reader {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        white-space: nowrap;
        width: 1px;
    }
}