

:root {
    --msv-accordion-expandcollapse-button-background:transparent;
    --msv-accordion-drawer-button-background:transparent;
    --msv-accordion-header-heading-font-size:24px;
    --msv-accordion-header-heading-font-color:black;
    --msv-accordion-item-content-block-color:black;
    --msv-accordion-item-content-block-font-size:16px;    
}

.ms-accordion {
    padding:$spacer;
    @include media-breakpoint-up(md) {
        padding:$spacer-xl;
    }
    @media screen and (max-width: $msv-breakpoint-s) {
        padding: 0;
    }
    &-header-section {
        display: none;
    }

    &-item {
        &__drawer {
            margin-top: $spacer-m;
            border-bottom: 1px solid $msv-gray-500;
            border-bottom: 1px solid rgba(104, 109, 113, .5);
            padding-bottom: $layout-gutter/2;

            .drawer__button {
                background: var(--msv-accordion-drawer-button-background);
                display: flex;
                justify-content: space-between;
                align-items: center;
                min-height: $spacer-l;
                padding: 0 $layout-gutter/2 $spacer-half 0;
                text-transform: none;
                width: 100%;
                cursor: pointer;

                &.msc-btn {
                    font-family: $msv-primary-font-family;
                    font-weight: $msv-font-weight-light;
                    font-size: 1.5rem;  
                    letter-spacing: normal;
                    text-align: left;
                }

                &:after {
                    color: $msv-gray-800;
                    font-size: 18px;
                    line-height: $spacer-m;
                }

                &[aria-expanded="true"] {
                    @include add-icon($msv-ChevronUp, after);
                }

                &[aria-expanded="false"] {
                    @include add-icon($msv-ChevronDown, after);
                }

                .drawer__buttontext {
                    padding-right: $layout-gutter*1.5;
                }
            }
        }

        &-content {
            padding: $spacer-half 0;

            .ms-text-block {
                font-family: $msv-primary-font-family;
                font-size: var(--msv-accordion-item-content-block-font-size) !important;
                color: var(--msv-accordion-item-content-block-color);
                background: var(--msv-accordion-item-content-block-background);
                letter-spacing: 1%;
                line-height: $spacer-m;
                margin-top: 0;
            }
        }
    }
}
@media (max-width: $msv-breakpoint-m) {
    .ms-accordion-item__drawer .drawer__button.msc-btn {
        font-size: 1rem;
        font-weight: $msv-font-weight-heavy;
        text-transform: unset;
    }
}