$msv-checkout-description-margin-left: 20px;
$msv-checkout-option-margin-bottom: 20px;
$msv-checkout-delivery-options-description-margin-left: 0px;

//style presets
:root {
  --msv-checkout-delivery-options-font-size: var(--msv-body-font-size-m);
  --msv-checkout-delivery-options-font-color: #{msv-gray-900};
}
.msc-order-summary__deliveryInfo {
  margin-top: $spacer-xl;
  &__title {
    border-bottom:2px solid $msv-gray-100;
    padding-bottom: 1rem;
  }
  &__line {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__price {
    font-size: 1.5rem;
    font-weight: $msv-font-weight-medium;
  }
  &__name {
    font-size: 0.875rem;
    color:$msv-gray-500;
    font-weight: $msv-font-weight-medium;
    text-transform: uppercase;
    letter-spacing: 0.1em;
  }
}
.ms-checkout-delivery-options {
  &__price {
  }
  &__description-price {

  }
  &__description {
    font-weight: $msv-font-weight-medium;
    margin-right: 0.25rem;
  }
  .icon-tooltip {
    .tooltip {
      white-space: nowrap;
      min-width: 0;
    }
  }
  &__list {
    display: table;
  }
  &__option {
    margin-bottom: $spacer;
    align-items: center;
    display: table-row;
    > * {
      display: table-cell;
      padding: 0 $spacer $spacer 0;
      vertical-align: bottom;
    }
    &-selected {
      .ms-checkout-delivery-options__price {
        margin-left: 0;
      }

      .ms-checkout-delivery-options__description {

      }
    }

  }
  &__input-radio {
    @include form-input-radio();
  }
}
