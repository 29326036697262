[dir="rtl"] {
    [dir="auto"].ms-checkout-pickup__group-header-item {
        float: right;
        margin-left: unset;            
        margin-right: 5px;
    }
    [dir="auto"].ms-checkout-pickup__group-selected-content {
        float: right;
    }

    .ms-checkout-pickup {
        &__group {

            &-header {
                float: right;
                &-main {
                    &:before {
                        padding-left: 10px;
                        padding-right: unset;
                    }
                    float: right;
                    margin-left: 10px;
                    margin-right: unset;
                }
            }

            &-line-quantity {
                left: -15px;
                right: unset;
            }

            &-product-image {
                float: right;
                margin-left: 12px;
                margin-right: unset;
            }

            &-product-image.product-image-wide {
                margin-left: 24px;
            }
            
            &-datetime {
                &-dropdown {
                    padding-left: 20px;
                    padding-right: unset;

                    &-select {
                        padding-left: unset;
                        padding-right: 8px;
                    }
                }
            }
        }
    }
}