//style presets
:root {
  --msv-header-bg: #{$msv-primary};
  --msv-header-border: var(--msv-border-color);
  --msv-header-font-color: #{$msv-white};
  --msv-header-font-size: var(--msv-body-font-size-m);

  --msv-nav-bg: #{$msv-primary};
  --msv-nav-border: transparent;
  --msv-nav-dropdown-border: #{$msv-gray-300};
  --msv-nav-carot-color: var(--msv-font-primary-color);
  --msv-nav-font-color: #{$msv-white};
  --msv-nav-font-size: var(--msv-body-font-size-m);
  --msv-nav-font-family: #{$msv-primary-font-family};
  --msv-nav-title-font-size: var(--msv-body-font-size-l);
}