$msv-store-selector-phone-section-padding-top: 5px;
$msv-store-selector-location-line-shop-address-padding-top: 2px;
$msv-store-selector-location-line-shop-address-padding-right: 15px;
$msv-store-selector-input-height: 48px;
$msv-store-selector-button-border-radius: 0px;
$msv-store-selector-input-border-radius: 2px 0 0 2px;
$msv-store-selector-input-padding-left: 12px;
$msv-store-selector-button-height: 52px;
$msv-store-selector-button-width: 52px;
$msv-store-selector-search-form-padding: 0 0 30px 0;
$msv-store-selector-location-line-item-margin-top: 10px;
$msv-breakpoint-modal-m: 450px;
$msv-store-selector-msc-modal-content-padding: 40px;
$msv-store-selector-search-form-margin-bottom: 5px;
$msv-store-line-header: 20px;
$msv-store-locations-max-height: 400px;
$msv-store-list-z-index: 9;
$pickup-option-list-item-bg-color: #f8f7f6;
$pickup-option-list-item-img-padding: 10px 10px 1px 0px;
$pickup-option-list-margin: 5px;
$pickup-option-list-item-padding-top: 10px;
$pickup-option-list-item-padding-bottom: 10px;
$pickup-option-list-item-padding-left: 10px;
$pickup-option-list-item-padding-right: 10px;
$pickup-option-height: 48px;
$pickup-option-list-padding-left: 0px;
$pickup-option-list-margin-left: 29px;
$pickup-option-list-width: 200px;

//style presets
:root {
    --msv-store-select-heading-font-color: var(--msv-font-primary-color);
    --msv-store-select-heading-font-size: var(--msv-heading-font-size-s);

    --msv-store-select-form-bg: #{$msv-gray-100};
    --msv-store-select-form-input-size: var(--msv-body-font-size-l);
    --msv-store-select-form-input-color: var(--msv-font-primary-color);
    --msv-store-select-form-input-border: transparent;

    //body title
    --msv-store-select-title-font-size: var(--msv-body-font-size-l);
    --msv-store-select-title-font-color: var(--msv-accent-brand-color);

    //body text
    --msv-store-select-text-font-size: var(--msv-body-font-size-m);
    --msv-store-select-distance-size: var(--msv-body-font-size-s);
    --msv-store-select-distance-color: var(--msv-accent-brand-color);

    //Link
    --msv-store-select-link-size: var(--msv-body-font-size-s);
    --msv-store-select-link-color: var(--msv-font-primary-color);

    --msv-store-select-divider-color: #{$msv-gray-300};

    // primary button
    --msv-store-select-primary-btn-bg: var(--msv-accent-brand-color);
    --msv-store-select-primary-btn-font-color: var(--msv-font-secondary-color);
    --msv-store-select-primary-btn-border: var(--msv-accent-brand-color);

    // pickup option list icon color
    --ms-pickup-option-icon-color: var(--msv-font-secondary-color);
    --ms-pickup-option-selection-icon-color: var(--msv-font-primary-color);
}

.ms-store-select {
    &__found-locations {
        display: none;
    }

    &__no-locations {
        @include font-content(var(--msv-font-weight-light), var(--msv-store-select-text-font-size), $msv-line-height-m);
        color: $black;
    }

    &__modal-body {
        min-height: 400px;
        max-height: none;
    }

    &__search {
        display: block;
        padding: $msv-store-selector-search-form-padding;
        &-form {
            flex: auto;
            display: flex;
            margin-top: 1.25rem;
            margin-bottom: $msv-store-selector-search-form-margin-bottom;
            position: relative;

            input {
                border: 1px solid $msv-primary;
                font-weight: 300;
                padding: .5rem;
            }
            .MicrosoftMap {
                .as_container_search {
                    left: 0;
                    top: 51px;
                    width: 100%;
                }
                .as_container {
                    .suggestLink {
                        color: var(--msv-store-select-link-color);
                    }
                }
            }
        }
        &-see-all-stores {
            cursor: pointer;
            width: fit-content;
            &:hover,
            &:focus {
                text-decoration: underline;
            }
        }

        &-button {
            align-items: center;
            border-radius: 0;
            height: auto;
            min-width: unset;
            order: 2;
            width: auto;
            min-width: 2.5rem;
            display: flex;
            justify-content: center;
            background: $msv-black;
            border: none;
            @include add-icon-icomoon($search);
            &:before {
                color: $msv-white;
            }
        }

        .search-btn-disabled {
            // background-color: $msv-gray-300;
        }
    }

    &__toggle-view {
        display: none;
        cursor: pointer;
        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }

    &__store-hours-details {
        font-size: $msv-font-size-s;
        display: flex;
        flex-wrap: wrap;

        .ms-store-select__store-hours-day {
            flex: 0 0 40%;
        }

        .ms-store-select__store-hours-time {
            flex: 0 0 60%;
        }
    }
    &__locations {
        border-bottom: $border-separator;
        max-height: $msv-store-locations-max-height;
        overflow-y: scroll;
        padding: 0 $spacer-m 0 0;
    }

    &__location {
        padding: $spacer-m 0;
        border-top: 1px solid var(--msv-store-select-divider-color);

        &-line-item {
            color: $black;

            &-set-as-preferred-store {
                display: block;
                border: none;
                padding: 0;
                outline: none;
                background-color: transparent;
                cursor: pointer;
                width: fit-content;
                font-size: $msv-font-size-xs;
                color: $msv-secondary;
                text-decoration: underline;
                margin-top: $spacer-m;
                flex-basis: 100%;
                text-align: left;
                &:hover {
                    text-decoration: none;
                }
            }

            &-preferred-store {
                display: block;
                @include add-icon-icomoon($check);
                cursor: pointer;
                border: none;
                padding: 0;
                outline: none;
                background-color: transparent;
                font-size: $msv-font-size-xs;
                color: $msv-secondary;
                text-decoration: underline;
                margin-top: $spacer-m;
                &:hover {
                    text-decoration: none;
                }

                &:before {
                    margin-right: $spacer-half;
                }
            }
        }

        &-line-item-header,
        &-line-header,
        &-line-stock-status {
            margin-bottom: $spacer-half;
        }
        &-line-item-store-name {
            @extend %h5;
            display: inline-block;
            margin-bottom: 0;
        }
        &-line-item-store-index {
            color: var(--msv-store-select-title-font-color);
            @include font-content(var(--msv-font-weight-bold), var(--msv-store-select-title-font-size), $msv-line-height-l);
            margin-right: 5px;
            &:after {
                content: '.';
            }
        }

        &-line-item-store-distance {
            @include font-content(var(--msv-font-weight-normal), var(--msv-store-select-distance-size), $msv-line-height-s);
            color: var(--msv-store-select-distance-color);
        }
        &-line-item-store-availability {
            @media screen and (max-width: $msv-breakpoint-m) {
                margin-top: 2rem;
            
                .ms-store-select__location-line-select-store {
                    display: block;
                    margin: auto;
                }
            }
        }
        &-line-header {
            @extend %h6;
        }

        &-line-store-distance {
            color: $msv-gray-500;
        }

        &-line-item-content {
            display: flex;
            flex-direction: column;
        }

        &-line-shop-address {
            &-section {
                display: flex;
            }
            &-glyph-icon {
                display: none;
            }
            &-text {
                font-size: $msv-font-size-s;
                white-space: pre-wrap;
            }
        }

        &-line-shop-phone {
            &-section {
                display: flex;
                padding-top: $msv-store-selector-phone-section-padding-top;
            }
            &-icon {
                padding-top: $msv-store-selector-location-line-shop-address-padding-top;
                padding-right: $msv-store-selector-location-line-shop-address-padding-right;
                @include add-icon($msv-Phone);
            }
        }

        &-line-select-store {
            @extend %primary-button;
            cursor: pointer;

            @include media-breakpoint-up(lg) {
                position: absolute;
                bottom: 0;
                right: 0;
            }
        }

        &-line-pickup {
            align-self: flex-start;
            align-items: self-end;
            z-index: 1005;

            &-menu {
                background-color: var(--msv-store-select-primary-btn-bg);
                border: transparent;
                color: var(--msv-store-select-primary-btn-font-color);
                min-width: 160px;
                height: 48px;

                &:active:focus:hover {
                    outline: none;
                }
            }

            &-span {
                &:after {
                    @include msv-icon();
                    content: $msv-ChevronDown;
                    color: var(--ms-pickup-option-icon-color);
                    margin: 0 8px;
                    line-height: 40px;
                }
            }

            &-list {
                display: block;
                list-style-type: none;
                padding-left: $pickup-option-list-padding-left;
                position: relative;
                margin-left: $pickup-option-list-margin-left !important;
                width: $pickup-option-list-width;
                z-index: $msv-store-list-z-index;
            }

            &-list-item {
                background-color: $pickup-option-list-item-bg-color;
                cursor: pointer;
            }

            &-list-link {
                padding-bottom: $pickup-option-list-item-padding-bottom;
                padding-left: $pickup-option-list-item-padding-left;
                padding-top: $pickup-option-list-item-padding-top;
                padding-right: $pickup-option-list-item-padding-right;
                text-align: center;
                width: 100%;
            }

            &-list-item__text:hover:before {
                outline: 0;
            }
        }
        &-line-item-store-availability {
            p {
                display: none;
            }
        }
    }

    &__terms-link {
        @include font-content(var(--msv-font-weight-normal), var(--msv-store-select-link-size), $msv-line-height-s);
        @include vfi();
        color: $black;
    }
}

@media screen and (min-width: $msv-breakpoint-m) {
	.ms-store-select__location {
		&-line-item-content {
			flex-direction: row;
			flex-wrap: wrap;
			position: relative;
		}
		&-line-item-contact-info,
		&-line-item-store-hours {
			width: 33%;
			margin-right: 1%;
		}
		&-line-item-store-availability {
			width: 32%;
			text-align: right;
		}
		&-line-stock-status {
			font-size: $msv-font-size-s;
			position: relative;
		}
		&-line-pickup {
			height: 55px;

			&-list {
				margin-left: 0px !important;
			}
		}
	}
	.ms-store-select__location {
		&-line-item-content {
			flex-direction: row;
			flex-wrap: wrap;
		}

		&-line-item-contact-info,
		&-line-item-store-hours {
			width: 33%;
			margin-right: 1%;
		}

		&-line-item-store-availability {
			width: 32%;
			text-align: right;
		}

		&-line-stock-status {
			@include font-content(var(--msv-font-weight-light), var(--msv-store-select-text-font-size), $msv-line-height-m);
			position: relative;
		}

		&-line-pickup {
			height: 55px;
		}
	}
}
@media screen and (max-width: $msv-breakpoint-m) {
    .ms-store-select {
		overflow: hidden;

        &__toggle-view {
            display: block;
            background-color: transparent;
            float: right;
            border: none;
            outline: none;
        }
		&__search {
			padding: 0;
		}
		&__locations {
			max-height: 100%;
			padding-bottom: 4rem;
			width: calc(100vw - 20px);
		}
		&__location {
			&:first-of-type {
				border-top: none;
			}
		}
		&.msc-modal__dialog.msc-modal-input-required .msc-modal__content .msc-modal__header .msc-modal__title {
			font-size: $msv-title-font-size-xs;
			margin-bottom: 0;
			text-align: left;
		}
		.msc-modal__footer {
			background: $msv-white;
            z-index: 1;
		}
    }
}

@media screen and (max-width: $msv-breakpoint-modal-m) {
    .ms-store-select {
        &__location {
            &-line-select-store {
                width: 100%;
            }
            &-line-pickup {
                &-list {
                    margin-left: 0px !important;
                }
            }
        }
    }
}
