.ms-breadcrumb {
  display: none;
}
.ms-account-profile {
  margin: 0 auto $spacer-l auto;
  width: 100%;

  &-wrapper,
  &__attributes-wrapper {
    border: 1px solid $msv-gray-100;
    padding: 2rem $layout-gutter;
    position: relative;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: $spacer-l;

    @media screen and (max-width: $msv-breakpoint-m) {
      padding-bottom: $spacer;
      padding-bottom: 0;
    }

    > * {
      margin-left: $layout-gutter;
      margin-right: $layout-gutter;

      @include media-breakpoint-down(sm) {
        margin-left: 0;
        margin-right: 0;
      }
    }
    &:hover {
      border-color: $msv-primary--hover;

      .ms-account-profile__section-links {
        display: block;
      }
    }
    .ms-account-loyalty-tile__heading {
      flex-basis: 100%;
      font-weight: $msv-font-weight-heavy;
      text-transform: uppercase;
    }
  }
  &__attributes {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    &-Privacy {
      display:none;
    }
    &-tile__heading {
      flex-basis:100%;
    }
    &-save-button {
      align-self: center;
      @extend %primary-button;
      margin: $spacer $spacer $spacer 0;
    }
    &-cancel-button {
      align-self: center;
      @extend %outlined-button;
    }
    @include media-breakpoint-down(md) {
      &-save-button,
      &-cancel-button {
        flex-basis: calc(50% - #{$layout-gutter});
      }
      &-save-button {
        margin-left: 0;
      }
      &-cancel-button {
        margin-left: $layout-gutter;
      }
    }
    @include media-breakpoint-down(sm) {
      &-save-button,
      &-cancel-button {
        flex-basis: 100%;
      }
      &-save-button,&-cancel-button {
        margin: 0 0 $spacer 0;
      }

    }
    &__section {
      margin:$spacer $spacer-xxl $spacer 0;
      @include media-breakpoint-down(md) {
        margin-bottom: $spacer-l;
      }
      @include media-breakpoint-down(sm) {
        margin-right: $spacer-l;
      }
      h3 {
        @extend %label;
        margin-bottom: $spacer;
      }
      &__toggle {
        &-wrapper {
          display: flex;
          align-items: center;
          span {
            text-transform: uppercase;
          }
        }
        &-disable {
          color: $msv-gray-500;
        }

        &-button {
          margin:0 $spacer-half;
          width: 2.5rem;
          height: 1.23rem;
          border-radius: 1rem;
          background: $msv-secondary;
          padding:3px;
          text-align: left;
          position: relative;

          &:disabled {
            background: $msv-gray-300;
          }
          &:before {
            content:"";
            height: 0.875rem;
            width: 0.875rem;
            background: #fff;
            border-radius: 100%;
            display: inline-block;
            transition: all 0.25s;
            margin-bottom: 2px;
          }
        }
        &-enable-button {
          text-align: right;
        }
      }
    }
    &-wrapper {
      &:hover {
        .ms-account-profile__attributes-edit-button {
          display: block;
        }
      }
    }
    &-edit-button {
      @extend %underline-button;
      display: none;
      position: absolute;
      top: 2rem;
      right: 2rem;

      @media screen and (max-width: $msv-breakpoint-m) {
        top: $spacer;
        right: $layout-gutter;
      }
    }
  }
  .ms-account-profile__section-links {
      display: none;
      position: absolute;
      top: 2rem;
      right: 2rem;

      a[aria-label="Modifica profilo"] {
          @extend %underline-button;
      }
  }

  .ms-account-profile-wrapper {
      margin-right: 0;
      width: 100%;
  }
}
.ms-account-profile__section {
  background-color: #F8F8F8;
  display: inline-flex;
  justify-content: space-between;
  flex-basis: calc(50% - #{$layout-gutter*2});
  @include media-breakpoint-down(md) {
    flex-basis: 100%;
  }
  padding: $spacer-m;
  margin-top: $spacer;
  margin-bottom:$spacer;
  @include media-breakpoint-down(sm) {
    display: block;
    padding:$spacer;
    width: 100%;
  }
  &-heading,
  &-description {
      font-size: $msv-font-size-s;
      line-height: $msv-line-height-xs;
      letter-spacing: 1px;
      margin-bottom: 0;
      @include media-breakpoint-down(sm) {
        margin-bottom: $spacer-half;
      }
  }
  &-heading {
      font-weight: $msv-font-weight-light;
  }
  &-description {
      font-weight: $msv-font-weight-heavy;
  }
  &-email {
    p {
      overflow: hidden;
      text-overflow: ellipsis;
    }

  }
  &-preferences {
      display: none;
      margin-top: 0;
      text-align: right;
  }
}