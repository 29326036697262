.msc-order-summary {
  &-totalPrice {
    margin-bottom: $spacer-m;
    margin-top: $spacer-m;
  }
  &-totalPrice-value {
    @extend %h3;
    margin-bottom: 0;
  }
  &-totalPrice-label,
  &-totalPrice-info {
    font-size: 0.875rem;
    color:$msv-gray-500;
    text-transform: uppercase;
    letter-spacing: 0.1em;
  }
  &-totalPrice-info {
    margin-top: $spacer-m;
    font-size: 12px;
  }
  &__items {
    width: 100%;
  }

  &__line {
    &-net-price,
    &-tax-amount,
    &-shipping,
    &-loyalty,
    &-gift-card,
    &-other-charges,
    &-total-discounts {

    }

    &-total {

    }
  }
}