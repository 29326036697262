.icon-tooltip,
.text-tooltip {
    position: relative;

    .icon {
        font-size: 22px;
        cursor: pointer;
        margin-left: .25rem;

        @media screen and (max-width: $msv-breakpoint-m) {
            font-size: $msv-font-size-m;
        }
    }
    .icon,
    .text {
        &:hover ~ .tooltip,
        &:hover + .tooltip,
        &:active ~ .tooltip,
        &:active + .tooltip {
            opacity: 1;
        }
    }
    .tooltip {
        background-color: $msv-primary--focus;
        border-radius: 4px;
        color: $msv-white;
        display: flex;
        min-width: 16rem;
        opacity: 0;
        padding: $spacer-half $layout-gutter;
        pointer-events: none;
        position: absolute;
        text-align: center;
        transition: all .25s ease-out;
        z-index: 1000;
        transform: translateX(-50%);
        left: 12px;
        
        p {
            font-family: $msv-primary-font-family;
            font-size: $msv-font-size-s;
            font-weight: $msv-font-weight-light;
        }
        &:target {
            opacity:1;
            pointer-events: auto;
        }
        &::after {
            background-color: $msv-primary--focus;
            content: '';
            height: $spacer-m; 
            position: absolute;
            right: calc(50% - 32px);
            bottom: $spacer;
            transform: rotate(45deg) translateY(24px);
            width: 1.5rem;
            z-index: -1;
        }
        &.isMobileTooltip {
            border-radius: 0;
            position: fixed;
            bottom: 0;
            left: 0;
            transform: none;
            width: 100vw;
            z-index: 1000;

            &::after {
                content: '';
            }
        }
        &.isTooCloseToEnd::after {
            content: none;
        }
    }
    .icon+.tooltip,
    .text+.tooltip {
        @media screen and (max-width: $msv-breakpoint-m) {
            border-radius: 0;
            position: fixed;
            bottom: 0;
            left: 0;
            transform: none;
            width: 100vw;
            z-index: 1000;

            &::after {
                content: '';
            }
        }
    }
    .icon+.tooltip {
        bottom: 21px;

        &.isMobileTooltip {
            bottom: 0;
        }
    }
    .text+.tooltip {
        bottom: 2rem;
        left: 0;
        transform: none;

        &::after {
            right: calc(50% - 1rem);
        }
    }
}
.price-offer {
    .icon-tooltip__small {
        margin-left: .25rem;

        .icon {
            font-size: 15px;
        }
        .tooltip {
            margin-left: -.25rem;

            @media screen and (max-width: $msv-breakpoint-m) {
                margin-left: 0;
            }
        }
    }
    .tooltip {
        width: 450px;

        &::after {
            bottom: 1.125rem;
        }
    }
}