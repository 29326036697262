$ms-refine-submenu-item-rating-line-height: 28px;
$ms-refine-submenu-item-rating-padding-left: 12px;
$refine-menu-rating-margin-bottom: 10px;
$refine-menu-submenu-item-line-height: 42px;
$refine-menu-submenu-item-multi-select-line-height: 42px;

.ms-refine-submenu__list
{
    border: 0;
    margin: 0;
    min-width: 0;
    padding: 0;
}

.ms-refine-submenu-item
{
    display: block;
    .refine-submenu-item
    {
        &__rating
        {
            display: none;

        }
    }

    &__label {

    }

    .multi-select,
    .multi-select-checked {

    }

    .msc-rating {
    }
}

.ms-refine-submenu__toggle_collapsed
{
    position: relative;
    @include add-icon-icomoon($more, after);
    &:after {
        font-size: 0.7rem;
        position:absolute;
        top:1rem;
        right:0;
    }

}

.ms-refine-submenu__toggle_expanded
{
    position: relative;
    @include add-icon-icomoon($less, after);
    margin-bottom: 0.5rem;
    &:after {
        font-size: 0.7rem;
        position: absolute;
        top: 1rem;
        right: 0;
    }
}

.ms-refine-submenu-item {
    font-size:1.125rem;
    &.multi-select,&.multi-select-checked {
        @include add-icon-icomoon($check);
        &:before {
            font-size: 0.75rem;
            width: 2em;
            height: 2em;
            min-width: 2em;
            line-height: 2em;
            background: $white;
            color:transparent;
            border:1px solid $msv-gray-500;
            display: inline-block;
            text-align: center;
        }
    }

    &.multi-select-checked {
        &:before {
            border:1px solid $msv-primary;
            color:$msv-secondary;
        }
    }
    &.single-select {
        @include add-icon($msv-RadioBtnOff, before, 400);
    }
    &.single-select-checked {
        @include add-icon($msv-RadioBtnOn, before, 400);
    }

    &.multi-select,
    &.multi-select-checked,
    &.single-select,
    &.single-select-checked {
        &:before {
            vertical-align: middle;
        }
    }
}

.msc-choice-summary__clear-all
{
    @include add-icon($msv-Cancel, after);
}

.ms-refine-submenu__range-refiner {
    .slider__labels-item {
        font-size:  var(--msv-body-font-size-s);
        line-height: $msv-line-height-s;
        color: $msv-gray-900;
    }
}

.ms-refine-submenu-item[aria-checked="true"] .msc-rating__count {
    font-weight: bold;
}