%icon-font-style {
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  //line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

%icon-button {
  border:0;
  outline: none;
  background:transparent;
}

//icomoon
$search:"\e900";
$user: "\e903";
$arrow: "\e917";
$arrow-long: "\e90a";
$chevron: "\e905";
$close: "\e909";
$more:  "\e907";
$less: "\e906";
$error: "\e915";
$youtube: "\e90b";
$instagram: "\e90c";
$facebook: "\e90e";
$twitter: "\e90d";
$linkedin: "\e90f";
$cart: "\e902";
$cart-btn: "\e902";
$check:"\e908";
$user-profile: "\e918";
$info: "\e903";
$alert: "\e915";
$error: "\e915";
$menu: "\e914";
$wallet: "\e910";
$savings: "\e911";
$financing: "\e912";
$keys-in-hand: "\e913";
$check-out: "\e919";
$improvement: "\e91a";
$reception: "\e91b";
$index: "\e91c";
$preparazione: "\e91d";
$billed: "\e91e";
$registered: "\e91f";
$sent: "\e920";
$delivered: "\e921";

@mixin add-icon-icomoon($icon, $selector: before, $next-to-text:false, $margin:$layout-gutter, $scale:false ) {
  &:#{$selector} {
    @extend %icon-font-style;
    content: $icon;
    display:inline-block;
    line-height: 1.3em;
    @if $next-to-text and $scale==true{
      transform: scale(1.25);
    }
    @if $next-to-text and $selector == 'before'{
      margin-right: $margin;
    }
    @if $next-to-text and $selector == 'after'{
      margin-left: $margin;
    }
  }
}