// desktop
$cookie-compliance_container-padding-l: 60px;
$cookie-compliance_container-padding-m: 20px;
$cookie-compliance_container-padding-s: 16px;
$cookie-compliance_container-max-width: $msv-breakpoint-xl;
$cookie-compliance_content-width:100%;
$cookie-compliance_accept-btn-margin-left: 48px;
$cookie-compliance_accept-btn-width: 160px;

$cookie-compliance_bg:$msv-gray-100;
$cookie-compliance_text-color:$msv-gray-900;
$cookie-compliance_link-color:$msv-gray-900;

// mobile 
$cookie-compliance_accept-btn-width-mobile: 243px;
$cookie-compliance_accept-content-margin-bottom-mobile:24px;

// style preset
:root {
  // background
  --msv-cookie-compliance-bg-color:$msv-gray-100;

  // font
  --msv-cookie-compliance-font-color:var(--msv-font-primary-color);
  --msv-cookie-compliance-font-size:var(--msv-body-font-size-m);

  // link
  --msv-cookie-compliance-link-color:var(--msv-font-primary-color);

   // primary button
   --msv-cookie-compliance-primary-btn-bg: var(--msv-accent-brand-color);
   --msv-cookie-compliance-primary-btn-font-color: var(--msv-font-secondary-color);
   --msv-cookie-compliance-primary-btn-border: var(--msv-accent-brand-color);
}

.ms-cookie-compliance {
  background-color: $cookie-compliance_bg;
  color: $cookie-compliance_text-color;
  font-size: 1rem;

  &__container {
    display: flex;
    font-weight: normal;
    margin-bottom: 0;
    max-width: $cookie-compliance_container-max-width;
    margin: 0 auto;

    @media screen and (min-width: $msv-breakpoint-s) {

    }

    @media (min-width: $msv-breakpoint-m) {

    }
  }

  &__content {
    width:$cookie-compliance_content-width;
  }

  &__accept-button {
    float: right;
    @extend %secondary-button;
  }

  &__cta-layer .link {
    color:$cookie-compliance_link-color;
    text-decoration: underline;
  }

  &__text{
    font-size: var(--msv-cookie-compliance-font-size);
  }
}

@media (max-width: $msv-breakpoint-m) {
  .ms-cookie-compliance {

    &__content {
      margin-bottom: $cookie-compliance_accept-content-margin-bottom-mobile;
    }
    
    &__accept-button {
      float: none;
      margin: 0;
      width: $cookie-compliance_accept-btn-width-mobile;
    }

    &__container {
      align-items: center;
      flex-direction: column;
    }
  }
}