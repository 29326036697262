.msc-btn {
  @include button-default();
  display: inline-block;
  border: none;
  text-decoration: none;
  text-align: center;
  min-width: auto;
  &:not(:disabled) {
    cursor: pointer;
  }
}

.solid-button, %solid-button,
.primary-button, %primary-button,
.msc-cta__primary{
  @include primary-button--k();
  @media screen and (max-width: $msv-breakpoint-s) {
    padding: $spacer $spacer-m;
  }
}

.primary-button--red, %primary-button--red{
  @include primary-button--k(
          $bg-color: $msv-secondary, $color: #ffffff,
          $bg-color--hover: $msv-white, $color--hover: $msv-primary,
          $bg-color--focus: $msv-gray-100, $color--focus: $msv-primary,
          $bg-color--disabled: $msv-gray-500, $color--disabled: $msv-primary,
  );
}

.outlined-button, %outlined-button,
.secondary-button, %secondary-button{
  @include secondary-button--k();
}

.secondary-button--light, %secondary-button--light {
  @include secondary-button--k(
          $bg-color: transparent, $color: #fff,
          $bg-color--hover: #fff, $color--hover: $msv-primary,
          $bg-color--focus: $msv-gray-100, $color--focus: $msv-primary,
          $bg-color--disabled: transparent, $color--disabled: $msv-gray-100
  );
}

.underline-button, %underline-button{
  @include underline-button--k();
}

