.ms-order-history__order-count,
.ms-order-history__btn-more {
    display: none
}
.ms-order-history {
    &__heading {
        margin: $spacer-l 0;
        padding-bottom: 1.75rem;
        text-align: center;
    }
    &__sales-order-list {
        margin: $spacer-l auto;
    }
    &__sales-order {
        display: flex;
        border: 1px solid $msv-gray-300;

        &:not(:first-of-type) {
            margin-top: $spacer-m;
        }
        > div {
            padding: $spacer-xl 2rem 2rem;
        }
        .ms-order-history__groups {
            border-right: 1px solid $msv-gray-300;
            flex-basis: 50%;
            order: -1;
            padding: 2rem 3rem;
    
            .ms-order-history__sales-lines {
                display: flex;
                justify-content: space-between;

                .ms-order-history__sales-line-product {
                    font-size: 2rem;
                    font-weight: $msv-font-weight-heavy;
                    margin: auto 0;
                    padding-right: $layout-gutter;
                    width: calc(55% - 1rem);
                }
                .ms-order-history__sales-line-picture {
                    width: 45%;
                    
                    img {
                        max-height: 155px;
                        object-fit: cover;
                        width: 100%;
                    }
                }
            }
        }
        .ms-order-history__order-information {
            display: flex;
            flex-direction: column;
            flex-basis: 25%;

            > * > span:last-of-type {
                margin-left: 3px;
            }
            > div {
                display: flex;
            }
            > div > span:not(.ms-order-history__order-information-channel-name),
            .ms-order-history__order-information-channel-reference-id {
                line-height: $msv-line-height-s;
                letter-spacing: 1.5px;
                padding: 0;
            }
            .ms-order-history__order-information-label {
                font-weight: $msv-font-weight-light;
            }
            &-sales-id,
            &-channel-name,
            &-created-date,
            &-count,
            &-amount,
            &-channel-reference-id span {
                font-weight: $msv-font-weight-heavy;
            }
            &-channel-name {
                margin-bottom: $spacer*2;
                text-transform: uppercase;
            }
        }
    }
    &__status {
        flex-basis:25%;
        display: flex;
        flex-direction: column;
        &__heading {
            font-weight:$msv-font-weight-heavy;
            font-size: 1rem;
            text-transform: uppercase;
        }
        &__name {
            font-weight: $msv-font-weight-heavy;
            text-transform: uppercase;
            flex-grow: 1;
            &--done {
                color:#70c800;
            }
            &--in-progress {
                color:#daad0e;
            }
        }
    }
    &__btn-order-details {
        @extend %outlined-button;
        padding:calc(1rem - 2px) $spacer;
        margin-top: $spacer-m;
        justify-self: flex-end;
    }
}
@media screen and (max-width: $msv-container-max-width-m) {
    .ms-order-history {
        &__heading {
            padding-bottom: 0;
        }
        &__sales-order {
            flex-direction: column;

            .ms-order-history__groups,
            > div {
                border-right: none;
                padding: $spacer-m $layout-gutter*2;
            }
            .ms-order-history__groups .ms-order-history__sales-lines {
                flex-direction: column;
                .ms-order-history__sales-line-product,
                .ms-order-history__sales-line-picture {
                    width: 100%;
                }
                .ms-order-history__sales-line-product {
                    padding-right: 0;
                }
                .ms-order-history__sales-line-picture {
                    margin-top: $spacer-xl;

                    img {
                        max-height:  300px;
                    }
                }
            }
            >div.ms-order-history__order-information {
                flex-direction: column;
                padding-top: 0;

                &-channel-name {
                    margin-bottom: $spacer;
                }
            }
        }
        &__status__heading {
            margin-bottom: .25rem;
        }
        &__btn-order-details {
            margin: 3rem 0 0 0;
        }
    }
}