//style presets
:root {
    --msv-search-result-font-color: var(--msv-font-primary-color);
    --msv-search-result-font-size: var(--msv-body-font-size-m);

    --msv-search-result-heading-font-color: var(--msv-font-primary-color);
    --msv-search-result-heading-font-size: var(--msv-body-font-size-xl);

    --msv-search-result-sort-bg: transparent;
    --msv-search-result-sort-font-color: var(--msv-font-primary-color);
    --msv-search-result-sort-font-size: var(--msv-body-font-size-m);

    --msv-search-result-product-color: var(--msv-font-primary-color);
    --msv-search-result-product-size: var(--msv-body-font-size-m);

    --msv-search-result-product-price-color: var(--msv-font-primary-color);
    --msv-search-result-product-price-size: var(--msv-body-font-size-l);

    --msv-search-result-pagination-color: var(--msv-font-primary-color);
    --msv-search-result-pagination-size: var(--msv-body-font-size-m);

    //Refiner heading
    --msv-refiner-heading-font-color: var(--msv-font-primary-color);
    --msv-refiner-heading-font-size: var(--msv-body-font-size-l);

    //Refiner values
    --msv-refiner-values-font-color: var(--msv-font-primary-color);
    --msv-refiner-values-font-size: var(--msv-body-font-size-m);

    //border
    --msv-refiner-border: #{$msv-gray-300};

    // slider
    --msv-refiner-slider: var(--msv-font-primary-color);

    // rating star
    --msv-refiner-rating-star: var(--msv-font-primary-color);
    --msv-refiner-rating-star-size: var(--msv-body-font-size-m);

    --msv-feature-title-color: var(--msv-font-primary-color);
    --msv-feature-title-desktop-size: var(--msv-body-font-size-xxl);
    --msv-feature-title-mobile-size: var(--msv-body-font-size-xl);

    --msv-feature-heading-color: var(--msv-font-primary-color);
    --msv-feature-heading-font-size: var(--msv-body-font-size-l);
}

.ms-search-result-container__category-nav-section {
    display: flex;
    height: $spacer*1.25;
    justify-content: space-between;
    margin-bottom: $spacer*1.25;
    margin-top: $spacer*2;

    .ms-search-result-container__category-hierarchy {
        @include font-content-s();
    }

    .ms-search-result-container__title h5 {
        margin: 0;

        .ms-search-result__collection-title-count {
            @include font-content(var(--msv-font-weight-normal), var(--msv-search-result-font-size), $msv-line-height-m);
        }
    }
}

.ms-search-result-container__refine-product-section {
    display: inline-flex;
    margin-bottom: 80px;
    width: 100%;
}

// refiner section
.ms-search-result-container__refiner-section {
    display: block;
    padding-right: 40px;
    width: 25%;
    @include media-breakpoint-up(lg) {
        &::before {
            content: '';
            position: absolute;
            top: 2.5rem;
            left:0;
            height: 1px;
            width: 100%;
            background-color:$msv-gray-100;
        }
    }

    button.ms-refine-submenu__toggle_collapsed,
    .ms-refine-submenu__toggle_expanded {
        background: transparent;
        display: flex;
        justify-content: space-between;
        padding-left: 0;
        padding-right: 0;
        width: 100%;
        color:$msv-gray-500;
        font-weight: $msv-font-weight-medium;
        font-size: 1.3rem;
        @include media-breakpoint-up(lg) {
            font-size:1.5rem;
        }
        line-height: 2.5rem;
    }

    .ms-refine-submenu.list-group {
        border-bottom: 1px solid var(--msv-refiner-border);
        flex-direction: column;
        margin-bottom: 0px;
        padding-left: 0px;
        button {
            padding:0.3rem 2rem 0.3rem 0;
            text-transform:none;
            letter-spacing: 0.02em;
            @include media-breakpoint-up(lg) {
                height: calc(2.5rem + 1px);
                padding:0 2rem 0 .75rem;
            }
        }
        button[aria-label="Category"],
        button[aria-label="Categoria"],
        button[aria-label="Price"] {
            font-size:0;
            &:before {
                content:"Modello";
                font-size:1.3rem;
                @include media-breakpoint-up(lg) {
                    font-size:1.5rem;
                }
                display: inline-block;
            }
        }
        button[aria-label="Price"]{
            &:before {
                content:"Prezzo";
            }
        }
        .ms-refine-submenu-item {
            padding-left: 0;
            margin-bottom: 2px;
            @include media-breakpoint-down(lg) {
                margin-bottom:$spacer;
            }
            > a {
                @include vfi();
            }

            .msc-rating {
                display: inline-block;

                &__count {
                    color: var(--msv-refiner-values-font-color);
                }

                &__empty-star, &__star, &__count {
                    font-size: var(--msv-refiner-rating-star-size);
                }
            }

            .ms-rating-component {
                &__up .msc-rating__star {
                    color: #616365;
                }
                &__current .msc-rating__star {
                    color: #1D1D1D;
                }
                &__down .msc-rating__star {
                    color: #8F8F8F;
                }
            }
        }

        .refine-submenu-item__rating {
            color: var(--msv-refiner-values-font-color);
            font-size: var(--msv-refiner-rating-star-size);
        }

        .multi-select,
        .multi-select-checked,
        .single-select,
        .single-select-checked {
            @include vfi();
            display: block;
            border: 1px solid transparent;

            span.ms-refine-submenu-item__label {
                color: var(--msv-refiner-values-font-color);
                font-size: var(--msv-refiner-values-font-size);
                padding-left: $layout-gutter*0.75;
                vertical-align: middle;
            }
        }
    }



    .list-group-item {
        border: 1px solid rgba(0,0,0,0);
        display: block;
        outline-offset: 2px;
        padding: 21px $layout-gutter*0.75 0px $layout-gutter*0.75;
        position: relative;
    }
}

// product section
.ms-search-result-container__product-section {
    width: 75%;

    .ms-search-result-wrapper-title-choice-summary {
        display: flex;
        flex-direction: column;
        flex: 0 0 75%;
        max-width: 75%;
        position: relative;
        width: 100%;

        .ms-search-result-container__title h2 {
            font-size: $msv-font-size-m*2.5;
            line-height: 3rem;
            margin: $spacer*2 0 $spacer-m 0;
            text-align: center;
    
            .ms-search-result__collection-title-prefix,
            .ms-search-result__collection-title-text,
            .ms-search-result__collection-title-count {
                @include font-content(var(--msv-font-weight-normal), var(--msv-search-result-font-size), $msv-line-height-m);
                color: $msv-primary;
                display: inline-block;
                font-weight: $msv-font-weight-medium;
                font-size: 40px;
                line-height: 3rem;
            }
    
            .ms-search-result__collection-title-text {
                margin: 0 $spacer*0.75;
            }
        }
    }
    .ms-search-result-wrapper-sort-by-category {
        display: none;
    }
}

//active filters
.msc-choice-summary {
    list-style-type: none;
    padding-inline-start: 0;
    margin-left: 0;
    margin-top:0;
    order: -1;

    .msc-choice-summary__list {
        display: inline-block;
        
        @media screen and (max-width: $msv-breakpoint-m) {
            width: 100%;
        }
    }

    .msc-choice-summary__list-item {
        &:first-child {
            margin-top: $spacer-l;
        }
        display: inline-block;
        margin-bottom: $spacer;
    }

    .msc-choice-summary__item {
        background-color: $msv-primary;
        border: none;
        border-radius: 0;
        color: $msv-white;
        margin-right: $layout-gutter;
        padding: 4px $spacer-half;
        position: relative;

        &:hover {
            text-decoration: none;
        }
    }

    .msc-choice-summary__clear-all {
        @extend %underline-button;
        display: inline-block;
        position: relative;
        margin-bottom:$spacer-xl;
    }

    .msc-choice-summary__glyph {
        margin-left: $layout-gutter;
        vertical-align: text-top;

        @include add-icon-icomoon($close);
    }

    .msc-choice-summary__clear-all:after {
        display: none;
    }
}

.ms-search-result-container__Sort-by-category {
    display: none;

    .msc-l-dropdown__label {
        color: var(--msv-search-result-sort-font-color);
        margin-right: 8px;
        vertical-align: middle;
    }

    .msc-l-dropdown {
        @include font-content(var(--msv-font-weight-bold), var(--msv-search-result-sort-font-size), $msv-line-height-m);
        background: var(--msv-search-result-sort-bg);
        border-style: none;
        color: var(--msv-search-result-sort-font-color);
        vertical-align: middle;
        outline-offset: 2px;
    }
}

.ms-search-result-container__Products {
    margin-bottom: 24px;
    margin-top: 32px;

    .ms-search-result-container__no-results-message {
        h5.error-text {
            @include font-content(var(--msv-font-weight-normal), var(--msv-refiner-values-font-size), $msv-line-height-m);
        }
    }

    .list-unstyled .ms-product-search-result__item {
        padding-bottom: $spacer-m;
        position: relative;

        &:not(:first-of-type) {
            padding-top: $spacer-m;

            &::before {
                content: '';
                background-color: $msv-gray-100;
                height: 8px;
                position: absolute;
                left: -$layout-gutter*1.5;
                right: -$layout-gutter*1.5;
                top: -$spacer/4;
            }
        }
        
        .msc-product {

            &__title,
            &__details {
                color: var(--msv-search-result-font-color);
            }

            .msc-product__image img {
                width: 100%;
            }

            .msc-rating {
                &__star {
                    color: var(--msv-refiner-rating-star);
                }

                &__half-star{
                    &:after {
                        color: var(--msv-refiner-rating-star);
                    }
                }
            }

            .msc-empty_image {
                @include image-placeholder(312px);
            }
        }

        a {
            .msc-product__title {
                @extend %h3;
                font-weight: 600;
            }
            
            .msc-rating {
                display: none;
            }
            
            .msc-price {
                .msc-price__actual {
                    font-size:1.75rem;
                    color:$msv-secondary;
                    display: inline-block;
                }
            }
        }
    }
}

// pagination
.ms-search-result-container__pagination {
    margin-bottom: $spacer*5;
}

.msc-pagination {
    @include font-content(var(--msv-font-weight-normal), var(--msv-refiner-values-font-size), $msv-line-height-m);
    display: flex;
    list-style: none;
    justify-content: center;
    width: 100%;
}

.msc-page-item {
    display: inline;

    .msc-page-link {
        color: $msv-gray-300;
        font-size: $msv-font-size-l;
        display: inline;
        padding: $spacer-half $layout-gutter*0.75;
        line-height: 1.25;
        @include vfi();

        &:hover {
            text-decoration: none;
        }
    }

    &.active {
        font-weight: $msv-font-weight-medium;
        
        .msc-page-link {
            color: $msv-primary;
        }
    }

    &.disabled .msc-page-link {
        cursor: auto;
        pointer-events: none;
    }

    &.previous {
        margin-right: $spacer-m;
    
        .msc-pagination__prev {
            padding-left: 4px;
        }
    
        .msc-page-link {
            color: $msv-primary;
            
            &:before {
                @include msv-icon();
                content: '';
            }
    
            .ms-search-result__pagination-left {
                @include add-icon($msv-ChevronLeft, before);
            }
        }
    }

    &.next {
        margin-left: $spacer-m;

        .msc-pagination__next {
            padding-right: 4px;
        }
    
        .msc-page-link {
            color: $msv-primary;
    
            &:after {
                @include msv-icon();
                content: '';
            }
    
            .ms-search-result__pagination-right {
                @include add-icon($msv-ChevronRight, after);
            }
        }
    }

    .msc-pagination__prev,
    .msc-pagination__next {
        display: inline;
    }

    .prev-text,
    .next-text {
        display: none;
    }
}

// modal
.msc-search-result-modal {
    margin: 0;
    max-width: none;
    height: 100%;

    .msc-modal__content {
        border: none;
        height: 100%;
        justify-content: flex-start;
        padding: 0;
    }
    .msc-modal__header {
        border-bottom: 2px solid $msv-black;
        padding: $spacer $layout-gutter;

        .msc-modal__close-button {
            font-size: 1.5rem;
            top: 1.125rem;
            right: .5rem;
        }
    }
    .msc-modal__body {
        max-height: 100vh;
    }
    .msc-modal__footer {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
    }
    .msc-review-modal-body {
        overflow-y: auto;
    }

    .ms-sort-and-filter-modal-close.msc-btn {
        @extend %primary-button;
        width:100%;
    }

    .ms-search-result-container__refiner-section {
        display: block;
        padding-right: 0;
        width: 100%;

        .ms-refine-submenu.list-group {
            margin-bottom: 1rem;
            padding-bottom: 1rem;
            padding-left: 1rem;

            button {
                color: $msv-black;
            }
            .ms-refine-submenu__toggle_collapsed::after,
            .ms-refine-submenu__toggle_expanded::after {
                color: $msv-black;
                right: 1rem;
            }
            &.Price ul.Price {
                margin-left: -0.5rem;
            }
        }
    }
}

.ms-search-result-container {
    .msc-button {
        @extend %outlined-button;
    }
}
.ms-search-result-container__Products .list-unstyled {
    display: flex;
    flex-direction: column;
    text-align: left;

    .ms-product-search-result__item .msc-product {
        border: 2px solid transparent;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: $spacer-m $layout-gutter;
        position: relative;

        .msc-product__image {
            align-self: center;
            max-width: 711px;
            min-width: 100%;
        }

        .msc-product__details {
            display: flex;
            flex-direction: column;
            flex: 1;

            .msc-product__title {
                text-decoration: none;
            }

            .tags-list {
                display: flex;
                margin-bottom: $spacer*1.5;
                
                .tags-item {
                    background-color: $msv-primary;
                    border: none;
                    border-radius: 0;
                    color: $msv-white;
                    margin-right: 0.625rem;
                    padding: 4px $spacer-half;
                    text-transform: uppercase;
                    line-height: $msv-line-height-m;

                    &:last-of-type {
                        margin-right: 0;
                    }
                }
            }

            .pre-price__actual {
                display: inline-block;
                font-size: $msv-font-size-l;
                margin-right: $layout-gutter/4;
            }

            .msc-price__actual {
                font-size: 1.75rem;
                font-weight: $msv-font-weight-medium;
                color: $msv-secondary;
                letter-spacing: 0.16px;
            }

            .price-offer {
                font-size: $msv-font-size-s;
                margin-top: $spacer-half;
                margin-bottom: $spacer-half;

                .msc-price__strikethrough {
                    font-weight: $msv-font-weight-heavy;
                    font-size: $msv-font-size-s;
                }
                .offer-duration,
                .financing-val, 
                .tan-val,
                .taeg-val {
                    text-transform: uppercase;
                    font-weight: $msv-font-weight-medium;
                }
            }

            .price-infos {
                color: $msv-gray-500;
                font-size: $msv-font-size-xs;
                letter-spacing: 1.5px;
            }

            .msc-product__text {
                font-size: $msv-font-size-s;
                font-weight: $msv-font-weight-medium;
                letter-spacing: 1.5px;
                margin-top: $spacer*0.75;
            }
            
            .msc-btn {
                display: flex;
                flex: 1;
                margin: 1.5rem auto 0 0;
                @include underline-button--k($color: $msv-secondary);
            }
        }
    
        &:focus-within {
            border: 2px solid $msv-primary-hover;
            outline: none;
        }
    }
}

@include media-breakpoint-up(lg) {
    .ms-search-result-container__refine-product-section {
        display: flex;
        flex-direction: column;
        padding: 0 $layout-gutter; 
    }
    
    // refiner section
    .ms-search-result-container__refiner-section {
        padding-right: 0;
        width: 100%;
    
        .ms-search-result-container__refine-menu {
            display: flex;
            overflow: hidden;
            position: relative;
            transition: .15s linear;
        }
        .ms-refine-submenu__list {
            display: flex;
            justify-content: center;
            width: 100%;

            li:not(:first-of-type) {
                margin-left: $layout-gutter*4;
            }
        }
        .ms-refine-submenu > button + div {
            background-color: #fbfbfc;
            flex-direction: row;
            align-items: center;
            height: auto;
            margin-right: 50vw;
            position: relative;
            left: 50%;
            right: 50%;
            max-width: 1350px;
            width: 100vw;
            padding-top: $spacer-l;
            padding-bottom: $spacer-l;
            transition: min-height .5s linear;
        }
        .ms-refine-submenu > button[aria-expanded="false"] + div {
            display: none;
        }
        .ms-refine-submenu > button[aria-expanded="true"] + div {
            display: flex;
        }
        button.ms-refine-submenu__toggle_collapsed,
        button.ms-refine-submenu__toggle_expanded {
            background: transparent;
            border-bottom: none;
            display:block;
            letter-spacing: 0.02em;
            height: auto;
            margin: 0 auto;
            padding: 0 $spacer*2 0 $spacer*0.75;
            position: relative;
            text-transform: none;
            width: auto;
    
            &::after {
                font-size: 10px;
                position: absolute;
                right: .75rem;
                top: 15px;
            }
        }
    
        button.ms-refine-submenu__toggle_collapsed {
            border: 1px solid transparent;
        }
    
        button.ms-refine-submenu__toggle_expanded {
            background-color: #fbfbfc;
            border: 1px solid $msv-gray-100;
            border-bottom: none;
            color: $msv-secondary;
        }
    
        .ms-refine-submenu.list-group {
            border-bottom: none;
            margin-bottom: $spacer*2.5;
            padding-left: 0px;
            flex-basis: 20%;
            width: 20%;

            &:first-child {
                border-top: none;
            }

            &.Price, &.Prezzo {
                .ms-refine-submenu__item.list-group-item.refine-submenu__item--range {
                    min-width: 45%;
                }
            }
        
            &:nth-child(1) > div{
                margin-left: -50%;
            }
            &:nth-child(2) > div {
                margin-left: -150%;
            }
            &:nth-child(3) > div {
                margin-left: -250%;
            }
            &:nth-child(4) > div {
                margin-left: -350%;
            }
            &:nth-child(5) > div {
                margin-left: -450%;
            }

            .ms-refine-submenu-item {
                margin-bottom: 0;

                &:hover {
                    text-decoration: none;
                }

                .ms-refine-submenu-item__label {
                    font-weight: $msv-font-weight-medium;
                }
            }
        }
    }
    
    // product section
    .ms-search-result-container {
        padding-left: $layout-gutter;
        padding-right:  $layout-gutter;

        .ms-search-result-container__product-section {
            display: flex;
            flex-direction: column;
            position: relative;
            width: 100%;
        
            .ms-search-result-wrapper-title-choice-summary {
                max-width: 100%;
            }

            .ms-search-result-wrapper-sort-by-category {
                position: absolute;
                top: $spacer*0.75;
                right: 0;
                display: none;
                
                .ms-search-result-container__Sort-by-category {
                    border-bottom: 1px solid $msv-gray-100;
                    padding: 0 0 $spacer 0;

                    .msc-l-dropdown__label.reviews-list-sort-by {
                        color: $msv-gray-500;
                        font-weight: $msv-font-weight-light;
                        padding-left: $layout-gutter/4;

                        .msc-l-dropdown.reviews-list-dropdown {
                            letter-spacing: 1px;
                        }
                    }
                }
            }
        }
    }

    // products
    .ms-search-result-container__Products .list-unstyled {

        .ms-product-search-result__item {
            padding: 0px 0px 33px 0px;
        
            &:not(:first-of-type) {
                padding-top: 0;

                &::before {
                    content: none;
                }
            }

            .msc-product {
                border: 2px solid transparent;
                padding-bottom: $spacer-m*3;
                margin-bottom: $spacer-m;

                .msc-product__image {
                    margin-right: 5%;
                    min-width: 375px;
                    width: 45%;
                }

                .msc-product__details .msc-btn {
                    display: none;
                    margin: auto 0 0 auto;
                }

                .msc-product__title {
                    margin-top: 0;
                }
                
                .msc-btn {
                    display: none;
                    position: absolute;
                    bottom: $spacer-m;
                    right: $layout-gutter*2;
                }

                &:hover {
                    border: 2px solid $msv-secondary;
                    
                    .msc-product__details .msc-btn {
                        display: flex;
                    }
                }
            }
        }
    }

    .ms-search-result-container__pagination {
        margin-bottom: 0;
    }
    
    .msc-page-item .msc-page-link {
        font-size: $msv-font-size-m;
        padding: 8px 4px;
    }
}
@media screen and (max-width: $msv-breakpoint-l) {
    .ms-search-result-container {
        display: flex;
        flex-direction: column;
        text-align: center;

        .msc-choice-summary {

            .msc-choice-summary__list-item:first-child {
                margin-top: 0;
            }
            .msc-choice-summary__clear-all {
                margin-bottom: 2rem;
            }
        }
        > .msc-button#search-result-modal {
            margin: auto auto 2rem auto;
            order: -1;
        }
    }
}
@media screen and (min-width: $msv-breakpoint-xl) {
    .ms-search-result-container .ms-product-search-result__item .msc-product .msc-empty_image {
        @include image-placeholder(334px);
    }       
}