.ms-accordion.product-specification-accordion {
    padding: 0;

    .ms-accordion-item__drawer {
        border-bottom: none;
        padding-bottom: $spacer-half;
    }
    .drawer__button.msc-btn.btn-block {
        border-bottom: 1px solid $msv-gray-100;
        font-weight: $msv-font-weight-heavy;
        letter-spacing: 1px;
        padding-right: 0;
        padding-bottom: $spacer;
        
        @media (max-width: $msv-breakpoint-m) {
            padding-top: $spacer;

            &:first-of-type {
                border-top: 1px solid $msv-gray-100;
            }
        }
        .drawer__buttontext __start {
            line-height: $spacer*2;
        }
    }
    @media screen and (max-width: $msv-breakpoint-m) {
        margin-bottom: $spacer;

        .drawer__button.msc-btn.btn-block {
            padding-top: $spacer;

            &:first-of-type {
                border-top: 1px solid $msv-gray-100;
            }
        }
    }
}
.ms-product-specification {
    padding: $spacer-half $spacer*0.75;

    @media (max-width: $msv-breakpoint-m) {
        padding: 0;
    }
    &__heading {
        @extend %h2;
        text-align:center;
    }

    &__table {
        width: 100%;
        border-spacing: 0px;
        border-collapse: collapse;
    }

    &__table-row {
        &:nth-of-type(odd) {
            background-color: $msv-gray-100;
        }

        &:nth-of-type(even) {
            background-color: $msv-white;
        }

        th,
        td {
            border: 1px solid $msv-gray-300;
            color: $msv-black;
            font-size: $msv-font-size-s;
            letter-spacing: 1px;
            line-height: $msv-line-height-xs;
            min-width: 140px;
            padding: $spacer*0.75 $layout-gutter*0.75 $spacer $layout-gutter*0.75;
            text-align: left;
        }
        th {
           font-weight: $msv-font-weight-bold;
        }
        td {
            font-weight: $msv-font-weight-normal;
        }
    }
}